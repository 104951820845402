import React from 'react';
import Api from '../../../../../util/storyblok-api';

import TextTile from './TextTile';
import SanitizeHTML from '../../../../util/SanitizeHTML';

const TextTileBlok = ({ blok }) => {
  return (
    <TextTile button={blok.button[0]}>
      <SanitizeHTML html={Api.richTextResolver.render(blok.content)} />
    </TextTile>
  );
};

export default TextTileBlok;
