import React from 'react';
import CTA from './CTA';
import SbEditable from '../../../util/SbEditable';

const CTABlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CTA />
    </SbEditable>
  );
};

export default CTABlok;
