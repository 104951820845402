import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Slider from 'react-slick';

import Member from './Member';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow: visible;
  @media ${({ theme }) => theme.breakpoint.lg} {
    grid-gap: 40px;
    display: grid;
    grid-auto-rows: 0px;
    grid-template-columns: repeat(12, 1fr);
    overflow: hidden;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-track {
    left: -8.5px;
  }
`;

const Grid = ({ members }) => {
  const themeContext = useContext(ThemeContext);

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const setMobileIfNeeded = () => {
        if (window.innerWidth < parseInt(themeContext.breakpointRaw.lg, 10) - 0.1) {
          setMobile(true);
        } else {
          setMobile(false);
        }
        return null;
      };
      setMobileIfNeeded();
      window.addEventListener('resize', setMobileIfNeeded);
      return () => {
        window.removeEventListener('resize', setMobileIfNeeded);
      };
    }
    return null;
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    arrows: false,
    swipe: true,
    slidesToShow: 1,
    centerMode: 1,
    centerPadding: 10,
    slidesToScroll: 1,
    easing: 'ease-in-out',
  };

  const AddSliderForMobile = ({ children }) => {
    if (isMobile) {
      return (
        <StyledSlider style={{ width: '100%' }} {...settings}>
          {children}
        </StyledSlider>
      );
    }
    return children;
  };

  return (
    <Container>
      <Row>
        <AddSliderForMobile>
          {members &&
            members.map((memberData) => {
              let rowClass = '';
              let columnClass = '';

              if (memberData.row === 'small') {
                rowClass = 'span5';
              } else if (memberData.row === 'big') {
                rowClass = 'span7';
              }

              if (memberData.column === 'small') {
                columnClass = 'col5';
              } else if (memberData.column === 'big') {
                columnClass = 'col7';
              }

              return React.createElement(Member, {
                key: memberData._uid,
                memberData,
                rowSize: memberData.row,
                className: `${rowClass} ${columnClass}`,
              });
            })}
        </AddSliderForMobile>
      </Row>
    </Container>
  );
};

export default Grid;
