import React from 'react';
import SbEditable from '../../../util/SbEditable';

import ContactForm from './ContactForm';

const ContactFormBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <ContactForm headingTag={props.blok.headingTag ?? 'h2'} />
    </SbEditable>
  );
};

export default ContactFormBlok;
