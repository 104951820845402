import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Img from 'gatsby-image/withIEPolyfill';
import { transformImage } from '../../../../../util/util';
import Link from '~components/util/Link';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => {
    return theme.gutterSize.xs / 2;
  }}px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding: ${({ theme }) => {
      return theme.gutterSize.md / 2;
    }}px;
  }

  color: ${({ theme }) => theme.colors.primary};
`;

const SliderRow = styled.div``;

const Title = styled.span`
  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  white-space: nowrap;

  display: flex;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  hr {
    display: none;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: block;
    }

    margin-left: 2rem;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  margin-bottom: 1rem;
`;

const Content = styled.span`
  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
  line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.xs};

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: ${({ theme }) => theme.font.typography.copy.size.md};
    line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.md};
  }
`;

const SliderLogo = styled.div`
  height: 60px;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 74px;
    margin-bottom: 3rem;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const PartnersSliderItem = ({ fluidDataCms, relatedProject, title, children }) => {
  const defaultImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-placeholder.png" }) {
        childImageSharp {
          fluid(maxHeight: 74) {
            aspectRatio
            src
            srcSet
            sizes
            originalName
          }
        }
      }
    }
  `);

  let fluidData = fluidDataCms;
  if (typeof fluidData === 'undefined' || fluidData == null || fluidData === '') {
    fluidData = defaultImage.file.childImageSharp.fluid;
  }

  return (
    <Container>
      <SliderRow>
        <Link to={relatedProject?.cached_url}>
          <SliderLogo>
            {typeof fluidData === 'string' ? (
              <picture>
                <source type="image/webp" srcSet={transformImage(fluidData, '0x74/', true)} />
                <source type="image/jpeg" srcSet={transformImage(fluidData, '0x74/')} />
                <img
                  srcSet={transformImage(fluidData, '0x74/')}
                  alt={title}
                  title={title}
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'bottom left',
                    width: '100%',
                    height: '100%',
                  }}
                  loading="lazy"
                />
              </picture>
            ) : (
              <Img
                alt={title}
                title={title}
                fluid={fluidData}
                objectFit="contain"
                objectPosition="bottom left"
              />
            )}
          </SliderLogo>
        </Link>
      </SliderRow>
      <SliderRow>
        <Title>
          {title}
          <hr />
        </Title>
      </SliderRow>
      <SliderRow>
        <Content>{children}</Content>
      </SliderRow>
    </Container>
  );
};

export default PartnersSliderItem;
