import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

const LogoWrapper = styled.div`
  width: 33.333%;
  display: flex;

  height: 103px;

  justify-content: center;
  align-items: center;

  padding: 5px 10px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 0 10px;
    height: 50px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    height: 80px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    height: 103px;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    height: 63px;
    padding: 10px 10px;
  }
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Logo = ({ fluidData }) => {
  const logoData = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "logo-placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 136, maxHeight: 43) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const fluidObj = fluidData ?? logoData.file.childImageSharp.fluid;

  return (
    <LogoWrapper>
      <Image
        alt="Logo in the tabletporftolio component"
        title="Logo in the tabletportfolio component"
        fluid={fluidObj}
        imgStyle={{ objectFit: 'contain' }}
      />
    </LogoWrapper>
  );
};

export default Logo;
