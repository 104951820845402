import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image';
import SbEditable from '../../../util/SbEditable';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-bottom: 80px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  &.col7 {
    padding-top: 1rem;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    &.col5 {
      ${mixins.makeCol(5, 12)}
    }
    &.col7 {
      ${mixins.makeCol(7, 12)}
      padding-top:0;
    }
  }
`;

const Image = styled(Img)`
  img {
    transition: 0.5s ease-in-out transform !important;
  }
  &:hover {
    img {
      overflow: hidden;
      transform: scale(1.1) translate(-0.07%, 0.05%);
    }
  }
`;

const TwoImagesBlok = ({ blok }) => {
  const imageLeftRaw = blok.image_left?.filename?.replace(/(^\w+:|^)/, '');
  const imageLeft = getFluidGatsbyImage(imageLeftRaw, {
    maxWidth: 700,
    maxHeight: 613,
  });
  const imageRightRaw = blok.image_right?.filename?.replace(/(^\w+:|^)/, '');
  const imageRight = getFluidGatsbyImage(imageRightRaw, {
    maxWidth: 1005,
    maxHeight: 613,
  });

  return (
    <SbEditable content={blok}>
      <Container>
        <Row>
          <Col className="col5">
            <Image alt={blok.image_left?.alt} title={blok.image_left?.title} fluid={imageLeft} />
          </Col>
          <Col className="col7">
            <Image alt={blok.image_right?.alt} title={blok.image_right?.title} fluid={imageRight} />
          </Col>
        </Row>
      </Container>
    </SbEditable>
  );
};

export default TwoImagesBlok;
