import React from 'react';
import styled from 'styled-components';

import Arrow from '../../../util/arrow';

const ButtonWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.BreakpointDown.xl} {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    padding-top: 10px;
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${({ theme }) => theme.font.weight.bold};
  @media ${({ theme }) => theme.breakpoint.sm} {
    display: block;
  }
  cursor: pointer;
`;

const Button = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;

  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};

  width: 52px;
  height: 52px;
  min-width: 52px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    font-size: ${({ theme }) => theme.font.typography.copy.size.md};

    margin-left: 1rem;
  }
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 55%;
  flex-direction: row;

  @media only screen and (min-width: 768px) {
    width: 20vw;
    padding-right: 15px;
  }
`;

const nextArrow = (props) => {
  return (
    <ButtonWrapper {...props}>
      <ContentWrapper>
        <ButtonText>Scroll to view next project</ButtonText>
        <Button>
          <Arrow size={39} />
        </Button>
      </ContentWrapper>
    </ButtonWrapper>
  );
};

export default nextArrow;
