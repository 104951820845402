import React from 'react';
import styled from 'styled-components';

import Button from '../../../../Button/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 2rem;

  padding-left: 7.5px;
  padding-right: 7.5px;

  color: ${({ theme }) => theme.primary};

  font-size: ${({ theme }) => theme.font.typography.h3.size.xs};
  line-height: ${({ theme }) => theme.font.typography.h3.lineHeight.xs};

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-left: 0;
    padding-right: 0;

    padding-bottom: 0;

    font-size: 1.5rem; /* Custom change requested by Sienna */
    line-height: ${({ theme }) => theme.font.typography.h3.lineHeight.md};
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 23px;
  letter-spacing: 1.5px;

  b,
  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    letter-spacing: 1.5px;
  }

  & .thin {
    font-weight: ${({ theme }) => theme.font.weight.light};
    letter-spacing: 1.5px;
  }

  p {
    margin-top: initial;
    margin-bottom: initial;
    letter-spacing: 1.5px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const TextTile = ({ button, children }) => {
  return (
    <Wrapper>
      <TextWrapper>{children}</TextWrapper>
      {button.url.cached_url && <Button link={button.url.cached_url}>{button.label}</Button>}
    </Wrapper>
  );
};

TextTile.defaultProps = {
  button: {
    url: '#',
    label: 'View our work',
  },
};

TextTile.propTypes = {
  // button: PropTypes.shape({
  //   url: PropTypes.string,
  //   label: PropTypes.string,
  // }),
};

export default TextTile;
