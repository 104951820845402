import React from 'react';
import styled from 'styled-components';

const CopyWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary};

  letter-spacing: 0.8;

  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
  line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.xs};
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-right: 30px;
    padding-bottom: 25px;

    font-size: ${({ theme }) => theme.font.typography.copy.size.md};
    line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.md};
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ListSliderCopy = ({ children }) => {
  return <CopyWrapper>{children}</CopyWrapper>;
};

export default ListSliderCopy;
