import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';
import Link from '~components/util/Link';

import * as mixins from '~styles/mixins';

const NoLink = styled(Link)`
  text-decoration: none;
  color: initial;
`;

const ProjectDescription = styled.div`
  @media ${({ theme }) => theme.breakpoint.xl} {
    position: relative;
    /* top: -150px; */
    overflow: visible;
    height: 0;
  }
  white-space: nowrap;
  padding-top: 27px;
  padding-left: 5px;

  & > div {
    font-size: 15px;
    display: inline-block;
    padding-left: 14px;
    text-transform: uppercase;
  }
  p {
    margin: 0;
  }
`;

const ProjectBreadCrumbs = styled.span`
  /* text-transform: uppercase; */
  font-size: 15px;
  display: block;
  width: 100%;
  color: #5c6575;
`;
const Container = styled.div`
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 3rem;
    padding-bottom: 3rem;

    ${mixins.makeContainer()}
    ${mixins.makeContainerMaxWidths()}
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;

const Col = styled.div`
    @media ${({ theme }) => theme.BreakpointDown.md} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ theme, md, xl }) => {
      return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
    }}
     
  &:hover ${ProjectDescription}, &:hover ${ProjectBreadCrumbs} {
    color:#E60D77 !important;
    cursor: pointer;
  }
  &.notlink ${ProjectDescription}, &.notlink ${ProjectBreadCrumbs} {
    color: initial !important;
  }
`;

const Row = styled.div`
  ${mixins.makeRow}
  overflow: visible;
`;

const ImageObject = styled(Row)`
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    ${ProjectDescription} {
      order: 3;
    }
    .smallerImage {
      /* width: 80vw; */
      order: 1;
    }
    .biggerImage {
      display: none;
      margin-top: 20px;
      order: 2;
      /* width: 90vw; */
    }
  }
  & img {
    transition: 2.5s ease-in-out transform !important;
  }
  & ${Col}:hover img {
    overflow: hidden;
    transform: scale(1.02) translate(-0.07%, 0.05%);
  }
`;

const TwoImagesBlock = (props) => {
  const { blok } = props;

  const imgBiggerUrl = blok.bigger_image?.filename?.replace(/(^\w+:|^)/, '');
  const imgBigger = getFluidGatsbyImage(imgBiggerUrl, {
    maxWidth: 669,
    maxHeight: 602,
  });

  const imgSmallerUrl = blok.smaller_image?.filename?.replace(/(^\w+:|^)/, '');
  const imgSmaller = getFluidGatsbyImage(imgSmallerUrl, {
    maxWidth: 461,
    maxHeight: 461,
  });

  return (
    <Container>
      <ImageObject>
        <Col xl={5} style={{ height: '100%' }}>
          <NoLink to={blok.url.cached_url}>
            <Img
              alt={blok.smaller_image?.alt}
              title={blok.smaller_image?.title}
              className="smallerImage"
              fluid={imgSmaller}
            />
            <ProjectDescription>
              <strong>Project</strong>
              <SanitizeHTML
                html={
                  Api.richTextResolver.render(blok.project_name) === ''
                    ? blok.project_name
                    : Api.richTextResolver.render(blok.project_name)
                }
              />
              <ProjectBreadCrumbs>
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(blok.breadcrumbs) === ''
                      ? blok.breadcrumbs
                      : Api.richTextResolver.render(blok.breadcrumbs)
                  }
                />{' '}
              </ProjectBreadCrumbs>
            </ProjectDescription>
          </NoLink>
        </Col>
        <Col className="notlink" xl={7}>
          <Img
            alt={blok.bigger_image?.alt}
            title={blok.bigger_image?.title}
            className="biggerImage"
            fluid={imgBigger}
          />
        </Col>
      </ImageObject>
    </Container>
  );
};

export default TwoImagesBlock;
