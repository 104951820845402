import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Container from '~components/Layout/Container';

import SbEditable from '../../../util/SbEditable';

const ParallaxContainer = styled(Container)`
  /* width: calc(100% - 140px); */
  height: 380px;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    height: auto !important;
  }
  background-color: ${(props) => (props.background_color ? props.background_color : '#cfc8c8')};
  margin-bottom: 5rem;
  overflow: hidden;
`;

const ParallaxWrapper = styled.div`
  margin: 5rem 0;
  position: relative;
  background: linear-gradient(
    to left,
    #ffffff 50%,
    ${(props) => (props.background_color ? props.background_color : '#F1F1F1')} 50%
  );

  @media ${({ theme }) => theme.BreakpointDown.md} {
    margin-top: 0;
    margin-bottom: 0;

    & ${ParallaxContainer} {
      margin-bottom: 2.5rem;
    }
  }
`;

const ParallaxImage = ({ blok, ...props }) => {
  const imgURL = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgURL, {});

  return (
    <SbEditable content={blok}>
      <ParallaxWrapper background_color={blok?.background_color?.color} {...props}>
        <ParallaxContainer
          style={{ overflow: 'hidden' }}
          background_color={blok?.background_color?.color}
        >
          <ParallaxProvider>
            <Parallax className="custom-class" y={[-60, 20]} tagOuter="figure">
              <Img alt={blok.image?.alt} title={blok.image?.title} fluid={img} />
            </Parallax>
          </ParallaxProvider>
        </ParallaxContainer>
      </ParallaxWrapper>
    </SbEditable>
  );
};

export default ParallaxImage;
