import React from 'react';
import styled from 'styled-components';

import Arrow from '../../../util/arrow';

const ButtonWrapper = styled.div`
  display: flex;

  align-items: center;
`;

const ButtonText = styled.div`
  display: none;

  font-weight: ${({ theme }) => theme.font.weight.bold};
  @media ${({ theme }) => theme.breakpoint.sm} {
    display: block;
  }
  cursor: pointer;
`;

const Button = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;

  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};

  width: 35px;
  height: 35px;
  @media ${({ theme }) => theme.breakpoint.md} {
    width: 52px;
    height: 52px;
  }
  @media ${({ theme }) => theme.breakpoint.sm} {
    font-size: ${({ theme }) => theme.font.typography.copy.size.md};

    margin-left: 1rem;
  }
  cursor: pointer;
`;

const PartnersSliderNext = (props) => {
  return (
    <ButtonWrapper {...props}>
      <ButtonText>Show me the next three!</ButtonText>
      <Button>
        <Arrow size={39} />
      </Button>
    </ButtonWrapper>
  );
};

export default PartnersSliderNext;
