import React from 'react';
import SbEditable from '../../../util/SbEditable';

import HowWeExcelled from './HowWeExcelled';
import Api from '../../../../util/storyblok-api';
import NumberRow from './NumberRow';

const HowWeExcelledBlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HowWeExcelled
        title={Api.richTextResolver.render(blok.title)}
        heading={Api.richTextResolver.render(blok.heading)}
        content={Api.richTextResolver.render(blok.content)}
        button={blok.button[0]}
      >
        {blok.numbers &&
          blok.numbers.map((numberBlok) =>
            React.createElement(
              NumberRow,
              {
                key: numberBlok._uid,
                numberType: numberBlok.type,
                numberRight: numberBlok.right,
                numberContent: numberBlok.number,
                numberChar: numberBlok.character,
              },
              Api.richTextResolver.render(numberBlok.content),
            ),
          )}
      </HowWeExcelled>
    </SbEditable>
  );
};

export default HowWeExcelledBlok;
