import React, { useEffect, useRef, useReducer } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { randomID } from './util';
import ProgressBar from './ProgressBar';
import FormStatus from './FormStatus';
import Arrow from '../util/arrow';

const Wrapper = styled.div`
  width: 100%;
  ${({ layoutType }) => {
    if (layoutType === 2) {
      return `
        height: 79px;
      `;
    }
    return `
      height: 176px;
    `;
  }}
  @media ${({ theme }) => theme.breakpoint.md} {
    height:auto;
  }
`;

const Spinner = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: ${({ iconSize }) => iconSize}px;

  color: #fff; 
  animation: ${Spinner} 1.1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
`;

const Row = styled.div`
  display: flex;

  height: 30px;

  &.labelContainer {
    overflow: hidden;
  }

  margin-bottom: 0px;

  & + & {
    flex-grow: 1;
  }

  ${({ layoutType, theme }) => {
    if (layoutType !== 2) {
      return `
          flex-wrap: wrap;
          height:auto;
          @media ${theme.breakpoint.md} {
            margin-bottom: 5px;
            height:30px;
            flex-wrap: nowrap;
          }
          &+& {
              margin-top:15px;
          }  
        `;
    }
    return null;
  }}
`;

const FormBody = styled.div`
  ${({ layoutType }) => {
    if (layoutType === 2) {
      return `
                display:flex;
                flex-wrap: wrap;    
            `;
    }
    return null;
  }}
`;

const LabelContainer = styled.div`
  ${({ layoutType }) => {
    if (layoutType === 2) {
      return `display:none;`;
    }
    return `display:flex;`;
  }}
  flex-direction: column;
  transition: 0.4s ease transform;
`;

const Label = styled.div`
  display: flex;

  align-items: center;

  white-space: nowrap;

  height: 30px;

  font-size: 1.3rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 0.95rem;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    font-size: 1.2rem;
  }
  line-height: 30px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-transform: uppercase;
`;

const Field = styled.input`
  background: transparent;
  border: 0;

  line-height: 30px;
  height: 30px;

  z-index: 1;
  opacity: 1;
  color: ${({ theme }) => theme.colors.secondary};

  font-size: 16px;
  line-height: 30px;

  ${({ layoutType, theme }) => {
    if (layoutType === 2) {
      return `
        
            &::placeholder {
                color: ${theme.colors.secondary};
            }
            &:-ms-input-placeholder {
                color: ${theme.colors.secondary};
            }
            &::-ms-input-placeholder {
                color: ${theme.colors.secondary};
            }
        `;
    }
    return null;
  }}

  transition: 0.3s ease-in-out opacity;
  &[value='']:focus {
    opacity: 0.2;
  }
`;

const fading = keyframes`
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  transition: 0s linear transform 0.2s;
  transform: translateY(0);

  &.animate {
    animation: ${fading} 0.6s 1;
  }
`;
const FieldContainerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  overflow: hidden;
  height: 30px;
`;
const MessageWrapper = styled.div`
  font-size: 14px;
  color: #ff0033;
  @media ${({ theme }) => theme.breakpoint.md} {
    white-space: nowrap;
  }
`;

const LabelWrapper = styled.div`
  width: 100%;
  height: 30px;
  padding-left: 0;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 0px;
  }

  ${({ layoutType, theme }) => {
    if (layoutType !== 2) {
      return `
        margin-top:10px;
        @media ${theme.breakpoint.md} {
          margin-top:0;
        }
      `;
    }
    return `
      padding-left:10px;
    `;
  }}

  overflow:hidden;
`;

const ArrowWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateY(200%);
  width: 30px;
  height: 30px;
  ${({ layoutType, theme }) => {
    if (layoutType === 1) {
      return `
        @media ${theme.breakpoint.md} {
          transform: translateY(-10px);
          width: 38px;
          height: 38px;
        }
      `;
    }
    return null;
  }}
  cursor: pointer;

  ${({ layoutType, theme }) => {
    if (layoutType === 2) {
      return `
            width:26px;
            height:26px;
            transform: translateY(200%);
            @media ${theme.breakpoint.md} {
              transform: translateY(0px);
            }
        `;
    }
    return null;
  }}

  ${({ noBg }) => noBg && `background: transparent`}
`;

const initialArg = {
  current: 0,
  values: {},
  fields: {},
  total: 0,
  isComplete: false,
  progress: 0,
  error: null,
  supportsHTML5Forms: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      const total = Object.keys(action.values).length;
      return {
        ...state,
        values: action.values,
        fields: action.fields,
        supportsHTML5Forms: action.supportsHTML5Forms,
        total,
      };
    }

    case 'setComplete': {
      return {
        ...state,
        isComplete: action.payload,
      };
    }

    case 'nextQuestion': {
      if (state.current >= state.total - 1) {
        return {
          ...state,
          error: null,
          isComplete: true,
        };
      }
      return {
        ...state,
        current: state.current + 1,
        progress: ((state.current + 1) / (state.total - 1)) * 100,
        error: null,
      };
    }

    case 'prevQuestion': {
      if (state.current === 0) {
        return state;
      }
      return {
        ...state,
        current: state.current - 1,
        progress: ((state.current - 1) / (state.total - 1)) * 100,
      };
    }

    case 'setValue': {
      return {
        ...state,
        values: {
          ...state.values,
          [action.name]: action.value,
        },
        fields: {
          ...state.fields,
          [action.name]: {
            ...state.fields[action.name],
            value: action.value,
          },
        },
      };
    }

    case 'nullifyError': {
      return {
        ...state,
        error: null,
      };
    }

    case 'setError': {
      return {
        ...state,
        error: action.value,
      };
    }

    default:
      throw new Error();
  }
};

const CompactForm = ({ id, customClass, fields, type, onSubmitHandler }) => {
  const formRef = useRef(null);
  const fieldContainerRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialArg);

  const prevField = () => {
    dispatch({ type: 'prevQuestion' });
    return true;
  };

  useEffect(() => {
    if (fieldContainerRef.current !== null) {
      fieldContainerRef.current.classList.add('animate');
    }
    setTimeout(() => {
      if (fieldContainerRef.current !== null) {
        fieldContainerRef.current.classList.remove('animate');
      }
    }, 1000);
    if (state.current !== 0) {
      try {
        console.log(state.current);
        formRef.current.elements[state.current].select();
        // console.log(formRef.current.elements[state.current])
      } catch (e) {
        console.log('Compact form error onfocus');
      }
    }
  }, [state.current]);

  useEffect(() => {
    if (state.isComplete) {
      if (typeof onSubmitHandler !== 'undefined') {
        console.log('CF complete');
        onSubmitHandler(state.values);
      }
      // TODO: Notify user about an error.
    }
  }, [state.isComplete]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'setValue', name, value });

    return true;
  };

  const getCurrentName = () => {
    return Object.keys(fields)[state.current];
  };

  const validate = () => {
    if (state.values[getCurrentName()] === '' && state.fields[getCurrentName()].isRequired) {
      dispatch({ type: 'setError', value: 'This field cannot be empty!' });

      return false;
    }

    return true;
  };

  const nextField = () => {
    // Validate current field
    // If good, disable and eventually save data
    if (!validate()) {
      return false;
    }

    if (state.supportsHTML5Forms) {
      const currentField = formRef.current[getCurrentName()];
      currentField.setCustomValidity('');
      if (!currentField.checkValidity()) {
        // Optionally, set a custom HTML5 valiation message
        // comment or remove this line to use the browser default message
        // input.setCustomValidity('Whoops, that\'s not an email address!');

        // display the HTML5 error message
        dispatch({ type: 'setError', value: currentField.validationMessage });

        // prevent the question from changing
        return false;
      }
    }

    dispatch({ type: 'nextQuestion' });
    return true;
  };

  useEffect(() => {
    // setTotal(Object.keys(fields).length);

    const tmp = [];

    Object.keys(fields).map((name) => {
      if (fields[name]?.default !== undefined) {
        tmp[name] = fields[name].default;
      } else {
        tmp[name] = '';
      }
      return true;
    });

    const supportsHTML5Forms = typeof document.createElement('input').checkValidity === 'function';

    dispatch({ type: 'init', values: tmp, fields, supportsHTML5Forms });
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' || e.keyCode === 13) {
      e.preventDefault();
      nextField();
    }
  };

  return (
    <Wrapper layoutType={type}>
      <form id={id} className={`${customClass} simform`} ref={formRef} autoComplete="off">
        <FormBody layoutType={type}>
          <Row className="labelContainer" layoutType={type}>
            <FormStatus layoutType={type} current={state.current + 1} total={state.total} />
            <LabelWrapper layoutType={type}>
              <LabelContainer
                layoutType={type}
                style={{ transform: `translateY(-${parseInt(state.current, 10) * 30}px)` }}
              >
                {Object.keys(fields).map((name) => {
                  return (
                    <Label key={fields[name].key}>
                      {fields[name].label}
                      {fields[name].isRequired ? '*' : ''}
                    </Label>
                  );
                })}
              </LabelContainer>
            </LabelWrapper>
          </Row>
          <Row layoutType={type}>
            <FieldContainerWrapper>
              <FieldContainer
                ref={fieldContainerRef}
                style={{ transform: `translateY(-${parseInt(state.current, 10) * 30}px)` }}
              >
                {Object.keys(fields).map((name) => {
                  const placeholder = type === 2 ? fields[name].label : fields[name].placeholder;
                  return (
                    <Field
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      type={fields[name].type ?? 'text'}
                      layoutType={type}
                      value={state.fields[name]?.value || ''}
                      name={name}
                      placeholder={`${placeholder ?? ''}${
                        fields[name].isRequired && type === 2 ? '*' : ''
                      }`}
                      key={fields[name].key}
                      required={!!fields[name].isRequired}
                    />
                  );
                })}
              </FieldContainer>
            </FieldContainerWrapper>
            <ArrowWrapper noBg onClick={prevField} layoutType={type}>
              {state.isComplete ? (
                <FaIcon iconSize={type === 2 ? 18 : 22} icon={faSpinner} />
              ) : (
                <Arrow size={type === 2 ? 24 : 32} direction="left" color="#aaa" />
              )}
            </ArrowWrapper>
            <ArrowWrapper onClick={nextField} layoutType={type}>
              {state.isComplete ? (
                <FaIcon iconSize={type === 2 ? 18 : 22} icon={faSpinner} />
              ) : (
                <Arrow size={type === 2 ? 24 : 32} />
              )}
            </ArrowWrapper>
          </Row>
          <ProgressBar progress={state.progress} />
          <MessageWrapper>{state.error ?? '\u00A0'}</MessageWrapper>
        </FormBody>
      </form>
    </Wrapper>
  );
};

CompactForm.defaultProps = {
  id: randomID(),
  customClass: '',
  fields: {},
  type: 1,
  onSubmitHandler: undefined,
};

CompactForm.propTypes = {
  id: PropTypes.string,
  customClass: PropTypes.string,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      default: PropTypes.string,
      isRequired: PropTypes.bool,
    }),
  ),
  type: PropTypes.number,
  onSubmitHandler: PropTypes.func,
};

export default CompactForm;
