import React from 'react';
import styled from 'styled-components';

import * as mixins from '../../../../styles/mixins';

const Container = styled.div`
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 3rem;
    padding-bottom: 3rem;

    ${mixins.makeContainer}
    ${mixins.makeContainerMaxWidths}
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 1)}

  padding-top:${({ theme }) => theme.gutterSize.xs / 2}px;
  padding-bottom:${({ theme }) => theme.gutterSize.xs / 2}px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top:${({ theme }) => theme.gutterSize.md / 2}px;
    padding-bottom:${({ theme }) => theme.gutterSize.md / 2}px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow}

  ${Col}:nth-child(n+3) {
    ${mixins.makeCol(1, 2)}
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    ${Col}:nth-child(odd) {
      ${mixins.makeCol(7, 12)}
    }
    ${Col}:nth-child(even) {
      ${mixins.makeCol(5, 12)}
    }
  }
`;

const ViewOurWork = ({ children }) => {
  return (
    <Container>
      <Row>{children && React.Children.map(children, (child) => <Col>{child}</Col>)}</Row>
    </Container>
  );
};

export default ViewOurWork;
