/* eslint-disable camelcase */
import React from 'react';

import ViewOurWork from './ViewOurWork';
import SbEditable from '../../../util/SbEditable';

import TextTileBlok from './components/TextTileBlok';
import ImageTileBlok from './components/ImageTileBlok';

import getComponent from '~components/util/getComponent';

const ComponentList = {
  text_tile: TextTileBlok,
  image_tile: ImageTileBlok,
};

const ViewOurWorkBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <ViewOurWork>
        {props.blok.body &&
          props.blok.body.map((blok) =>
            React.createElement(getComponent(ComponentList, blok.component), {
              key: blok._uid,
              blok,
            }),
          )}
      </ViewOurWork>
    </SbEditable>
  );
};

export default ViewOurWorkBlok;
