import React, { useReducer } from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';

import { navigate } from 'gatsby';
import * as mixins from '../../../../styles/mixins';
import ListSliderItem from '../../FrontPage/ListSlider/ListSliderItem';
import TwoColumnsListTitle from './TwoColumnsListTitle';

export const mockData = [
  {
    _uid: '1',
    label: 'Website Design',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
  {
    _uid: '2',
    label: 'Social Media',
    image: {
      filename: '//a.storyblok.com/f/39898/2250x1500/c15735a73c/demo-image-human.jpeg',
    },
  },
  {
    _uid: '3',
    label: 'SEO',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
  {
    _uid: '4',
    label: 'Content Creation',
    image: {
      filename: '//a.storyblok.com/f/39898/2250x1500/c15735a73c/demo-image-human.jpeg',
    },
  },
  {
    _uid: '5',
    label: 'PPC',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
];

const FluidContainer = styled.div`
  ${mixins.makeContainer()}
  padding-left:0;
  padding-right: 0;
  border-bottom: 18px solid ${({ theme }) => theme.colors.grey200};

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 100px;
  }
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const ContentWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 50px;
  }
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}

    @media ${({ theme }) => theme.breakpoint.lg} {
        ${({ colWidth }) => {
          return mixins.makeCol(colWidth ?? 12, 12);
        }}
    }

    .list-slider-item-animate{ 
        left:100%;
        transition: 1.5s cubic-bezier(.55,-0.05,0,1.01) transform;

        z-index:10;

        &.list-slider-item-animate-work{
            transform: translateX(-100%);
            
        }
    }
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: auto;

  top: 0;

  ${({ isActive }) => (isActive ? `visibility:visible` : `visibility:hidden`)};

  &.forceShow {
    visibility: visible;
  }
`;

const AreaWrapper = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  overflow: hidden;
`;

const SlideWrapper = styled.div`
  width: 100%;
  height: auto;

  z-index: 10;

  overflow: hidden;
`;

const LabelList = styled.div`
  padding-bottom: 2rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    column-count: 2;
    padding-bottom: 0;
  }
`;

const LabelItemWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

const ImageAreaWrapper = styled.div`
  max-height: 288px;
  overflow: hidden;
`;

const LabelAreaWrapper = styled.div`
  padding-top: 25px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 40px;
  }
`;

const TwoColumnListSlider = ({ title, imageUrl, data, children }) => {
  if (typeof data === 'undefined' || data === null) {
    return null;
  }

  const firstObj = data[Object.keys(data)[0]];

  const [active, setActive] = useReducer((state, newState) => ({ ...state, ...newState }), {
    image: firstObj?._uid,
    label: firstObj?._uid,
  });

  const slideImage = (el, uid) => {
    if (uid === active.label) {
      return null;
    }
    const randId = `_${Math.random().toString(36).substr(2, 9)}`;

    if (typeof el !== 'object') {
      return null;
    }

    const obj = el;
    obj.id = randId;
    obj.classList.add('list-slider-item-animate');
    obj.classList.add('forceShow');

    document.querySelector('.list-slider-items').append(obj);
    setTimeout(() => {
      obj.classList.add('list-slider-item-animate-work');
    }, 10);
    setTimeout(() => {
      setActive({ image: uid });
      obj.remove();
    }, 1501);

    return null;
  };

  const onMouseHandler = (e) => {
    try {
      setActive({ label: e.target.dataset.key });
      const clonedItem = document
        .querySelector(`.list-slider-image-preview[data-key='${e.target.dataset.key}']`)
        .cloneNode(true);
      slideImage(clonedItem, e.target.dataset.key);
    } catch (err) {
      // Do we need to do anything if the user was playing with the slider?
    }
  };

  const navigateToPage = (link) => {
    navigate(`/${link.cached_url}`);
  };

  const slideItems = data.map((item) => (
    <LabelItemWrapper key={item._uid}>
      <ListSliderItem
        onClick={() => navigateToPage(item.link)}
        onMouseEnter={onMouseHandler}
        data-key={item._uid}
        key={item._uid}
        isActive={active.label === item._uid}
      >
        {item.label}
      </ListSliderItem>
    </LabelItemWrapper>
  ));

  const imgPlaceholder = () => {
    if (typeof data === 'undefined') {
      return null;
    }
    const imgUrl = data[0]?.image?.filename?.replace(/(^\w+:|^)/, '');
    const img = getFluidGatsbyImage(imgUrl, {
      maxWidth: 664,
      maxHeight: 228,
    });

    if (img === null) {
      return null;
    }

    return <Img fluid={img} />;
  };

  const slideImages = data.map((item) => {
    const imgUrl = item.image?.filename?.replace(/(^\w+:|^)/, '');
    const img = getFluidGatsbyImage(imgUrl, {
      maxWidth: 664,
      maxHeight: 228,
    });

    if (img === null) {
      return null;
    }
    return (
      <ImageWrapper
        key={item._uid}
        className="list-slider-image-preview"
        data-key={item._uid}
        isActive={active.image === item._uid}
      >
        <Img alt={item.image?.alt} title={item.image?.title} fluid={img} />
      </ImageWrapper>
    );
  });

  const prepareImg = () => {
    if (typeof imageUrl === 'undefined') {
      return null;
    }

    const url = imageUrl?.replace(/(^\w+:|^)/, '');
    if (url === '' || url === null) {
      return null;
    }

    return (
      <Img
        alt=""
        fluid={getFluidGatsbyImage(url, {
          maxWidth: 461,
          maxHeight: 228,
        })}
      />
    );
  };

  return (
    <FluidContainer>
      <Container>
        {title?.props?.html !== '<p></p>' && (
          <Row>
            <Col>
              <TwoColumnsListTitle>{title}</TwoColumnsListTitle>
            </Col>
          </Row>
        )}

        <Row>
          <Col colWidth={5}>
            <ImageAreaWrapper>{prepareImg()}</ImageAreaWrapper>
            <ContentWrapper>{children}</ContentWrapper>
          </Col>
          <Col colWidth={7}>
            <div className="list-slider-current-image">
              <AreaWrapper>
                {imgPlaceholder()}
                {slideImages}
                <SlideWrapper className="list-slider-items" />
              </AreaWrapper>
              <LabelAreaWrapper className="list-items-wrapper">
                <LabelList>{slideItems}</LabelList>
              </LabelAreaWrapper>
            </div>
          </Col>
        </Row>
      </Container>
    </FluidContainer>
  );
};

export default TwoColumnListSlider;
