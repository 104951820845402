import React from 'react';
import SbEditable from '../../../util/SbEditable';
import TwoColumnsText from './TwoColumnsText';
import Api from '../../../../util/storyblok-api';

const TwoColumnTextBlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <TwoColumnsText
        title={Api.richTextResolver.render(blok.title)}
        columnLeft={Api.richTextResolver.render(blok.column_left)}
        columnRight={Api.richTextResolver.render(blok.column_right)}
        showCta={blok.show_cta}
      />
    </SbEditable>
  );
};

export default TwoColumnTextBlok;
