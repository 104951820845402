import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import mapStyle from './styles';

const MapContainer = styled.div`
  height: 274px;
  position: relative;

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 463px;
  }
`;

const MapWrapper = styled.div`
  height: 274px;

  @media ${({ theme }) => theme.breakpoint.md} {
    position: absolute;
    width: calc(50vw - ${({ theme }) => theme.gutterSize.md / 2}px);
    height: 463px;

    ${({ alternative }) => alternative && `right:0;`}

    max-width: 801px;
  }
`;

const MapPin = styled.div`
  width: 36px;
  height: 36px;

  border-radius: 50%;

  background: ${({ theme }) => theme.colors.secondary}19; /* its rgba a=0.1 */
  position: relative;

  :before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);

    width: 2px;
    height: 43px;
    background: ${({ theme }) => theme.colors.secondary};
  }
  :after {
    content: '';
    position: absolute;

    bottom: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 18px));

    width: 17px;
    height: 17px;
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
  }
`;

const Marker = () => <MapPin />;

const createMapOptions = () => {
  return {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: mapStyle,
  };
};

const Map = ({ alternative, mapCenter, mapMarker, mapZoom }) => {
  const center = {
    lat: parseFloat(mapCenter?.lat) ?? 59.95,
    lng: parseFloat(mapCenter?.lng) ?? 30.33,
  };

  const markerCenter = {
    lat: parseFloat(mapMarker?.lat) ?? 60,
    lng: parseFloat(mapMarker?.lng) ?? 30.337844,
  };

  const zoom = mapZoom ?? 15;

  return (
    <MapContainer>
      <MapWrapper alternative={alternative}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAKxjNhNXvUZWWNGDhAF5quCUBb1bFxtNA' }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={createMapOptions}
        >
          <Marker lat={markerCenter.lat} lng={markerCenter.lng} />
        </GoogleMapReact>
      </MapWrapper>
    </MapContainer>
  );
};

export default Map;
