import React from 'react';
import styled from 'styled-components';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~util/storyblok-api';
import NumberRow from './NumberRow';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.breakpoint.md} {
    /* padding-top: 50px; */
  }
  & + & {
    padding-top: 85px;
  }
`;

const CopyWrapper = styled.div`
  width: 100%;
  justify-content: flex-start;

  @media ${({ theme }) => theme.breakpoint.md} {
    /* & + & { */
    padding-top: 15px;
    /* } */
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 50%;
  }

  display: flex;
  align-items: top;
`;

const NumberColWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 50%;
    align-items: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CopyNumber = styled(SanitizeHTML)`
  padding-left: 5px;
  font-weight: bold;
  font-size: 15px;
  .smaller {
    font-weight: 400;
  }
`;
const CopyContainer = styled(SanitizeHTML)`
  max-width: 80%;

  @media ${({ theme }) => theme.breakpoint.lg} {
    max-width: initial;
  }
`;

const NumberWrapper = styled.div`
  font-size: 85px;
  text-align: left;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    padding-top: 20px;
    text-align: left;
  }
  line-height: 90px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;
const UnitWrapper = styled.div`
  /* padding-top: 10px; */
  font-size: 30px;
  line-height: 20px;
  text-align: left;

  transform: translateY(-15px);
  text-transform: uppercase;
  letter-spacing: 0;
  @media ${({ theme }) => theme.BreakpointDown.md} {
    /* padding-top: 20px; */
    text-align: left;
  }
`;

const PosWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoint.md} {
    /* padding-left: 30px; */
    align-self: flex-start;
    padding-left: 35%;
  }
`;

const Number = ({ blok }) => {
  const copy = Api.richTextResolver.render(blok?.copy);
  const numbers = Api.richTextResolver.render(blok?.unit);
  // console.log(blok);
  return (
    <Wrapper>
      <NumberColWrapper>
        <PosWrapper>
          <NumberWrapper>
            {blok && <NumberRow key={blok.number[0]._uid} numberContent={blok.number[0].number} />}
          </NumberWrapper>
          <UnitWrapper>
            <CopyNumber html={numbers} />
          </UnitWrapper>
        </PosWrapper>
      </NumberColWrapper>
      <CopyWrapper>
        <CopyContainer html={copy} />
      </CopyWrapper>
    </Wrapper>
  );
};

export default Number;
