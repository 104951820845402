import React, { useRef } from 'react';
import styled from 'styled-components';
import SbEditable from '~components/util/SbEditable';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import Row from '~components/Layout/Row';
import Container from '~components/Layout/Container';
import Col from '~components/Layout/Col';

import * as mixins from '~styles/mixins';
import AnchorButton from '~components/Button/AnchorButton';

const AlteredListSliderTitle = styled(ListSliderTitle)`
  & .greytext {
    color: #abaaae;
  }
`;

const OptionalTextWrapper = styled.div`
${mixins.makeCol()}

@media ${({ theme }) => theme.breakpoint.lg} {
  
  ${mixins.makeCol(7, 12)}
}
`;

const AnchorButtonWrapper = styled.div`
  align-items: center;
  .mobileText {
    margin-top: 30px;
    display: block;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    width: 40%;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: none;
    }
  }
  ${({ hideMobile }) => hideMobile && `display: none;`}
  @media ${({ theme }) => theme.breakpoint.md} {
    display: flex;
  }
`;

const Hero = (props) => {
  const { blok } = props;

  const ref = useRef(null);

  return (
    <Container ref={ref}>
      <Row>
        <Col>
          <SbEditable>
            <AlteredListSliderTitle>
              <SanitizeHTML
                html={
                  Api.richTextResolver.render(blok.main_text) === ''
                    ? blok.main_text
                    : Api.richTextResolver.render(blok.main_text)
                }
              />
            </AlteredListSliderTitle>
            {typeof blok.optional_text !== 'undefined' && blok.optional_text !== '' && (
              <OptionalTextWrapper>
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(blok.optional_text) === ''
                      ? blok.optional_text
                      : Api.richTextResolver.render(blok.optional_text)
                  }
                />
              </OptionalTextWrapper>
            )}
            <AnchorButtonWrapper hideMobile={blok.mobile_disable_cta}>
              <AnchorButton parentRef={ref} />
              <span className="mobileText">
                {blok.mobile_cta_text !== '' && blok.mobile_cta_text}
              </span>
            </AnchorButtonWrapper>
          </SbEditable>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
