import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';

const ContentSide = styled.div`
  min-height: 475px;
  flex: 1;
  display: grid;
  place-items: center;
  padding-left: 140px;
  padding-right: 46px;

  @media ${({ theme }) => theme.BreakpointDown.lg} {
    padding: 0;
    min-height: auto;
  }
`;
const SliderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${({ theme }) => theme.BreakpointDown.lg} {
    flex-direction: column !important;
  }
`;
const Heading = styled.h2`
  margin: 0px;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
`;
const SubHeading = styled.h3`
  margin: 0px;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
`;
const Number = styled.div`
  font-family: 'Avenir Next';
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 25px;
  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 22px;
  }

  @media ${({ theme }) => theme.BreakpointDown.lg} {
    margin-top: 22px;
    margin-bottom: 25px;
  }
`;
const WeCanHelpYouWithSliderItem = ({ fluidDataCms, item, items, index }) => {
  const defaultImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-placeholder.png" }) {
        childImageSharp {
          fluid(maxHeight: 475) {
            aspectRatio
            src
            srcSet
            sizes
            originalName
          }
        }
      }
    }
  `);

  let fluidData = fluidDataCms;
  if (typeof fluidData === 'undefined' || fluidData == null || fluidData === '') {
    fluidData = defaultImage.file.childImageSharp.fluid;
  }
  return (
    <SliderWrapper>
      <ContentSide>
        <div>
          <Number>
            <span>{index + 1}</span>/{items.length}
          </Number>
          <Heading>{item.heading}</Heading>
          <SubHeading>{item.subheading}</SubHeading>
          <SanitizeHTML html={Api.richTextResolver.render(item.description)} />
        </div>
      </ContentSide>
    </SliderWrapper>
  );
};

export default WeCanHelpYouWithSliderItem;
