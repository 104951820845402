import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Grid from './Grid';

const GridBlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <Grid members={blok.member} />
    </SbEditable>
  );
};

export default GridBlok;
