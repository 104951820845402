import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import SanitizeHTML from '~components/util/SanitizeHTML';

import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';
import * as mixins from '~styles/mixins';
import SocialIcon from '~components/Button/SocialIcon';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 70px;
    padding-bottom: 50px;
  }
`;

const HeadingCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
`;

const MetaDataCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

  padding-top:5px;
  padding-bottom: 5px;

  @media ${({ theme }) => theme.breakpoint.md} {
    ${mixins.makeCol(5, 12)}
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(4, 12)}
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    ${mixins.makeCol(3, 12)}
  }
`;
const MetaData = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.light};
  ${({ theme, bold }) => bold && `font-weight: ${theme.font.weight.bold};`}

  text-transform: uppercase;

  font-size: 18px;
  line-height: 25px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    line-height: 30px;
  }
`;

const SocialCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  align-items:center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;

  position: absolute; 
  background: #fff;
  transition: 0.2s box-shadow ease-in-out;

`;

const Row = styled.div`
  ${mixins.makeRow()}

  &.meta-data {
    margin-bottom: 20px;
    padding: 5px 0 5px 0;
    @media ${({ theme }) => theme.breakpoint.md} {
      margin-bottom: 0;
      padding: 10px 0 30px 0;
    }
    @media ${({ theme }) => theme.breakpoint.xl} {
      padding: 20px 0 60px 0;
    }
  }

  &.share-mobile {
    position: relative;
    height: 52px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    @media ${({ theme }) => theme.breakpoint.sm} {
      box-shadow: initial;
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      display: none;
    }
    background: #fff;

    &.sticky ${SocialCol} {
      position: fixed;
      z-index: 5;
      top: 72px;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
      @media ${({ theme }) => theme.breakpoint.sm} {
        box-shadow: initial;
        position: relative;
        top: 0;
      }
    }
  }
`;

const SocialTitle = styled.div`
  display: block;

  font-size: 22px;
  padding-right: 25px;
  @media ${({ theme }) => theme.breakpoint.sm} {
    flex-grow: 1;
    max-width: 150px;
  }
  @media (max-width: 360px) {
    flex-grow: 1;
  }
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 30px;
`;

const SocialIcons = styled.div`
  display: flex;
`;

const StyledIcon = styled(SocialIcon)`
  flex-shrink: 0;
  & + & {
    margin-left: 15px;
  }
`;

const Heading = ({ url, data }) => {
  const shareMobile = useRef(null);

  const date = new Date(data.published_at);
  const formattedDate = date.toLocaleString('default', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScrollSocialPos = () => {
        const scrollPos =
          window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop;

        if (shareMobile.current.offsetTop - scrollPos <= 72) {
          shareMobile.current.classList.add('sticky');
        } else {
          shareMobile.current.classList.remove('sticky');
        }
      };
      onScrollSocialPos();
      window.addEventListener('resize', onScrollSocialPos);
      window.addEventListener('scroll', onScrollSocialPos);
      return () => {
        window.removeEventListener('resize', onScrollSocialPos);
        window.removeEventListener('scroll', onScrollSocialPos);
      };
    }
    return null;
  }, []);

  return (
    <Container>
      <Row>
        <HeadingCol>
          <ListSliderTitle>
            <SanitizeHTML html={data.title} />
          </ListSliderTitle>
        </HeadingCol>
      </Row>
      <Row className="meta-data">
        <MetaDataCol>
          <MetaData bold>{data.author}</MetaData>
          <MetaData>{data.author_title}</MetaData>
        </MetaDataCol>
        <MetaDataCol>
          <MetaData bold>{formattedDate}</MetaData>
          <MetaData>{data.read_time}</MetaData>
        </MetaDataCol>
      </Row>
      <Row ref={shareMobile} className="share-mobile">
        <SocialCol>
          <SocialTitle>Share</SocialTitle>
          <SocialIcons>
            <StyledIcon
              href={`https://www.facebook.com/sharer.php?u=${url}`}
              title="Share this article on Facebook"
              icon="facebook"
              buttonSize={32}
            />
            <StyledIcon
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
              title="Share this article on LinkedIn"
              icon="linkedin"
              buttonSize={32}
            />
          </SocialIcons>
        </SocialCol>
      </Row>
    </Container>
  );
};

export default Heading;
