import React from 'react';
import styled from 'styled-components';
import Api from '~components/../util/storyblok-api';
import SbEditable from '~components/util/SbEditable';
import SanitizeHTML from '~components/util/SanitizeHTML';

import Row from '~components/Layout/Row';
import Col from '~components/Layout/Col';
import Container from '~components/Layout/Container';

import ProjectGrid from '~components/PageSpecific/Work/ProjectGrid/ProjectGrid';

const SubHeading = styled.p`
  color: #272728;
  width: 50%;
`;
const Heading = styled.h2`
  p {
    line-height: 2.3125rem;
    font-size: 2rem;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    p {
      line-height: 3.75rem;
      font-size: 3.125rem;
    }
  }
  .grey {
    color: #abaaad;
  }
`;

const AlteredContainer = styled(Container)`
  padding-bottom: 0px !important;
`;

const OurRecentProjectsBlok = (props) => {
  const { blok } = props;
  const { heading } = props.blok;
  // console.log(blok);

  return (
    <SbEditable content={blok}>
      <AlteredContainer>
        <Row>
          <Col>
            <Heading>
              <SanitizeHTML
                html={
                  Api.richTextResolver.render(heading) === ''
                    ? heading
                    : Api.richTextResolver.render(heading)
                }
              />
            </Heading>
            <SubHeading>{blok?.subheading}</SubHeading>
          </Col>
        </Row>
      </AlteredContainer>
    </SbEditable>
  );
};

export default OurRecentProjectsBlok;
