import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Api from '../../../../../util/storyblok-api';

import ImageTile from './ImageTile';
import SanitizeHTML from '../../../../util/SanitizeHTML';

const ImageTileBlok = ({ blok }) => {
  const imgUrl = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgUrl, {
    maxWidth: 660,
  });

  return (
    <ImageTile fluidDataCms={img} button={blok.button[0]} overlay={blok.overlay}>
      <SanitizeHTML html={Api.richTextResolver.render(blok.content)} />
    </ImageTile>
  );
};

export default ImageTileBlok;
