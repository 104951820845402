import React from 'react';
import SbEditable from '~components/util/SbEditable';
import ParallaxImage from './ParallaxImage';

const ParallaxImageBlok = (props) => {
  const { blok } = props;
  return (
    <SbEditable content={blok}>
      <ParallaxImage blok={blok} />
    </SbEditable>
  );
};

export default ParallaxImageBlok;
