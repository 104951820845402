import styled from 'styled-components';
import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer}
  ${mixins.makeContainerMaxWidths}
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;

export default Container;
