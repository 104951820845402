import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as mixins from '../../../../styles/mixins';
import Button from '../../../Button/Button';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  p {
    margin-top: initial;
    margin-bottom: initial;
    color: ${({ theme }) => theme.colors.primary};
  }

  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    
    @media ${({ theme }) => theme.breakpoint.xl} {
        ${({ colWidth }) => {
          return mixins.makeCol(colWidth, 12);
        }}
    }
`;

const Text = styled.h1`
  .greytext {
    color: #abaaae;
  }
  .pinktext {
    color: ${({ theme }) => theme.colors.secondary};
  }

  display: inline-block;
  font-size: ${({ theme }) => theme.font.typography.hero.size.xs};
  line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.xs};

  font-weight: ${({ theme }) => theme.font.weight.light};

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: ${({ theme }) => theme.font.typography.hero.size.md};
    line-height: ${({ theme }) => theme.font.typography.hero.size.md};
  }

  .lines-full {
    @media ${({ theme }) => theme.breakpoint.md} {
      line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.md};
    }
  }

  margin-top: 0;
  margin-bottom: 0;

  p {
    margin-bottom: 0.5em;
  }

  b {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

const HeroFront = ({ button, colWidth, children }) => {
  return (
    <Container>
      <Row>
        <Col colWidth={colWidth}>
          <Text>{children}</Text>
          {button?.url?.cached_url && (
            <Button link={button?.url?.cached_url} variant="secondary">
              {button.label}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

HeroFront.defaultProps = {
  colWidth: 10,
};

HeroFront.propTypes = {
  colWidth: PropTypes.number,
  // button: PropTypes.shape({
  //   url: PropTypes.string.isRequired,
  //   label: PropTypes.string.isRequired,
  // }).isRequired,
};

export default HeroFront;
