import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Parallax } from 'react-scroll-parallax';

import * as mixins from '~styles/mixins';

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  padding-left:0;
  padding-right: 0;

  position: relative;
  overflow: hidden;
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const PhantomRow = styled(Row)`
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: -1;
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
`;

const HalfCol = styled(Col)`
  @media ${({ theme }) => theme.breakpoint.md} {
    ${mixins.makeCol(1, 2)}
  }
`;

const HalfColNoPadding = styled(HalfCol)`
  padding-right: 0 !important;
`;

const BgDiv = styled.div`
  @media ${({ theme }) => theme.breakpoint.md} {
    background-color: ${({ bgColor }) => bgColor};
  }
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const HalfColPar = styled(HalfCol)`
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.BreakpointDown.md} {
    margin-top: 25px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    justify-content: flex-end;
  }
`;

const ParContainer = styled.div`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: flex-end;
  @media ${({ theme }) => theme.BreakpointDown.md} {
    overflow: hidden;
    justify-content: center;
  }
  display: flex;
`;

const BgMobile = styled.div`
  position: absolute;

  background-color: ${({ bgColor }) => bgColor};

  left: 50%;
  width: 100vw;
  top: 0;
  bottom: 0;
`;

const AlteredParallax = styled(Parallax)`
  margin: 0;

  .parallax-inner {
    box-shadow: 0 30px 20px rgba(0, 0, 0, 0.5);
    @media ${({ theme }) => theme.breakpoint.md} {
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.5);
    }
  }

  width: 80%;

  @media ${({ theme }) => theme.breakpoint.md} {
    width: 222px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 350px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    width: 415px;
  }
`;

const MockupContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const MockupImage = styled(Img)`
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.28);
  @media ${({ theme }) => theme.breakpoint.md} {
    width: 210px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 310px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    width: 405px;
  }
`;

const ImageModule = ({ imageLeft, imageRight, colorRight, colorLeft }) => {
  return (
    <FluidContainer>
      <PhantomRow>
        <HalfCol />
        <HalfColNoPadding>
          <BgDiv bgColor={colorRight} />
        </HalfColNoPadding>
      </PhantomRow>
      <Row>
        <Col>
          <Container>
            <Row>
              <HalfCol>
                <MockupContainer bgColor={colorLeft}>
                  {imageLeft && <MockupImage fluid={imageLeft} />}
                </MockupContainer>
              </HalfCol>
              <HalfColPar>
                <ParContainer bgColor={colorRight}>
                  <BgMobile bgColor={colorRight} />
                  <AlteredParallax className="custom-class" y={[-40, 40]} tagOuter="figure">
                    {imageRight && (
                      <Img
                        alt="One of two images of the Image module"
                        title="One of two images of the image module"
                        fluid={imageRight}
                      />
                    )}
                  </AlteredParallax>
                </ParContainer>
              </HalfColPar>
            </Row>
          </Container>
        </Col>
      </Row>
    </FluidContainer>
  );
};

export default ImageModule;
