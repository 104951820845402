import React from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import SEO from '~components/util/seo';

import HeroBlok from '~components/PageSpecific/IndividualServices/Hero/HeroBlok';
import SidedTextBlockBlok from '~components/PageSpecific/AboutUs/SidedTextBlock/SidedTextBlockBlok';
import InterestedInUsing from '~components/PageSpecific/IndividualServices/InterestedInUsing/InterestedInUsing';
import LetsMakeSomethingTogether from '~components/Contact/LetsMakeContactBlok';
import WeCanHelpYouWithSliderBlok from '~components/PageSpecific/IndividualServices/WeCanHelpYouWithSlider/WeCanHelpYouWithSliderBlok';
import ParallaxImageBlok from '~components/PageSpecific/IndividualServices/ParallaxImage/ParallaxImageBlok';
import ImageAndTile from '~components/PageSpecific/IndividualServices/ImageAndTile/ImageAndTileBlok';
import DoubleImageAndTextBlok from '~components/PageSpecific/IndividualServices/DoubleImageAndText/DoubeImageAndTextBlok';

const AlteredDoubleImageAndTextBlok = styled(DoubleImageAndTextBlok)``;
const SwapSides = styled.section`
  ${AlteredDoubleImageAndTextBlok}:nth-child(odd) {
    order: 2;
  }
`;

const IndividualService = (props) => {
  return (
    <>
      <SEO seo={props.blok.seo} defaultImage={props.globalLayout?.og_image_default?.filename} />
      <Helmet>
        <meta name="viewport" content="initial-scale=1, minimum-scale=1, maximum-scale=1" />
      </Helmet>
      {props.blok.hero?.length > 0 && <HeroBlok blok={props.blok.hero[0]} />}
      {props.blok.reach_out?.length > 0 && <SidedTextBlockBlok blok={props.blok.reach_out[0]} />}
      {props.blok.we_can_help_you_slider_slider?.length > 0 && (
        <WeCanHelpYouWithSliderBlok blok={props.blok} />
      )}
      {props.blok.lets_make_something_together?.length > 0 && (
        <LetsMakeSomethingTogether blok={props.blok.lets_make_something_together[0]} />
      )}
      {props.blok.view_casestudy?.length > 0 && (
        <ImageAndTile blok={props.blok.view_casestudy[0]} />
      )}
      <SwapSides>
        {props.blok.DoubleImageAndTextBlok?.length > 0 &&
          props.blok.DoubleImageAndTextBlok.map((block, i) => {
            return (
              <AlteredDoubleImageAndTextBlok
                className={i % 2 === 0 ? 'odd' : 'even'}
                key={block._uid}
                blok={block}
              />
            );
          })}
      </SwapSides>
      {props.blok.image?.length > 0 && <ParallaxImageBlok blok={props.blok.image[0]} />}
      <InterestedInUsing />
    </>
  );
};

export default IndividualService;
