import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled, { ThemeContext } from 'styled-components';
import Slider from 'react-slick';

import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';
import ButtonComponent from '~components/Button/Button';
import Project from './Project';
import * as mixins from '~styles/mixins';

const BgContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey100};
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  overflow: visible;
`;

const Row = styled.div`
  ${mixins.makeRow()}
  overflow: visible;

  &.mobilePadding {
    padding-top: 2rem;
    @media ${({ theme }) => theme.breakpoint.sm} {
      padding-top: 2rem;
    }
  }
`;

const HeadingCol = styled.div`
  ${mixins.makeColReady()}
  width: 100%;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.breakpoint.sm} {
    width: auto;
  }

  ${({ grow }) => grow && `flex-grow:1;`}
`;

const SliderWrapper = styled.div`
  width: 100%;

  .slick-slider .slick-list .slick-track {
    left: -15px;
    @media ${({ theme }) => theme.breakpoint.sm} {
      left: initial;
    }
  }
`;

const Button = styled(ButtonComponent)`
  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: initial;
  }
`;

const Heading = styled(ListSliderTitle)`
  h2 {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SimilarProjects = ({ projects }) => {
  const themeContext = useContext(ThemeContext);

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    arrows: false,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    easing: 'ease-in-out',
    responsive: [
      {
        breakpoint: parseInt(themeContext.breakpointRaw.lg, 10) - 0.1,
        settings: {
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          // centerMode: true,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: parseInt(themeContext.breakpointRaw.sm, 10) - 0.1,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15px',
        },
      },
    ],
  };

  const prepareStory = (props) => {
    const story = Object.assign({}, props);
    story.content = JSON.parse(story.content);
    return story;
  };

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allStoryblokEntry(filter: { field_component: { in: "individual_project" } }) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `);

  const individualProjects = data.allStoryblokEntry.edges
    .filter((item) => {
      return projects.includes(item.node.uuid);
    })
    .map((item) => prepareStory(item.node));

  return (
    <BgContainer>
      <Container>
        <Row>
          <HeadingCol grow>
            <Heading>Similar Projects</Heading>
          </HeadingCol>
          <HeadingCol>
            <Button variant="secondary" link="projects">
              View all projects
            </Button>
          </HeadingCol>
        </Row>
        <Row className="mobilePadding">
          <SliderWrapper>
            <Slider style={{ width: '100%' }} {...settings}>
              {individualProjects.map((project) => {
                return (
                  <StyledLink to={`/${project.full_slug}`} key={project.uuid}>
                    <Project project={project} />
                  </StyledLink>
                );
              })}
            </Slider>
          </SliderWrapper>
        </Row>
      </Container>
    </BgContainer>
  );
};

export default SimilarProjects;
