import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SocialIcon from '~components/Button/SocialIcon';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const Row = styled.div`
  ${mixins.makeRow()}

  padding-top: 50px;
  padding-bottom: 50px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 82px;
    padding-bottom: 82px;
  }
`;

const SocialIconWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
`;
const SocialCol = styled.div`
  display: none;
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeColReady()}
    ${mixins.makeCol(1, 6)}
    display: flex;
    flex-direction: column;
  }

  &.sticky ${SocialIconWrapper} {
    position: fixed;
    top: 104px;
  }

  &.sticky.end {
    justify-content: flex-end;
    ${SocialIconWrapper} {
      position: relative;
      top: 0;
    }
  }
`;
const ContentCol = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    
    @media ${({ theme }) => theme.breakpoint.lg} {
        ${mixins.makeCol(4, 6)}
    }

    img {
        width: 100%;
    }

    blockquote {
        margin:0;
        padding: 16px 20px;

        font-size: 24px;
        line-height: 33px;
        border-left: 3px solid ${({ theme }) => theme.colors.secondary};
        color: ${({ theme }) => theme.colors.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};
        @media ${({ theme }) => theme.breakpoint.md} {
            padding: 33px 40px;
            font-size: 36px;
            line-height: 49px;
        }
    }
    p {
        letter-spacing: 0.8px;
        margin-bottom: 7px;
    }
    b {
        font-weight: ${({ theme }) => theme.font.weight.bold};
    }
`;

const SocialTitle = styled.div`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};

  font-size: 22px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 30px;
  padding-bottom: 5px;
`;

const SocialIcons = styled.div`
  padding-top: 10px;
  display: flex;
`;

const StyledIcon = styled(SocialIcon)`
  flex-shrink: 0;
  & + & {
    margin-left: 15px;
  }
`;

const Content = ({ url, children }) => {
  const shareRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScrollSocialPos = () => {
        const scrollPos =
          window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
        if (shareRef.current.offsetTop - scrollPos <= 104) {
          shareRef.current.classList.add('sticky');
          if (shareRef.current.offsetTop + shareRef.current.offsetHeight - scrollPos <= 104 + 78) {
            shareRef.current.classList.add('end');
          } else {
            shareRef.current.classList.remove('end');
          }
        } else {
          shareRef.current.classList.remove('sticky');
        }
      };
      onScrollSocialPos();
      window.addEventListener('resize', onScrollSocialPos);
      window.addEventListener('scroll', onScrollSocialPos);
      return () => {
        window.removeEventListener('resize', onScrollSocialPos);
        window.removeEventListener('scroll', onScrollSocialPos);
      };
    }
    return null;
  }, []);

  return (
    <Container>
      <Row>
        <SocialCol ref={shareRef}>
          <SocialIconWrapper>
            <SocialTitle>Share</SocialTitle>
            <SocialIcons>
              <StyledIcon
                href={`https://www.facebook.com/sharer.php?u=${url}`}
                title="Share this article on Facebook"
                icon="facebook"
                buttonSize={32}
              />
              <StyledIcon
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                title="Share this article on LinkedIn"
                icon="linkedin"
                buttonSize={32}
              />
            </SocialIcons>
          </SocialIconWrapper>
        </SocialCol>
        <ContentCol>{children}</ContentCol>
      </Row>
    </Container>
  );
};

export default Content;
