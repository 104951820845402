/* eslint-disable camelcase */
import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import TabletPortfolio from './TabletPortfolio';
import Logo from './Logo';
import { randomID } from '../../../MinimalForm/util';

const TabletPortfolioBlok = ({ blok }) => {
  const title = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(blok.title)} />;
  };

  const content = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(blok.content)} />;
  };

  const getLogos = () => {
    return blok?.images?.map((it) => {
      const imgUrl = it.filename?.replace(/(^\w+:|^)/, '');
      const img = getFluidGatsbyImage(imgUrl, { maxWidth: 136 });
      return <Logo key={randomID()} fluidData={img} />;
    });
  };

  return (
    <SbEditable content={blok}>
      <TabletPortfolio greyBackground={blok?.grey_background} title={title()} content={content()}>
        {getLogos()}
      </TabletPortfolio>
    </SbEditable>
  );
};

export default TabletPortfolioBlok;
