import React from 'react';
import styled from 'styled-components';

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 2px;

  background: #d4d4d4;
  overflow: hidden;
`;

const ProgressBarLine = styled.div`
  width: 100%;
  height: 100%;

  transform: translateX(-100%);
  transition: transform 0.5s ease;
  background: ${({ theme }) => theme.colors.secondary};
`;

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarLine style={{ transform: `translateX(${-100 + parseInt(progress, 10)}%)` }} />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
