/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ThemeContext from '~contexts/ThemeContext';

import * as mixins from '../../styles/mixins';
import MinimalForm from '../MinimalForm/MinimalForm';

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  ${({ theme }) => {
    return `
            background: ${theme.colors.secondary};
           @media ${theme.breakpoint.md} {
                background: linear-gradient(90deg, ${theme.colors.secondary} calc(50% + ${
      (parseInt(theme.containerSize.md, 10) * 1) / 12
    }px), #FFF calc(50% + ${parseInt(theme.containerSize.md, 10) / 12}px));
            }
            @media ${theme.breakpoint.lg} {
                background: linear-gradient(90deg, ${theme.colors.secondary} calc(50% + ${
      parseInt(theme.containerSize.lg, 10) / 12
    }px), #FFF calc(50% + ${parseInt(theme.containerSize.md, 10) / 12}px));
            }
            @media ${theme.breakpoint.xl} {
                background: linear-gradient(90deg, ${theme.colors.secondary} calc(50% + ${
      parseInt(theme.containerSize.xl, 10) / 12
    }px), #FFF calc(50% + ${parseInt(theme.containerSize.md, 10) / 12}px));
           }
       `;
  }}
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const CopyCol = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol(1, 1)}
    
    @media ${({ theme }) => theme.breakpoint.md} {
        ${mixins.makeCol(5, 12)}
        padding-bottom:4rem;
    }
    @media ${({ theme }) => theme.breakpoint.lg} {
        ${mixins.makeCol(6, 12)}
    }

    padding-top:4rem;
`;

const FormCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 1)}

    background: #fff;
  @media ${({ theme }) => theme.breakpoint.md} {
    background: linear-gradient(90deg, #fff 50%, transparent 50%);
    ${mixins.makeCol(7, 12)}
    background: #fff;
    margin-top: 3rem;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(1, 2)}
  }
  margin-top: 1.5rem;

  display: flex;
`;

const FormColContainer = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
    height:100%;

  background: #fff;

  flex-grow: 1;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TitleWrapper = styled.div`
  color: #fff;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.font.typography.hero.size.xs};
  line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.xs};

  @media ${({ theme }) => theme.breakpoint.lg} {
    font-size: ${({ theme }) => theme.font.typography.hero.size.md};
    line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.md};
  }

  margin: 0;

  span.thin {
    font-weight: ${({ theme }) => theme.font.weight.light};

    display: block;
  }
`;

const FormWrapper = styled.div`
  padding: 1rem 0rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 4rem 0rem;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding: 4rem 2rem;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    padding: 4rem;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
  }
`;

const WrapperCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 1)}
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const MobileBackground = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 0;

  @media ${({ theme }) => theme.breakpoint.md} {
    display: none;
  }
`;

const BottomText = styled.div`
  padding-top: 0.2rem;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 2.2rem;
  }
`;

const SocialItem = styled.div`
  width: auto;

  padding-left: 7.5px;
  padding-right: 7.5px;

  position: relative;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 20px;
    padding-right: 20px;
    &:first-child {
      padding-left: 0;
    }
    &:not(:first-child):before {
      content: '';

      position: absolute;
      left: 0;

      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: 11px;
      background: #979797;
    }
  }
`;

const SocialArea = styled.div`
  /* border-top: 1px solid #e6e2e2; */

  display: flex;

  flex-direction: column;
  /* margin-top: 30px; */
  @media ${({ theme }) => theme.breakpoint.md} {
    /* margin-top: 40px; */
    flex-direction: row;
  }

  /* padding-top: 22.5px; */
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 27px;
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
`;
const StartingProject = () => {
  const { themeState } = useContext(ThemeContext);
  const [formComplete, setFormComplete] = useState(false);
  const [formState, setFormState] = useState(false);

  const fieldsMockData = {
    name: {
      key: 'name',
      label: 'Write your name',
      placeholder: 'Enter your name',
      default: '',
      isRequired: true,
    },
    email: {
      key: 'surname',
      type: 'email',
      label: 'Enter your email address',
      placeholder: 'Enter your email address',
      default: '',
      isRequired: true,
    },
    phone: {
      key: 'phone',
      label: 'Enter your telephone',
      placeholder: 'Enter your telephone',
      default: '',
      isRequired: true,
    },
    subject: {
      key: 'subject',
      label: 'What services are you interested in?',
      placeholder: 'What services are you interested in?',
      default: '',
      isRequired: true,
    },
    website: {
      key: 'website',
      label: 'Please provide a link to your website',
      placeholder: 'Please provide a link to your website',
      default: '',
    },
  };

  const onSubmitHandler = (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    // for(const name in values) {
    //   formData.append(name, values[name]);
    // }

    fetch('//forms.mkpactive.com/mail.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        setFormComplete(true);
        // do something awesome that makes the world a better place
        if (data.status === true) {
          setFormState(true);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Form Submitted',
            category: 'Starting Project',
            action: 'Submit',
            label: 'Gatsby',
          });
        } else {
          setFormState(false);
        }
      })
      .catch(() => {
        setFormComplete(true);
        setFormState(false);
      });
  };

  const SuccessResponse = () => {
    return (
      <h2>
        Thank you for your message. <br />
        <small>A member of the team will be in touch soon</small>
      </h2>
    );
  };

  const ErrorResponse = () => {
    return (
      <>
        <h2>
          There was a problem with sending your message. <br />
          <small>Please try again later.</small>
        </h2>
      </>
    );
  };

  return (
    <FluidContainer>
      <Row>
        <WrapperCol>
          <Container>
            <Row>
              <CopyCol>
                <TitleWrapper>
                  <Title>
                    Starting a project? <span className="thin">We can help.</span>
                  </Title>
                </TitleWrapper>
              </CopyCol>
              <FormCol>
                <MobileBackground />
                <FormColContainer>
                  <FormWrapper>
                    {formComplete ? (
                      formState ? (
                        <SuccessResponse />
                      ) : (
                        <ErrorResponse />
                      )
                    ) : (
                      <MinimalForm onSubmitHandler={onSubmitHandler} fieldsData={fieldsMockData} />
                    )}
                    <BottomText>
                      <SocialArea>
                        <SocialItem>
                          <Icon icon={faPhoneAlt} />
                          <SocialLink
                            target="_blank"
                            rel="noreferrer"
                            href={`tel:${themeState?.phone}`}
                          >
                            {`${themeState?.phone?.substr(0, 4)} ${themeState?.phone?.substr(
                              4,
                              3,
                            )} ${themeState?.phone?.substr(7)}`}
                          </SocialLink>
                        </SocialItem>
                        {/* <SocialItem>
                          <Icon icon={faWhatsapp} />
                          <SocialLink
                            target="_blank"
                            rel="noreferrer"
                            href={`${themeState?.whatsapp ?? `#`}`}
                          >
                            WhatsApp us
                          </SocialLink>
                        </SocialItem> */}
                      </SocialArea>
                      Or email us at{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:${themeState?.email ?? 'hello@ccity.co.uk'}`}
                      >
                        {themeState?.email ?? 'hello@ccity.co.uk'}
                      </a>
                    </BottomText>
                  </FormWrapper>
                </FormColContainer>
              </FormCol>
            </Row>
          </Container>
        </WrapperCol>
      </Row>
    </FluidContainer>
  );
};

export default StartingProject;
