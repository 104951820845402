import React from 'react';
import styled from 'styled-components';

import Arrow from '~components/util/arrow';
import scrollTo from './util';

const CircleButtonIcon = styled.div`
  width: 52px;
  height: 52px;
  transition: 0.5s transform ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  margin-right: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondary};
  margin-top: 30px;

  cursor: pointer;
  &:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  }
`;

const AnchorButton = ({ parentRef, ...props }) => {
  const getRefPos = (ref) => {
    return ref.current.offsetTop + ref.current.offsetHeight - 80;
  };

  /*   const scrollToRef = (ref) =>
    window.scrollTo({
      top: getRefPos(ref),
      behavior: 'smooth',
    }); */

  const scrollToRefAlt = (ref) => {
    scrollTo(getRefPos(ref));
  };

  const onClickHandler = () => {
    if (typeof parentRef !== 'undefined' || parentRef !== null) {
      scrollToRefAlt(parentRef);
    }
  };

  return (
    <CircleButtonIcon onClick={onClickHandler} {...props}>
      <Arrow size={32} direction="bottom" color="white" />
    </CircleButtonIcon>
  );
};

export default AnchorButton;
