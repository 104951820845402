import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Banner = ({ image }) => {
  const imageRaw = image?.filename?.replace(/(^\w+:|^)/, '');
  const fluidImage = getFluidGatsbyImage(imageRaw, {
    maxWidth: 3800,
  });

  return (
    <Wrapper>
      <Img alt={image?.alt} title={image?.title} fluid={fluidImage} />
    </Wrapper>
  );
};

export default Banner;
