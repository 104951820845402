import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import SbEditable from '~components/util/SbEditable';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';

const InnerImage = styled(Img)`
  overflow: visible;
  width: '698px';
  height: '557px';
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
`;

const BorderBox = styled.div`
  width: calc(698px + 180px);
  position: relative;
  padding: 30px 90px;
  /* background-color: #c84e6e; */

  @media only screen and (max-width: 1600px) {
    margin-top: 23px;
    width: auto;
    padding: 0px 30px;
  }
`;

const ImageCaption = styled.div`
  position: absolute;
  left: 90px;
  bottom: 50px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.514px;
  p {
    margin: 4px auto 4px;
  }

  @media ${({ theme }) => theme.BreakpointDown.xl} {
    left: 30px;
    bottom: 20px;
    /* color: white; */
    font-size: 14px;
  }
`;

function ImageWideBorder(props) {
  const { blok } = props;
  const imgUrl = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgUrl, {
    maxWidth: 698,
    maxHeight: 557,
  });

  return (
    <SbEditable content={blok}>
      <BorderBox style={{ overflow: 'visible' }}>
        <InnerImage alt={blok.image?.alt} title={blok.image?.title} fluid={img} />
        <ImageCaption>
          <SanitizeHTML
            style={{ fontWeight: 600, fontSize: '18px', paddingBottom: '10px' }}
            html={
              Api.richTextResolver.render(blok.image_description) === ''
                ? blok.image_description
                : Api.richTextResolver.render(blok.image_description)
            }
          />
        </ImageCaption>
      </BorderBox>
      {/* <sizeFixer /> */}
    </SbEditable>
  );
}

export default ImageWideBorder;
