import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import ListSlider from './ListSlider';
import ListSliderTitle from './ListSliderTitle';
import ListSliderCopy from './ListSliderCopy';

const ListSliderBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <ListSlider whiteBg={props.blok.white_bg} data={props.blok.data}>
        <ListSliderTitle>
          <SanitizeHTML html={Api.richTextResolver.render(props.blok.title)} />
        </ListSliderTitle>
        <ListSliderCopy>
          <SanitizeHTML html={Api.richTextResolver.render(props.blok.copy)} />
        </ListSliderCopy>
      </ListSlider>
    </SbEditable>
  );
};

export default ListSliderBlok;
