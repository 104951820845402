import React from 'react';
import styled, { css } from 'styled-components';

import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
// import SbEditable from '~components/util/SbEditable';
import Api from '~components/../util/storyblok-api';

import Row from '~components/Layout/Row';
import Col from '~components/Layout/Col';
import Container from '~components/Layout/Container';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Button from '~components/Button/Button';

// import * as mixins from '~styles/mixins';

const ImageContainer = styled.div`
  position: absolute;
  left: 0px;
  width: 50vw;
  /* height: 518px; */

  /* @media (min-width: 1500px) {
    bottom: calc(7vw - 90px);
  }
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    position: relative;
    width: auto;
    height: auto;
    order: 2;
  } */
`;

const AlteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const AltedContainer = styled(Container)`
  padding-top: 0px !important;
  padding-bottom: 0 !important;
  margin-top: 0rem;
  margin-bottom: 0rem;
`;
const SectionWrapper = styled.div`
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media ${({ theme }) => theme.breakpoint.xl} {
    &:nth-child(odd) {
      background: linear-gradient(to left, #ffffff 55%, #f2f2f2 50%);
    }
    &:nth-child(even) {
      background: linear-gradient(to right, #ffffff 55%, #f2f2f2 50%);
    }
  }

  /* height: 518px; */
  @media (min-width: 1500px) {
    /* height: calc(50vw - 197px); */
    /* height: 37vw; */
    /* margin-top: 10vw; */
  }
  /* margin-top: 5rem; */
  /* margin-bottom: 5rem; */

  @media ${({ theme }) => theme.BreakpointDown.sm} {
    /* margin-top: 3rem; */
    /* margin-bottom: 1rem; */
    height: auto;
  }

  @media ${({ theme }) => theme.breakpoint.xl} {
    display: flex;
    &.even ${ImageContainer} {
      order: 2;
      right: 0;
      left: initial;
    }
    &.even ${AltedContainer} > ${Row} > ${Col}:first-child {
      order: 2;
    }
    &.even ${AltedContainer} {
      order: 1;
    }
  }
`;

const HeadingText = styled.h2`
  text-transform: uppercase;
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 1.1px;

  &:nth-child(2) {
    position: relative;
    top: -10px;
  }
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const DescParagraph = styled.div`
  /* padding-top: 20px; */
  padding-bottom: 20px;
  font-size: 16px;
  color: #232324;
  line-height: 27px;
  letter-spacing: 0.8px;
  & ${Button} p {
    margin: 0 !important;
  }
`;

const DoubleImageAndText = ({ blok, ...props }) => {
  const imgUrl = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgUrl, {
    maxWidth: 699,
    maxHeight: 518,
  });

  return (
    <SectionWrapper {...props}>
      {/* <ImageContainer>
        <Img fluid={img} />
      </ImageContainer> */}
      <AltedContainer>
        <Row>
          <Col md={12} xl={7}>
            <Img alt={blok.image?.alt} title={blok.image?.title} fluid={img} />
            {/* Empty pusher */}
          </Col>
          <AlteredCol md={12} xl={5}>
            <>
              <HeadingText bold>{blok.heading}</HeadingText>
              {blok.subheading && <HeadingText>{blok.subheading}</HeadingText>}
              <DescParagraph>
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(blok.description) === ''
                      ? blok.description
                      : Api.richTextResolver.render(blok.description)
                  }
                />
              </DescParagraph>
              {blok.link.cached_url && <Button link={blok.link.cached_url}>Let&apos;s chat</Button>}
            </>
          </AlteredCol>
        </Row>
      </AltedContainer>
    </SectionWrapper>
  );
};

export default DoubleImageAndText;
