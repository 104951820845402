import React from 'react';
import styled, { keyframes } from 'styled-components';

import * as mixins from '~styles/mixins';

const rippleAnimation = keyframes`
0% {
  transform: scale(0.0);
  opacity: 1;
}
100% {
  transform: scale(1);
  opacity: 0;
}
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 80px;
  padding-bottom: 80px;

  font-size: 5rem;
  color: ${({ theme }) => theme.colors.grey600};
`;

const Ripple = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;

    width: 72px;
    height: 72px;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    opacity: 1;
    border-radius: 50%;
    animation: ${rippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;

const LoadingScreen = () => {
  return (
    <Container>
      <Ripple>
        <div />
        <div />
      </Ripple>
    </Container>
  );
};

export default LoadingScreen;
