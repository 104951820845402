import React from 'react';
import SbEditable from '~components/util/SbEditable';
// import Api from '~components/../util/storyblok-api';

// import SanitizeHTML from '~components/util/SanitizeHTML';
import WeCanHelpYouWithSlider from './WeCanHelpYouWithSlider';
import WeCanHelpYouWithSliderItem from './WeCanHelpYouWithSliderItem';

const PartnersSliderBlok = (props) => {
  const { blok } = props;
  return (
    <SbEditable content={blok}>
      <WeCanHelpYouWithSlider blok={blok}>
        {blok.we_can_help_you_slider_slider &&
          blok.we_can_help_you_slider_slider.map((item, index) => {
            const img = item.image?.filename;
            return (
              <WeCanHelpYouWithSliderItem
                index={index}
                key={item._uid}
                fluidDataCms={img}
                items={blok.we_can_help_you_slider_slider}
                item={item}
              />
            );
          })}
      </WeCanHelpYouWithSlider>
    </SbEditable>
  );
};

export default PartnersSliderBlok;
