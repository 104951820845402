import React from 'react';
import styled from 'styled-components';

import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import Row from '~components/Layout/Row';
import Col from '~components/Layout/Col';
import Container from '~components/Layout/Container';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';
import SbEditable from '../../../util/SbEditable';

import Button from '~components/Button/Button';

const AlteredContainer = styled(Container)`
  padding: 0px !important;
  /* transition: 0.5s ease all; */

  & ${Col} img {
    transition: 2.5s ease all !important;
  }
  &:hover ${Col} img {
    transform: scale(1.02) translate(-0.07%, 0.05%);
  }
`;

const CardWrapper = styled(Col)`
  display: flex;
  align-items: flex-end;
  padding-left: 0px;
  background: #f2f2f2 !important;
`;
const Card = styled.div`
  @media ${({ theme }) => theme.BreakpointDown.md} {
    display: none;
  }
  h3 {
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.629px;
    margin-bottom: 21px;
  }
  padding: 60px 15px;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    padding: 15px;
    /* padding-bottom: 0px; */
  }
`;

const AlteredCol = styled(Col)`
  @media ${({ theme }) => theme.BreakpointDown.md} {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

const SectionWrapper = styled.div`
  &:hover h3 {
    color: ${({ theme }) => theme.colors.secondary};
  }
  &:hover ${Button} {
    background: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: #fff;
    svg g {
      stroke: white;
    }
  }
  margin-top: 3rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 8vw;
  }
  position: relative;
  background: linear-gradient(
    to left,
    #ffffff 50%,
    ${(props) => (props.background_color ? props.background_color : '#F1F1F1')} 50%
  );
`;
const NoLink = styled.span`
  text-decoration: none;
  color: initial;

  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  align-items: flex-end;
`;

const ImageAndTile = ({ blok }) => {
  const imgURL = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgURL, {});

  return (
    <SbEditable content={blok}>
      <SectionWrapper background_color={blok.background_color?.color}>
        <AlteredContainer>
          <Row>
            <AlteredCol md={6} xl={9}>
              <Img alt={blok.image?.alt} title={blok.image?.title} fluid={img} />
            </AlteredCol>

            <CardWrapper md={6} xl={3}>
              <NoLink>
                <Card>
                  <h3>
                    <SanitizeHTML
                      html={
                        Api.richTextResolver.render(blok.project_name) === ''
                          ? blok.project_name
                          : Api.richTextResolver.render(blok.project_name)
                      }
                    />
                  </h3>
                  {blok.link.cached_url && (
                    <Button link={blok.link.cached_url}>View case study</Button>
                  )}
                </Card>
              </NoLink>
            </CardWrapper>
          </Row>
        </AlteredContainer>
      </SectionWrapper>
    </SbEditable>
  );
};

export default ImageAndTile;
