import React from 'react';
import SbEditable from '../../../util/SbEditable';
import DoubleImageAndText from './DoubleImageAndText';

export const mockData = {};

const DoubleImageAndTextBlok = (props) => {
  let { blok } = props;
  if (typeof blok === 'undefined') {
    blok = mockData;
  }
  return (
    <SbEditable content={blok}>
      <DoubleImageAndText blok={blok} {...props} />
    </SbEditable>
  );
};

export default DoubleImageAndTextBlok;
