import React from 'react';
import styled, { css } from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import SbEditable from '~components/util/SbEditable';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Button from '~components/Button/Button';

import * as mixins from '~styles/mixins';

const SectionWrapper = styled.div`
  /* ${mixins.makeContainer} */
  width: 100%;
  border-top: 15px solid #f5f5f5;
`;
const Container = styled.div`
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;

    ${mixins.makeContainer}
    ${mixins.makeContainerMaxWidths}
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;

const Col = styled.div`
    @media ${({ theme }) => theme.BreakpointDown.md} {
      /* padding-left: 15px !important; */
      /* padding-right: 15px !important; */
      &:nth-child(n+2) {  
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
    transition: all 3s ease;
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ theme, md, xl }) => {
      return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
    }}
`;

const Row = styled.div`
  ${mixins.makeRow}
  overflow: visible;
`;

const HeadingText = styled.h2`
  text-transform: uppercase;
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 1.1px;
  margin: 0;
  &:nth-child(2) {
    position: relative;
    top: -10px;
  }
  p {
    display: inline-block;
    margin: 5px auto;
    line-height: 30px;
  }
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const DescParagraph = styled.div`
  /* padding-top: 20px; */
  padding-bottom: 20px;
  font-size: 16px;
  color: #232324;
  line-height: 27px;
  letter-spacing: 0.8px;
  & ${Button} p {
    margin: 0 !important;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 100%;

  transition: 0.3s ease-in-out transform;

  background: ${({ theme }) => theme.colors.grey100rgba};

  font-size: 1.5rem;
  line-height: ${({ theme }) => theme.font.typography.h3.lineHeight.md};

  height: calc(100% - 30px);
  width: calc(100% - 30px);
  padding: 20px;

  p {
    margin-top: initial;
    margin-bottom: initial;

    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding: 60px;
    font-size: 2rem;
  }

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const OneSideDiv = styled.div`
  height: 100%;
  width: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;
const OverlayText = styled.div`
  padding-bottom: 23px;
`;

const AlteredCol = styled(Col)`
  padding: 0;
  @media ${({ theme }) => theme.breakpoint.xl} {
    margin-left: 16.666667%;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    &.imageSide {
      margin-left: 0px;
      margin-top: 40px;
    }
  }

  @media ${({ theme }) => theme.BreakpointDown.md} {
    &.imageSide {
      display: none;
    }
  }
`;

const ImageTileWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    &:hover ${Overlay} {
      transform: translateY(-100%);
    }
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  /* height: 100%; */
`;

const SidedTextBlock = (props) => {
  const { blok } = props;
  const imgUrl = blok.image?.filename?.replace(/(^\w+:|^)/, '');

  const imgTaller = getFluidGatsbyImage(imgUrl, {
    maxWidth: 360,
    maxHeight: 503,
  });

  const imgWider = getFluidGatsbyImage(imgUrl, {
    maxWidth: 460,
    maxHeight: 502,
  });

  const ImageSide = () => {
    return (
      <ImageTileWrapper>
        {blok.image_size === 'taller' && (
          <Img
            alt={blok.image?.alt}
            title={blok.image?.title}
            fluid={imgTaller}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        {blok.image_size === 'wider' && (
          <Img
            alt={blok.image?.alt}
            title={blok.image?.title}
            fluid={imgWider}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        {(blok.image_size === undefined || blok.image_size === null || blok.image_size === '') && (
          <Img
            alt={blok.image?.alt}
            title={blok.image?.title}
            fluid={imgTaller}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        {blok.project_name && blok.button_link_image && blok.overlay && (
          <Overlay>
            <OverlayContainer>
              <OverlayText>
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(blok.project_name) === ''
                      ? blok.project_name
                      : Api.richTextResolver.render(blok.project_name)
                  }
                />
              </OverlayText>
              {blok.button_link_image.cached_url && (
                <Button variant="secondary" link={blok.button_link_image.cached_url}>
                  View case study
                </Button>
              )}
            </OverlayContainer>
          </Overlay>
        )}
      </ImageTileWrapper>
    );
  };

  const OneSide = () => {
    return (
      <OneSideDiv>
        <HeadingText bold>
          <SanitizeHTML
            html={
              Api.richTextResolver.render(blok.heading) === ''
                ? blok.heading
                : Api.richTextResolver.render(blok.heading)
            }
          />
        </HeadingText>
        <HeadingText>
          <SanitizeHTML
            html={
              Api.richTextResolver.render(blok.subheading) === ''
                ? blok.subheading
                : Api.richTextResolver.render(blok.subheading)
            }
          />
        </HeadingText>
        <DescParagraph>
          <SanitizeHTML
            html={
              Api.richTextResolver.render(blok.description) === ''
                ? blok.description
                : Api.richTextResolver.render(blok.description)
            }
          />
        </DescParagraph>
        {blok.button_link.cached_url && (
          <Button link={blok.button_link.cached_url}>
            <SanitizeHTML
              html={
                Api.richTextResolver.render(blok.button_content) === ''
                  ? blok.button_content
                  : Api.richTextResolver.render(blok.button_content)
              }
            />
          </Button>
        )}
      </OneSideDiv>
    );
  };

  const LeftElement = () => {
    if (blok.image?.filename) {
      switch (blok.side) {
        case 'left':
          return (
            <>
              <Col md={6} xl={blok.side === 'right' ? 7 : 5}>
                {OneSide()}
              </Col>
              <AlteredCol className="imageSide" md={6} xl={5}>
                {ImageSide()}
              </AlteredCol>
            </>
          );
        case 'right':
          return (
            <>
              <Col md={6} xl={5} className="imageSide">
                {ImageSide()}
              </Col>
              <Col md={6} xl={blok.side === 'right' ? 7 : 5}>
                {OneSide()}
              </Col>
            </>
          );
        default:
          return null;
      }
    }
    if (blok.side === 'right') {
      return (
        <>
          <Col md={6} xl={5} />
          <Col md={6} xl={7}>
            {OneSide()}
          </Col>
        </>
      );
    }
    if (blok.side === 'left') {
      return (
        <Col md={6} xl={5}>
          {OneSide()}
        </Col>
      );
    }
    return null;
  };

  return (
    <SectionWrapper>
      <Container className="SidedBlock">
        <SbEditable content={blok}>
          <Row>{LeftElement()}</Row>
        </SbEditable>
      </Container>
    </SectionWrapper>
  );
};

export default SidedTextBlock;
