/* eslint-disable camelcase */
import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import styled from 'styled-components';
import Img from 'gatsby-image';

import * as mixins from '~styles/mixins';

const Wrapper = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

  display:flex;
  flex-direction: column;

  img {
    transition: 2.5s ease-in-out transform !important;
  }

  &:hover {
    * {
      color: ${({ theme }) => theme.colors.secondary};
    }

    img {
      overflow: hidden;
      transform: scale(1.02) translate(-0.07%, 0.05%);
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
  @media ${({ theme }) => theme.breakpoint.sm} {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.17);
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
  }
`;

const InfoWrapper = styled.div``;

const Heading = styled.h4`
  color: ${({ theme }) => theme.colors.primary};

  font-weight: ${({ theme }) => theme.font.weight.bold};

  text-transform: uppercase;

  margin-bottom: 0;

  transition: 0.3s ease-in-out color;
`;

const Categories = styled.div`
  color: #000;
  font-size: 0.9375rem;
  opacity: 0.64;

  transition: 0.3s ease-in-out color;
`;

const Blog = ({ blog }) => {
  const imageRaw = blog?.content?.featured_image?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 500,
    maxHeight: 312,
  });
  return (
    <Wrapper>
      <Container>
        <ImageWrapper>
          {image && (
            <Img
              alt={blog?.content?.featured_image?.alt}
              title={blog?.content?.featured_image?.title}
              fluid={image}
            />
          )}
        </ImageWrapper>
        <InfoWrapper>
          <Heading>{blog?.content?.title}</Heading>
          <Categories>{blog?.content?.excerpt}</Categories>
        </InfoWrapper>
      </Container>
    </Wrapper>
  );
};

export default Blog;
