import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import HeroFront from './HeroFront';

const HeroFrontBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <HeroFront button={props.blok.button[0]}>
        <SanitizeHTML html={Api.richTextResolver.render(props.blok.content)} />
      </HeroFront>
    </SbEditable>
  );
};

export default HeroFrontBlok;
