import React from 'react';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 3.75rem;
  line-height: 3.525rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 5.125rem;
    line-height: 4.5rem;
  }

  position: relative;
  z-index: 1;

  display: flex;
  align-items: flex-end;

  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const WaveAnimation = keyframes`
0%{background-position:0 bottom};
100%{background-position: 200px bottom};
`;

const LoadingAnimation = keyframes`
0%{background-size:200px 95%};
25%{background-size:200px 115%};
50%{background-size:200px 140%};
100%{background-size:200px 125%};
`;

const Number = styled.div`
  color: #e2e0e1;
  font-size: 3.75rem;
  line-height: 3.525rem;

  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 5.125rem;
    line-height: 4.5rem;
  }

  position: relative;
  z-index: 1;

  display: inline-block;

  font-weight: ${({ theme }) => theme.font.weight.bold};

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  background-image: url('/wave.png');

  background-size: 100% 95%;
  background-repeat: repeat-x;
  background-position: 100% 100%;

  animation: ${WaveAnimation} 1.5s infinite linear,
    ${LoadingAnimation} 10s infinite linear alternate;
`;

const UpperLayer = styled.div`
  display: inline-block;

  position: relative;
  z-index: 2;

  font-size: 1.625rem;
  line-height: 2.25rem;

  ${({ bg }) => {
    if (bg === 0) {
      return `
        z-index: -1;
      `;
    }
    return null;
  }}
`;

const BackgroundLayer = styled.div`
  background: white;
  height: 3.75rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 5.125rem;
  }

  position: absolute;
  left: -15px;
  ${({ moveRight }) => moveRight === true && `left:-7px;`}
  top: -45%;
  ${({ leftPos }) => {
    return (
      leftPos &&
      `
      top:initial;
      bottom: -0.2rem;
      left: -0.5rem;
    `
    );
  }}
  z-index: -1;

  @media ${({ theme }) => theme.breakpoint.md} {
    left: -25px;
    ${({ moveRight }) => moveRight === true && `left:-12px;`}
    top: -95%;
    ${({ leftPos }) => {
      return (
        leftPos &&
        `
        top:initial;
        bottom: 0rem;
        left: 1.55rem;
      `
      );
    }}
  }

  ${({ makeRotate }) => makeRotate && `transform: rotate(36deg);`}

  display: flex;
  ${({ bg }) => {
    if (bg === 0) {
      return `
        background: transparent;
      `;
    }
    return null;
  }}
`;

const StonksArrow = styled.div`
  width: 15px;

  background: #e2e0e1;

  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 5px;

  position: relative;

  &:after {
    width: 0;
    height: 0;
    border-bottom: 25px solid #e2e0e1;
    border-top: 25px solid transparent;
    border-left: 19px solid transparent;
    border-right: 19px solid transparent;

    content: '';
    position: absolute;
    top: -90%;
    left: 50%;
    transform: translateX(-50%);

    @media ${({ theme }) => theme.breakpoint.md} {
      top: -50%;
      left: 50%;
    }
  }
  ${({ show, bg }) => {
    if (show === 0 && bg === 0) {
      return `
        display: none;
      `;
    }
    if (show === 0 && bg === 1) {
      return `
        visibility: hidden;
      `;
    }
    return null;
  }}
`;

const RightChar = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  ${({ paddingLeft }) => {
    if (paddingLeft === 1) {
      return `
        padding-left: 20px;
        @media ${({ theme }) => theme.breakpoint.md} {
          padding-left: 14px;
        }
      `;
    }
    return null;
  }};
`;

const BottomLayer = styled.div`
  position: absolute;

  height: 100%;

  top: 0;
  left: 0;

  ${({ show }) => show !== 1 && `display: none;`}
`;

const UpArrow = styled(StonksArrow)`
  top: 0;

  transform: rotate(0);
`;

const AnimtatedNumber = ({ type, number, char, moveBgRight }) => {
  const option = type ?? 0;
  if ([0, 1, 2, 3, 4, 5].indexOf(option) === -1) {
    return null;
  }

  const config = [
    {
      rightArrow: 1,
      leftArrow: 0,
      background: 1,
    },
    {
      rightArrow: 0,
      leftArrow: 0,
      background: 1,
    },
    {
      rightArrow: 0,
      leftArrow: 0,
      background: 0,
    },
    {
      rightArrow: 0,
      leftArrow: 1,
      background: 1,
    },
    {
      rightArrow: 0,
      leftArrow: 1,
      background: 0,
    },
    {
      rightArrow: 0,
      leftArrow: 0,
      background: 0,
    },
  ];

  return (
    <Wrapper>
      <Number>{number ?? `300`}</Number>
      <BottomLayer show={config[option].leftArrow}>
        <BackgroundLayer leftPos makeRotate={false} bg={false}>
          <UpArrow show={config[option].leftArrow} bg={false} />
        </BackgroundLayer>
      </BottomLayer>
      <UpperLayer bg={config[option].background}>
        <BackgroundLayer makeRotate moveRight={moveBgRight} bg={config[option].background}>
          <StonksArrow show={config[option].rightArrow} bg={config[option].background} />
        </BackgroundLayer>
        <RightChar paddingLeft={config[option].rightArrow}>{char ?? `%`}</RightChar>
      </UpperLayer>
    </Wrapper>
  );
};

export default AnimtatedNumber;
