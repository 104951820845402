import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image';
import Api from '../../../../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Button from '~components/Button/Button';

const Wrapper = styled.div`
  padding-left: 8.5px;
  padding-right: 8.5px;

  display: flex;
  flex-direction: column;

  width: 100%;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-left: 0px;
    padding-right: 0px;
    grid-column: span 6;
    grid-row: span 13;
    &.span7 {
      grid-row: span 14;
    }
    &.span5 {
      grid-row: span 12;
    }
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    grid-column: span 4;
    grid-row: span 13;
    &.span7 {
      grid-row: span 14;
    }
    &.span5 {
      grid-row: span 12;
    }
  }
  &.col5 {
    grid-column: span 5;
  }
  &.col7 {
    grid-column: span 7;
  }
`;

const Description = styled.div`
  h4 {
    font-size: 1.125rem;
    @media ${({ theme }) => theme.breakpoint.lg} {
      font-size: 1.375rem;
    }

    letter-spacing: 1.1;
    text-transform: uppercase;

    margin-bottom: 0;
  }
  .title {
    white-space: nowrap;
    padding-top: 5px;

    font-size: 1.125rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      font-size: 1.375rem;
    }
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-transform: uppercase;
  }
`;
const Image = styled(Img)`
  height: 250px;
  img {
    object-position: center;
    ${({ objectPosMobile }) => {
      if (
        typeof objectPosMobile !== 'undefined' &&
        objectPosMobile !== null &&
        objectPosMobile !== ''
      ) {
        return `object-position: ${objectPosMobile} !important; `;
      }
      return null;
    }}
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 375px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    img {
      object-position: center !important;
    }
    height: 100%;
    width: 100%;
  }
`;

const Overlay = styled.div`
  display: none;
  position: absolute;
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: flex;
  }
  top: 100%;

  max-height: 100%;

  transition: 0.3s ease-in-out transform;

  background: ${({ theme }) => theme.colors.grey100rgba};

  font-size: 1rem;

  width: 100%;
  padding: 20px;

  p {
    margin-top: initial;
    margin-bottom: initial;

    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 60px 20px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding: 40px 20px;
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.breakpoint.xl} {
    padding: 60px 20px;
    width: 90%;
  }

  flex-direction: column;
  align-items: flex-start;
`;

const OverlayText = styled.div`
  padding-bottom: 23px;
  &.pt-1 {
    padding-top: 23px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.lg} {
    ${({ rowSize }) => {
      switch (rowSize) {
        case 'small':
          return `height: 352px;`;
        case 'big':
          return `height: 432px;`;
        default:
          return `height: 392px;`;
      }
    }}
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    ${({ rowSize }) => {
      switch (rowSize) {
        case 'small':
          return `height: 352px;`;
        case 'big':
          return `height: 432px;`;
        default:
          return `height: 392px;`;
      }
    }}
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    &:hover ${Overlay} {
      transform: translateY(-100%);
    }
  }
`;

const MobileDescription = styled.div`
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: none;
  }
`;

const Member = ({ memberData, rowSize, ...props }) => {
  const imageRaw = memberData.photo?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 1005,
  });

  const printButtonLabel = () => {
    if (
      typeof memberData.override_button_label !== 'undefined' &&
      memberData.override_button_label !== null &&
      memberData.override_button_label !== ''
    ) {
      return memberData.override_button_label;
    }
    const firstName = memberData?.name.replace(/ .*/, '');
    return `${firstName}'s favourite project`;
  };

  return (
    <Wrapper {...props}>
      <ImageWrapper rowSize={rowSize}>
        <Image
          alt={memberData.photo?.alt}
          title={memberData.photo?.title}
          objectPosMobile={memberData.object_pos_mobile}
          fluid={image}
        />
        <Overlay>
          <OverlayText>
            <SanitizeHTML html={Api.richTextResolver.render(memberData.description)} />
          </OverlayText>
          <Button link={memberData.favourite_project?.cached_url}>{printButtonLabel()}</Button>
        </Overlay>
      </ImageWrapper>
      <Description>
        <h4>{memberData.name}</h4>
        <div className="title">{memberData.title}</div>
        <MobileDescription>
          <OverlayText className="pt-1">
            <SanitizeHTML html={Api.richTextResolver.render(memberData.description)} />
          </OverlayText>
          <Button link={memberData.favourite_project?.cached_url}>{printButtonLabel()}</Button>
        </MobileDescription>
      </Description>
    </Wrapper>
  );
};

export default Member;
