import React from 'react';
import SbEditable from '../../../util/SbEditable';
import SidedTextBlock from './SidedTextBlock';

export const mockData = {
  _uid: '1',
  component: 'sided_text_block',
  description:
    'We take pride in building long-term partnerships with our clients. We’re just as committed to achieving your business objectives, as you are. Brand immersion ensures we add value and a purpose behind every message, touchpoint and experience we create for our clients. ',
  heading: 'collaboration',
  side: 'right',
  subheading: 'means a lot to us',
};

const SidedTextBlockBlok = (props) => {
  let { blok } = props;
  if (typeof blok === 'undefined') {
    blok = mockData;
  }
  return (
    <SbEditable content={blok}>
      <SidedTextBlock blok={blok} content={blok} />
    </SbEditable>
  );
};

export default SidedTextBlockBlok;
