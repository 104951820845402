import React from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Button from '~components/Button/Button';
import Link from '~components/util/Link';
import { randomID } from '~components/MinimalForm/util';

const InsideWrapper = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    /* padding: 25px; */
  }
`;
const NoLink = styled(Link)`
  text-decoration: none;
  color: initial;
  display: block;
  width: 100%;
  height: 100%;
  & img {
    transition: all ease 2.5s;
  }
  &:hover,
  &:hover span {
    color: ${({ theme }) => theme.colors.secondary};
  }
  /* 
  &:hover span:not(:last-child):after { {
    color: initial;
  } */
  &:hover img {
    transform: scale(1.02) translate(-0.07%, 0.05%);
  }
`;

// const NoLink = styled(Link)`
//   text-decoration: none;
//   color: initial;
// `;
const ImageWrapper = styled.div`
  /* width: 100%;
  height: 100%; */
  display: flex;
  overflow: hidden;
`;

const ItemWrapper = styled.div`
  width: ${(props) => {
    switch (props.compSize) {
      case 'add':
        return '25%';
      case 'reversed-informal':
      case 'informal':
      case 'normal':
        return '33.3%';
      case 'quater':
        return '41.6%';
      case 'fullwidth':
        return '100%';
      default:
        return '33.3%';
    }
  }};

  ${InsideWrapper} {
    background: ${(props) => (props.compSize === 'add' ? '#F5F5F5' : 'transparent')};
    ${(props) =>
      props.compSize === 'add' &&
      css`
        padding: 80px 36px;
      `}
  }

  &.add {
    &:hover {
      cursor: pointer;

      color: ${({ theme }) => theme.colors.secondary} !important;
      ${Button} {
        background: ${({ theme }) => theme.colors.secondary};
        border: 1px solid ${({ theme }) => theme.colors.secondary};
        color: #fff;
      }
      svg g {
        stroke: white;
      }
    }
    @media ${({ theme }) => theme.BreakpointDown.xl} {
      display: none;
    }
    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 38px;
    }
  }
  margin-top: 70px;
  /* margin-left: -12px; */
  /* margin-right: -12px; */

  @media ${({ theme }) => theme.BreakpointDown.xl} {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
`;
const ProjectCategory = styled.span`
  &:not(:first-child) {
    padding-left: 10px;
  }
  &:not(:last-child):after {
    content: '|';
    padding-left: 10px;
  }
`;
const DescriptionWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
  span:not(${ProjectCategory}) {
    text-transform: uppercase;
    padding-left: 20px;
  }
`;

const ImageThumbnail = styled.img`
  flex: 1;
  /* width: 100%; */
  /* height: auto; */
  object-fit: cover;
  height: 300px;
`;

const ProjectCategoryDescription = styled.div`
  color: #5c5c5c;
  font-size: 10px;
`;
const ProjectItem = ({ contentJSON, compSize, full_slug }) => {
  const content = JSON.parse(contentJSON);

  return (
    <>
      {compSize === 'reversed-informal' && (
        <ItemWrapper className="add" compSize="add" onClick={() => navigate('/services')}>
          <InsideWrapper>
            <h2>Services we offer our clients</h2>
            <Button variant="primary" link="services">
              View services
            </Button>
          </InsideWrapper>
        </ItemWrapper>
      )}
      <ItemWrapper compSize={compSize}>
        <NoLink to={full_slug}>
          <InsideWrapper>
            <ImageWrapper>
              <ImageThumbnail src={content.meta_image.filename} alt={content.meta_image.alt} />
            </ImageWrapper>
            <DescriptionWrapper>
              <strong>Client</strong> <span>{content.meta_title}</span>
              <br />
              <ProjectCategoryDescription>
                {content.meta_category.map((item) => (
                  <ProjectCategory key={randomID()}>{item}</ProjectCategory>
                ))}
              </ProjectCategoryDescription>
            </DescriptionWrapper>
          </InsideWrapper>
        </NoLink>
      </ItemWrapper>

      {compSize === 'informal' && (
        <ItemWrapper className="add" compSize="add" onClick={() => navigate('/contact')}>
          <InsideWrapper>
            <h2>Let&apos;s make something together</h2>
            <Button link="contact">Let&apos;s chat</Button>
          </InsideWrapper>
        </ItemWrapper>
      )}
    </>
  );
};

export default ProjectItem;
