export const prepareBlogs = (rawResults) => {
  let result = [];
  try {
    result = rawResults.allStoryblokEntry.edges.map((blog) => {
      const story = Object.assign({}, blog.node);
      story.content = JSON.parse(story.content);
      return story;
    });
  } catch (e) {
    if (e instanceof TypeError) {
      console.log('TypeError......');
    } else {
      console.log('Unknown Error. Please investigate.');
    }
    result = [-1];
  }
  return result;
};

export const state = {
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error',
  EMPTY: 'empty',
};

export const range = (from, to, step = 1) => {
  let i = from;
  const r = [];

  while (i <= to) {
    r.push(i);
    i += step;
  }

  return r;
};
