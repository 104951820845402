/* eslint-disable no-nested-ternary, eqeqeq */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import ThemeContext from '~contexts/ThemeContext';
import * as mixins from '../../styles/mixins';
import MinimalForm from '../MinimalForm/MinimalForm';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  padding-left:0;
  padding-right: 0;

  background: ${({ theme }) => theme.colors.white};
  ${(props) => {
    return (
      props.greyBackground &&
      `background: ${props.background_color === '' ? '#F5F5F5' : props.background_color}`
    );
  }}
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

    padding-top:45px;
  padding-bottom: 45px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 85px;
    padding-bottom: 85px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const ContentRow = styled.div`
  ${mixins.makeRow()}
  padding-top:37px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 55px;
  }
`;

const TitleCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 1)}
`;

const Title = styled.h2`
  line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.xs};
  font-size: ${({ theme }) => theme.font.typography.h1.size.xs};
  @media ${({ theme }) => theme.breakpoint.md} {
    line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.md};
    font-size: ${({ theme }) => theme.font.typography.h1.size.md};
  }

  margin-top: 0;
  margin-bottom: 0;

  white-space: nowrap;

  color: ${({ theme }) => theme.colors.primary};
  .title-grey {
    display: block;
    @media ${({ theme }) => theme.breakpoint.lg} {
      display: inline;
    }
    color: ${({ theme }) => theme.colors.grey600};
  }
  .title-pink {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const FormCol = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol(1, 1)}
    
    @media ${({ theme }) => theme.breakpoint.lg} {
        ${mixins.makeCol(8, 12)}
    }

    align-items:center;
`;

const SocialCol = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol(1, 1)}
    
    @media ${({ theme }) => theme.breakpoint.lg} {
        ${mixins.makeCol(11, 12)}
    }
`;

const SocialItem = styled.div`
  width: auto;

  padding-left: 7.5px;
  padding-right: 7.5px;

  position: relative;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 20px;
    padding-right: 20px;
    &:first-child {
      padding-left: 0;
    }
    &:not(:first-child):before {
      content: '';

      position: absolute;
      left: 0;

      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: 11px;
      background: #979797;
    }
  }
`;

const SocialArea = styled.div`
  border-top: 1px solid #e6e2e2;

  display: flex;

  flex-direction: column;
  margin-top: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 40px;
    flex-direction: row;
  }

  padding-top: 22.5px;
  font-size: 16px;
  line-height: 27px;
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
`;

const LetsMakeContact = ({ greyBackground, title, background_color }) => {
  const { themeState } = useContext(ThemeContext);
  const [formComplete, setFormComplete] = useState(false);
  const [formState, setFormState] = useState(false);

  const fieldsMockData = {
    name: {
      key: 'name',
      label: 'Enter your name',
      default: '',
      placeholder: 'Enter your name',
      isRequired: true,
    },
    email: {
      key: 'surname',
      type: 'email',
      label: 'Enter your email address',
      default: '',
      isRequired: true,
    },
    phone: {
      key: 'phone',
      label: 'Enter your telephone',
      default: '',
      isRequired: true,
    },
    subject: {
      key: 'subject',
      label: 'What services are you interested in?',
      default: '',
      isRequired: true,
    },
    website: {
      key: 'website',
      label: 'Please provide a link to your website',
      default: '',
    },
  };

  const onSubmitHandler = (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    fetch('//forms.mkpactive.com/mail.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        setFormComplete(true);
        // do something awesome that makes the world a better place
        if (data.status === true) {
          setFormState(true);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Form Submitted',
            category: 'Lets Make Contact',
            action: 'Submit',
            label: 'Gatsby',
          });
        } else {
          setFormState(false);
        }
      })
      .catch(() => {
        setFormComplete(true);
        setFormState(false);
      });
  };

  const SuccessResponse = () => {
    return (
      <h2 style={{ marginTop: '0' }}>
        Thank you for your message. <br />
        <small>A member of the team will be in touch soon</small>
      </h2>
    );
  };

  const ErrorResponse = () => {
    return (
      <>
        <h2 style={{ marginTop: '0' }}>
          There was a problem with sending your message. <br />
          <small>Please try again later.</small>
        </h2>
      </>
    );
  };

  return (
    <FluidContainer greyBackground={greyBackground} background_color={background_color.color}>
      <Container>
        <Row>
          <TitleCol>
            <Title>
              {title == '' ? (
                <>
                  <span className="title-grey">Lets make </span>something together
                </>
              ) : (
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(title) === ''
                      ? title
                      : Api.richTextResolver.render(title)
                  }
                  style={{ display: 'inline-block' }}
                  nomargin
                />
              )}
              <span className="title-pink">.</span>
            </Title>
          </TitleCol>
        </Row>
        <ContentRow>
          {/* <LabelCol>Let’s start the conversation now</LabelCol> */}
          <FormCol>
            {formComplete ? (
              formState ? (
                <SuccessResponse />
              ) : (
                <ErrorResponse />
              )
            ) : (
              <MinimalForm onSubmitHandler={onSubmitHandler} fieldsData={fieldsMockData} type={2} />
            )}
          </FormCol>
        </ContentRow>
        <Row>
          <SocialCol>
            <SocialArea>
              <SocialItem>
                <Icon icon={faEnvelope} />
                <SocialLink target="_blank" rel="noreferrer" href={`mailto:${themeState?.email}`}>
                  {themeState?.email}
                </SocialLink>
              </SocialItem>
              <SocialItem>
                <Icon icon={faPhoneAlt} />
                <SocialLink target="_blank" rel="noreferrer" href={`tel:${themeState?.phone}`}>
                  {`${themeState?.phone?.substr(0, 4)} ${themeState?.phone?.substr(
                    4,
                    3,
                  )} ${themeState?.phone?.substr(7)}`}
                </SocialLink>
              </SocialItem>
              {/* <SocialItem>
                <Icon icon={faWhatsapp} />
                <SocialLink
                  target="_blank"
                  rel="noreferrer"
                  href={`${themeState?.whatsapp ?? `#`}`}
                >
                  WhatsApp us
              </SocialLink>
              </SocialItem> */}
            </SocialArea>
          </SocialCol>
        </Row>
      </Container>
    </FluidContainer>
  );
};

export default LetsMakeContact;
