import React from 'react';
import styled from 'styled-components';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';

import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top: 20px;
  padding-bottom: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const AlteredListSliderTitle = styled(ListSliderTitle)`
  h2 {
    font-size: ${({ theme }) => theme.font.typography.hero.size.xs};
    line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.xs};

    @media ${({ theme }) => theme.breakpoint.md} {
      font-size: ${({ theme }) => theme.font.typography.hero.size.md};
      line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.md};
    }
  }

  .thin-text {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & .greytext {
    color: #abaaae;
  }

  .thin-grey {
    color: #abaaae;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`;

const Hero = ({ heading }) => {
  return (
    <Container>
      <AlteredListSliderTitle>
        <SanitizeHTML
          html={
            Api.richTextResolver.render(heading) === ''
              ? heading
              : Api.richTextResolver.render(heading)
          }
        />
      </AlteredListSliderTitle>
    </Container>
  );
};

export default Hero;
