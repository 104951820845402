import React from 'react';

export const MenuContext = React.createContext();
export const menuInitState = {
  open: false,
  modal: false,
};
export const menuReducer = (state, action) => {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        open: true,
      };
    }
    case 'close': {
      return {
        ...state,
        open: false,
      };
    }
    case 'toggle': {
      return {
        ...state,
        open: !state.open,
      };
    }
    case 'modalOpen': {
      return {
        ...state,
        modal: true,
      };
    }
    case 'modalClose': {
      return {
        ...state,
        modal: false,
      };
    }
    default:
      throw new Error();
  }
};

const ContactContext = React.createContext();
export default ContactContext;
