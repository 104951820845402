import React from 'react';
import { getFixedGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from '../../../util/SbEditable';

import VideoPhones from './VideoPhones';

const VideoPhonesBlok = ({ blok }) => {
  const images = blok.images.map((image) => {
    if (typeof image.filename !== 'undefined') {
      const imgUrl = image?.filename?.replace(/(^\w+:|^)/, '');
      const img = getFixedGatsbyImage(imgUrl, {
        width: 230,
        height: 500,
      });
      return img;
    }
    return false;
  });

  return (
    <SbEditable content={blok}>
      <VideoPhones images={images} />
    </SbEditable>
  );
};

export default VideoPhonesBlok;
