import React, { useReducer } from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import { navigate } from 'gatsby';

import ListSliderItem from './ListSliderItem';
import * as mixins from '../../../../styles/mixins';

export const mockData = [
  {
    _uid: '1',
    label: 'Website Design',
    link: '/website/',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
  {
    _uid: '2',
    label: 'Social Media',
    link: '/social-media/',
    image: {
      filename: '//a.storyblok.com/f/39898/2250x1500/c15735a73c/demo-image-human.jpeg',
    },
  },
  {
    _uid: '3',
    label: 'SEO',
    link: '/ppc/',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
  {
    _uid: '4',
    label: 'Content Creation',
    link: '/content-creation/',
    image: {
      filename: '//a.storyblok.com/f/39898/2250x1500/c15735a73c/demo-image-human.jpeg',
    },
  },
  {
    _uid: '5',
    label: 'PPC',
    link: '/ppc/',
    image: {
      filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
    },
  },
];

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  padding-left:0;
  padding-right: 0;

  padding-top: 0px;
  padding-bottom: 40px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ whiteBg, theme }) => {
    if (typeof whiteBg !== 'undefined' && whiteBg === true) {
      return ` background: white;`;
    }
    return `background: ${theme.colors.grey100};`;
  }}

  overflow: hidden;
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

    height:auto;

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 500px;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    height: 690px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol(1, 1)}
    
    @media ${({ theme }) => theme.breakpoint.md} {
        ${({ md }) => {
          return `
                ${mixins.makeCol(md, 12)}
            `;
        }}
    }
    @media ${({ theme }) => theme.breakpoint.lg} {
        ${({ lg }) => {
          return `
                ${mixins.makeCol(lg, 12)}
            `;
        }}
    }

    .list-slider-item-animate{ 
        left:100%;
        transition: 1.5s cubic-bezier(.55,-0.05,0,1.01) transform;

        z-index:10;

        &.list-slider-item-animate-work{
            transform: translateX(-100%);
            
        }
    }
`;

const DesktopColumn = styled.div`
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(5, 6)}

    margin-top:60px;
  }
`;

const AreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    position: static;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  @media ${({ theme }) => theme.breakpoint.md} {
    width: 500px;
    height: 490px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 701px;
    height: 687px;
  }

  ${({ isActive }) => (isActive ? `visibility:visible` : `visibility:hidden`)};

  &.forceShow {
    visibility: visible;
  }
`;

const SlideWrapper = styled.div`
  width: 100%;
  height: calc(100vw - ${({ theme }) => theme.gutterSize.xs}px);
  @media ${({ theme }) => theme.breakpoint.sm} {
    height: 535px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    position: absolute;
    width: 500px;
    height: 490px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 701px;
    height: 687px;
  }
  z-index: 10;

  overflow: hidden;
`;

const LabelList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 2rem;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-bottom: 0;
  }
`;

const ListSlider = ({ data, children, whiteBg }) => {
  if (typeof data === 'undefined' || data === null) {
    return null;
  }

  const firstObj = data[Object.keys(data)[0]];

  const [active, setActive] = useReducer((state, newState) => ({ ...state, ...newState }), {
    image: firstObj?._uid,
    label: firstObj?._uid,
  });

  const slideImage = (el, uid) => {
    if (uid === active.label) {
      return null;
    }
    const randId = `_${Math.random().toString(36).substr(2, 9)}`;

    if (typeof el !== 'object') {
      return null;
    }

    const obj = el;
    obj.id = randId;
    obj.classList.add('list-slider-item-animate');
    obj.classList.add('forceShow');

    document.querySelector('.list-slider-items').append(obj);
    setTimeout(() => {
      obj.classList.add('list-slider-item-animate-work');
    }, 10);
    setTimeout(() => {
      setActive({ image: uid });
      obj.remove();
    }, 1501);

    return null;
  };

  const onMouseHandler = (e) => {
    try {
      setActive({ label: e.target.dataset.key });
      const clonedItem = document
        .querySelector(`.list-slider-image-preview[data-key='${e.target.dataset.key}']`)
        .cloneNode(true);
      slideImage(clonedItem, e.target.dataset.key);
    } catch (err) {
      // Do we need to do anything if the user was playing with the slider?
    }
  };

  const navigateToPage = (link) => {
    navigate(`/${link.cached_url}`);
  };

  const slideItems = data.map((item) => (
    <ListSliderItem
      onClick={() => navigateToPage(item.link)}
      onMouseEnter={onMouseHandler}
      data-key={item._uid}
      key={item._uid}
      isActive={active.label === item._uid}
    >
      {item.label}
    </ListSliderItem>
  ));

  const slideImages = data.map((item) => {
    const imgUrl = item.image?.filename?.replace(/(^\w+:|^)/, '');
    const img = getFluidGatsbyImage(imgUrl, {
      maxWidth: 701,
      maxHeight: 687,
    });

    if (img === null) {
      return null;
    }
    return (
      <ImageWrapper
        key={item._uid}
        className="list-slider-image-preview"
        data-key={item._uid}
        isActive={active.image === item._uid}
      >
        <Img alt={item.image?.alt} title={item.image?.title} fluid={img} />
      </ImageWrapper>
    );
  });

  return (
    <FluidContainer whiteBg={whiteBg}>
      <Container>
        <Row>
          <Col md="8" lg="6" className="list-items-wrapper">
            <DesktopColumn>{children}</DesktopColumn>
            <LabelList>{slideItems}</LabelList>
          </Col>
          <Col md="4" lg="6" className="list-slider-current-image">
            <AreaWrapper>
              {slideImages}
              <SlideWrapper className="list-slider-items" />
            </AreaWrapper>
          </Col>
        </Row>
      </Container>
    </FluidContainer>
  );
};

export default ListSlider;
