import React from 'react';
import styled from 'styled-components';

import Img from 'gatsby-image/withIEPolyfill';

const Wrapper = styled.div`
  position: relative;
`;

const Mask = styled.div`
  position: absolute;
  left: 20px;
  bottom: 30px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    left: 0px;
    bottom: 50px;
  }
`;
const MaskAlter = styled.div`
  position: absolute;
  left: 20px;
  bottom: -60px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    left: 0px;
    bottom: 50px;
  }
`;
const Heading = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.375rem;
`;

const Desc = styled.div``;

const Slide = ({ fluid, heading, description, mobileFix }) => {
  return (
    <Wrapper>
      <Img alt={heading} title={heading} fluid={fluid} />
      {!mobileFix && (
        <Mask>
          <Heading>{heading}</Heading>
          <Desc>{description}</Desc>
        </Mask>
      )}
      {mobileFix && (
        <MaskAlter>
          <Heading>{heading}</Heading>
          <Desc>{description}</Desc>
        </MaskAlter>
      )}
    </Wrapper>
  );
};

export default Slide;
