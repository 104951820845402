import React, { useEffect, useReducer } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import LoadingScreen from './Screens/LoadingScreen';
import ErrorScreen from './Screens/ErrorScreen';
import EmptyScreen from './Screens/EmptyScreen';
import GridScreen from './Screens/GridScreen';

import { prepareBlogs, state } from './util';

const initState = {
  status: state.LOADING,
  blogData: [],
};

const reducer = (blogState, action) => {
  switch (action.type) {
    case 'setData': {
      if (action.payload.length < 1) {
        return {
          status: state.EMPTY,
          blogData: [],
        };
      }
      if (action.payload[0] === -1) {
        return {
          status: state.ERROR,
          blogData: [],
        };
      }
      return {
        status: state.READY,
        blogData: action.payload,
      };
    }

    case 'setError': {
      return {
        status: state.ERROR,
        ...blogState,
      };
    }
    default: {
      throw new Error();
    }
  }
};

const BlogGrid = () => {
  const [blogState, dispatch] = useReducer(reducer, initState);

  const blogRawData = useStaticQuery(graphql`
    query Blogs {
      allStoryblokEntry(filter: { field_component: { eq: "blog" } }) {
        edges {
          node {
            uuid
            name
            content
            full_slug
          }
        }
      }
    }
  `);

  useEffect(() => {
    dispatch({
      type: 'setData',
      payload: prepareBlogs(blogRawData),
    });
  }, []);

  const printScreen = () => {
    switch (blogState.status) {
      case state.EMPTY:
        return <EmptyScreen />;
      case state.ERROR:
        return <ErrorScreen />;
      case state.READY:
        return <GridScreen posts={blogState.blogData} />;
      default:
        return <LoadingScreen />;
    }
  };

  return <>{printScreen()}</>;
};

export default BlogGrid;
