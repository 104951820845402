import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

import SbEditable from '../../../util/SbEditable';
import Banner from './Banner';

const SidedTextBlockBlok = ({ blok }) => {
  const imageRaw = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 3800,
  });

  return (
    <SbEditable content={blok}>
      <Banner image={image} />
    </SbEditable>
  );
};

export default SidedTextBlockBlok;
