import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;

  line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.xs};
  font-size: ${({ theme }) => theme.font.typography.h1.size.xs};
  @media ${({ theme }) => theme.breakpoint.md} {
    line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.md};
    font-size: ${({ theme }) => theme.font.typography.h1.size.md};
  }

  color: ${({ theme }) => theme.colors.primary};
  .title-grey {
    color: ${({ theme }) => theme.colors.grey600};
  }
  .title-pink {
    color: ${({ theme }) => theme.colors.secondary};
  }
  .desktop-only-next-line {
    display: initial;
    @media ${({ theme }) => theme.breakpoint.lg} {
      display: block;
    }
  }
`;

const ListSliderTitle = ({ children, className, ...props }) => {
  return (
    <Wrapper className={className} {...props}>
      <Title>{children}</Title>
    </Wrapper>
  );
};

export default ListSliderTitle;
