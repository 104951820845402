import React from 'react';

import LetsMakeContact from './LetsMakeContact';
import SbEditable from '../util/SbEditable';

const LetsMakeContactBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <LetsMakeContact
        title={props.blok.title ?? false}
        greyBackground={props.blok.grey_background ?? false}
        background_color={props.blok.background_color ?? '#ffffff'}
      />
    </SbEditable>
  );
};

export default LetsMakeContactBlok;
