/* eslint-disable camelcase */
import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import MapModule from './MapModule';

const MapBlok = (props) => {
  const prepareTitle = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(props.blok.title)} />;
  };

  const prepareAddress = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(props.blok.address)} />;
  };

  const prepareContact = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(props.blok.contact)} />;
  };

  const prepareMapProps = () => {
    return {
      lat: props.blok?.map_center_lat ?? 60,
      lng: props.blok?.map_center_lng ?? 30.33,
    };
  };

  const prepareMarkerProps = () => {
    return {
      lat: props.blok?.map_marker_lat ?? 60,
      lng: props.blok?.map_marker_lng ?? 30.33,
    };
  };

  return (
    <SbEditable content={props.blok}>
      <MapModule
        titleHtml={prepareTitle()}
        addressHtml={prepareAddress()}
        mapMarker={prepareMarkerProps()}
        mapCenter={prepareMapProps()}
        contactHtml={prepareContact()}
        alternative={props.blok?.type}
      />
    </SbEditable>
  );
};

export default MapBlok;
