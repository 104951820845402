import React from 'react';
import styled from 'styled-components';
import Container from '~components/Layout/Container';

const Wrapper = styled.div``;
const AlteredContainer = styled(Container)`
  margin-top: 0px;
  padding-top: 55px !important;
`;
const ProjectGrid = ({ children, ...props }) => {
  return (
    <AlteredContainer {...props}>
      <Wrapper>{children}</Wrapper>
    </AlteredContainer>
  );
};

export default ProjectGrid;
