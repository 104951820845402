import React from 'react';
import styled from 'styled-components';

import * as mixins from '~styles/mixins';
import Address from './Address';
import Map from './Map';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
  padding-top:40px;
  padding-bottom: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 80px;
  }

  & + & {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    & + & {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
`;

const Row = styled.div`
    ${mixins.makeRow()}
    @media ${({ theme }) => theme.breakpoint.md} {
        ${({ alternative }) => alternative && `flex-direction:row-reverse;`}
    }
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ theme, md, xl }) => {
      return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
                order: initial;
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
    }}
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;

  @media ${({ theme }) => theme.breakpoint.xl} {
    width: 80%;

    ${({ alternative }) => alternative && `margin-left:20%;`}
  }
`;

const TitleWrapper = styled.div``;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  .pink {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .light {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 22px;
    line-height: 30px;
  }
`;

const MapModule = ({
  alternative,
  titleHtml,
  addressHtml,
  contactHtml,
  mapCenter,
  mapMarker,
  mapZoom,
}) => {
  return (
    <Container>
      <Row alternative={alternative}>
        <Col md={6} xl={6}>
          <ContentWrapper alternative={alternative}>
            <TitleWrapper>
              <Title>{titleHtml}</Title>
            </TitleWrapper>
            <Address addressHtml={addressHtml} contactHtml={contactHtml} />
          </ContentWrapper>
        </Col>
        <Col md={6} xl={6}>
          <Map
            alternative={alternative}
            mapCenter={mapCenter}
            mapMarker={mapMarker}
            mapZoom={mapZoom}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MapModule;
