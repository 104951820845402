import React from 'react';
import SbEditable from '../../../util/SbEditable';

import LetsNumbersTalk from './LetsNumbersTalk';

const LetsNumbersTalkBlok = ({ blok }) => {
  if (typeof blok === 'undefined') {
    return null;
  }
  return (
    <SbEditable content={blok}>
      <LetsNumbersTalk
        title={blok?.title || undefined}
        copy={blok?.copy || undefined}
        button={blok?.button?.length > 0 ? blok.button : false || undefined}
        numbers={blok?.numbers?.length > 0 ? blok.numbers : false || undefined}
        image={blok?.image || undefined}
      />
    </SbEditable>
  );
};

export default LetsNumbersTalkBlok;
