/* eslint-disable camelcase */
import React from 'react';
import SbEditable from '../../../util/SbEditable';

import WebDesign from './WebDesign';
import WebDesignItem from './WebDesignItem';

const WebDesignBlok = ({ blok }) => {
  const getItems = () => {
    if (typeof blok.items === 'undefined' || blok.items.length === 0) {
      return null;
    }
    return blok.items.map((item) => {
      return (
        <SbEditable key={item._uid} content={item}>
          <WebDesignItem
            heading={item.heading}
            content={item.content}
            button={item.button[0] ?? null}
            imgData={item.img}
          />
        </SbEditable>
      );
    });
  };

  return (
    <SbEditable content={blok}>
      <WebDesign
        heading={blok?.heading}
        copyCol1={blok?.content_col_left}
        copyCol2={blok?.content_col_right}
        button={blok?.button[0] ?? null}
        imgData={blok?.img}
      >
        {getItems()}
      </WebDesign>
    </SbEditable>
  );
};

export default WebDesignBlok;
