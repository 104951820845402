import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 39px;
  height: 30px;

  ${({ layoutType, theme }) => {
    if (layoutType !== 2) {
      return `
        width:100%;
        @media ${theme.breakpoint.md} {
          width:39px;
        }
        `;
    }
    return null;
  }}

  display: flex;
  align-items: center;
  flex-shrink: 0;

  user-select: none;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Current = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.secondary};
  padding-right: 0px;
`;

const Total = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const FormStatus = ({ current, total }) => {
  return (
    <Wrapper>
      <Container>
        <Current>{current}</Current>
        <Total>/{total}</Total>
      </Container>
    </Wrapper>
  );
};

export default FormStatus;
