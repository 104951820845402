/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ seo, defaultImage }) => {
  const getSafe = (variable, fallback) => {
    if (typeof variable !== 'undefined' && variable !== null && variable !== '') {
      return variable;
    }
    return fallback;
  };

  const meta = [
    {
      name: `description`,
      content: seo?.description ?? null,
    },
    {
      itemprop: `name`,
      content: seo?.title ?? null,
    },
    {
      itemprop: `description`,
      content: seo?.description ?? null,
    },
    {
      itemprop: `image`,
      content: getSafe(seo?.og_image ?? null, defaultImage),
    },
    {
      property: `og:site_name`,
      content: 'CCMEDIA',
    },
    {
      property: `og:title`,
      content: getSafe(seo?.og_title, seo?.title) ?? null,
    },
    {
      property: `og:image`,
      content: getSafe(seo?.og_image ?? null, defaultImage),
    },
    {
      property: `og:description`,
      content: getSafe(seo?.og_description, seo?.description) ?? null,
    },
    {
      property: `og:type`,
      content: 'website',
    },
    {
      property: `og:locale`,
      content: 'en_GB',
    },
    {
      name: `twitter:title`,
      content: getSafe(seo?.twitter_title, seo?.title) ?? null,
    },
    {
      name: `twitter:image`,
      content: getSafe(seo?.twitter_image, defaultImage),
    },
    {
      name: `twitter:description`,
      content: getSafe(seo?.twitter_description, seo?.description) ?? null,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
  ];

  const filteredMeta = meta.filter((item) => {
    if (item.content !== null && item.content !== '') {
      return true;
    }
    return false;
  });

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={seo?.title}
      meta={filteredMeta}
    />
  );
};

SEO.defaultProps = {
  seo: {
    og_title: '',
    og_image: '',
    og_description: '',
    twitter_title: '',
    twitter_image: '',
    twitter_description: '',
  },
};

SEO.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    og_title: PropTypes.string,
    og_image: PropTypes.string,
    og_description: PropTypes.string,
    twitter_title: PropTypes.string,
    twitter_image: PropTypes.string,
    twitter_description: PropTypes.string,
  }),
};

export default SEO;
