import React, { useContext } from 'react';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import HelloVideo from '~src/assets/videos/hello.mp4';
import Row from '~components/Layout/Row';
import Container from '~components/Layout/Container';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';
import Button from '~components/Button/Button';
import IconWrapper from '~components/Button/IconWrapper';
import ContactContext from '../../../../util/context';

import * as mixins from '~styles/mixins';

const SectionWrapper = styled.div`
  background: #f1f1f1;
`;

const AltContainer = styled(Container)`
  overflow: visible;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
`;

const Col = styled.div`
${mixins.makeColReady()}
${mixins.makeCol()}

${({ theme, md, lg, xl }) => {
  return `
          @media ${theme.breakpoint.md} {
              ${mixins.makeCol(md ?? 12, 12)}
          }
          @media ${theme.breakpoint.lg} {
              ${mixins.makeCol(lg ?? 12, 12)}
          }
          @media ${theme.breakpoint.xl} {
              ${mixins.makeCol(xl ?? 12, 12)}
          }
      `;
}}
`;

const AlteredCol = styled(Col)`
  padding: 0;
  @media ${({ theme }) => theme.BreakpointDown.md} {
    &.imageSide {
      margin-left: 0px;
      margin-top: 40px;
    }
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    margin-left: 8.333333%;
  }
`;

const AlteredButton = styled(Button)`
  position: relative;
  background: transparent;
  overflow: hidden;
  a {
    position: relative;
  }

  ${Button} {
    position: relative;
    z-index: 4;
  }
  &:before {
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 0;
    width: 200%;
    height: 100%;
    background: #232424;
  }
  transition: 0.5s ease all;
  /* box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.20); */
  :hover {
    :before {
      -webkit-transform: translateX(55%);
      -ms-transform: translateX(55%);
      transform: translateX(55%);
    }
    color: white;
    ${Icon} {
      color: white;
    }
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    margin-top: 20px !important;
  }
`;
const AlteredVideo = styled.video`
  display: block;
  margin: 0 auto;
  width: 100%;
  padding-top: 25px;
`;

const Title = styled(ListSliderTitle)`
  &:first-of-type h2 {
    margin-bottom: 0;
  }
  &.no-top-padding {
    padding-bottom: 1rem;
    h2 {
      margin-top: 0;
    }
  }
`;
const InterestedInUsing = () => {
  const contactContext = useContext(ContactContext);

  return (
    <SectionWrapper>
      <AltContainer>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <Title>Interested in working with our team?</Title>
            <Title className="no-top-padding" style={{ color: '#ABAAAD' }}>
              <span className="title-grey"> Start by saying Hi! </span>
            </Title>
            <Row>
              {contactContext?.whatsapp && contactContext.whatsapp !== '' && (
                <Col md={6} lg={6} xl={6}>
                  {/* <AlteredButton link={contactContext.whatsapp} variant="modal">
                    <IconWrapper>
                      <Icon icon={faWhatsapp} />
                    </IconWrapper>
                    WhatsApp us
                  </AlteredButton> */}
                </Col>
              )}
              {contactContext?.email && contactContext.email !== '' && (
                <Col md={6} lg={6} xl={6}>
                  <AlteredButton link={`mailto:${contactContext.email}`} variant="modal">
                    <IconWrapper>
                      <Icon icon={faEnvelope} />
                    </IconWrapper>
                    {contactContext.email}
                  </AlteredButton>
                </Col>
              )}
            </Row>
          </Col>
          <AlteredCol className="imageSide" md={12} lg={5} xl={5}>
            {/* <Image fluid={imageData.file.childImageSharp.fluid} /> */}
            <AlteredVideo autoPlay loop muted playsInline>
              <source src={HelloVideo} type="video/mp4" />
            </AlteredVideo>
          </AlteredCol>
        </Row>
      </AltContainer>
    </SectionWrapper>
  );
};

export default InterestedInUsing;
