/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';

import Title from './components/Title';
import Button from '../../../Button/Button';

export const mockData = {
  heading: 'Content Creation',
  content:
    'Captivating content creation that amplifies your brand message and shapes your brand story. Creativity, authenticity and storytelling are the three pillars that are ever-present in our content strategies.',
  img: {
    filename: '//a.storyblok.com/f/39898/2250x1500/c15735a73c/demo-image-human.jpeg',
  },
  button: [
    {
      label: 'Learn More',
      url: '#',
    },
  ],
};

const Wrapper = styled.div``;

const TextWrapper = styled.div`
  width: ${500 / 6}%;
  letter-spacing: 0.8px;
`;

const ButtoWrapper = styled.div`
  padding-top: 20px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`;

const ImgComponent = styled(Img)`
  &:hover img {
    transform: scale(1.02) translate(-0.07%, 0.05%);
  }
`;

const WebDesignItem = ({ heading, content, imgData, button }) => {
  const mainImg = () => {
    if (typeof imgData === 'undefined') {
      return null;
    }
    const imgUrl = imgData?.filename?.replace(/(^\w+:|^)/, '');
    const img = getFluidGatsbyImage(imgUrl, {
      maxWidth: 560,
    });

    if (img === null) {
      return null;
    }

    return (
      <ImgComponent
        alt={imgData?.alt}
        title={imgData?.title}
        fluid={img}
        imgStyle={{ transition: 'transform 2.5s ease-in-out' }}
      />
    );
  };

  const returnButton = () => {
    if (typeof button === 'undefined') {
      return null;
    }

    return (
      <ButtoWrapper>
        <Button link={button?.url?.cached_url ?? '#'}>{button?.label}</Button>
      </ButtoWrapper>
    );
  };

  return (
    <Wrapper>
      {mainImg()}
      <Title>{heading}</Title>
      <TextWrapper>{content}</TextWrapper>
      {returnButton()}
    </Wrapper>
  );
};

export default WebDesignItem;
