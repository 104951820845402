import React, { useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import Slider from 'react-slick';
import Img from 'gatsby-image/withIEPolyfill';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import WeCanHelpYouWithSliderNext from './WeCanHelpYouWithSliderNext';
import Container from '~components/Layout/Container';

import 'slick-carousel/slick/slick.css';

const AlteredContainer = styled(Container)`
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media ${({ theme }) => theme.BreakpointDown.lg} {
    padding-top: 0px;
  }
`;

const ButtonPos = styled.div`
  z-index: 999;

  position: absolute;
  top: calc(53px + 2rem);

  right: 12px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    padding-top: 20px;
    position: static;
  }
  @media ${({ theme }) => theme.BreakpointDown.lg} {
    top: auto;
    position: relative;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  @media ${({ theme }) => theme.BreakpointDown.lg} {
    display: block;
  }
`;
const ContentSide = styled.div`
  overflow: hidden;
  flex: 1;
  @media ${({ theme }) => theme.BreakpointDown.lg} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const ArrowSide = styled.div`
  display: grid;
  place-items: center;
  min-width: 52px;

  @media ${({ theme }) => theme.BreakpointDown.lg} {
    position: absolute;
    top: calc(83vw + 10px);
    /* transform: translateY(-50%); */
    right: 20px;
    min-width: initial;
  }
`;
const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #f1f1f1;
`;
const WhiteCover = styled.div`
  position: absolute;
  width: 65%;
  right: 0px;
  height: calc(100% - 15px);
  background: white;
  top: 15px;
  z-index: 0;

  @media ${({ theme }) => theme.BreakpointDown.lg} {
    top: initial;
    bottom: 0px;
    width: 100%;
    height: 70%;
  }
`;

const ImageSide = styled.div`
  min-width: 460px;
  @media ${({ theme }) => theme.BreakpointDown.lg} {
    min-width: auto;
    height: 83vw;
  }
`;

const WeCanHelpYouWithSlider = ({ children, blok }) => {
  const themeContext = useContext(ThemeContext);

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    arrows: false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'ease-in-out',
    fade: true,
    responsive: [
      {
        breakpoint: parseInt(themeContext.breakpointRaw.lg, 10) - 0.1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: parseInt(themeContext.breakpointRaw.sm, 10) - 0.1,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };
  const imgUrl = blok.we_can_help_you_slider_image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgUrl, {
    maxWidth: 460,
    maxHeight: 475,
  });

  return (
    <SectionWrapper>
      <AlteredContainer style={{ position: 'relative', zIndex: '1' }}>
        <FlexContainer>
          <ImageSide>
            <Img
              alt={blok.we_can_help_you_slider_image?.alt}
              title={blok.we_can_help_you_slider_image?.title}
              fluid={img}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </ImageSide>
          <ContentSide>
            <Slider style={{ width: '100%' }} ref={sliderRef} {...settings}>
              {children}
            </Slider>
          </ContentSide>

          <ArrowSide>
            <ButtonPos>
              <WeCanHelpYouWithSliderNext
                onClick={() => {
                  sliderRef.current.slickNext();
                }}
              />
            </ButtonPos>
          </ArrowSide>
        </FlexContainer>
      </AlteredContainer>
      <WhiteCover />
    </SectionWrapper>
  );
};

export default WeCanHelpYouWithSlider;
