import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

const AlteredDiv = styled.div`
  display: inline-block;
  position: absolute;
  right: 20px;
  margin-top: -3px;
  margin-bottom: 0;
`;

const Image = styled(Img)`
  width: 18px;
  height: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  transform: translateY(0px);

  ${(props) => (props.variant === 'down' ? 'transform: rotate(90deg) !important; top: -2px;' : '')}

  img {
    vertical-align: middle;
  }
`;
const ArrowIcon = ({ variant, ...props }) => {
  const imageData = useStaticQuery(graphql`
    query dropdownarrow {
      file(relativePath: { eq: "dropdown_arrow.png" }) {
        childImageSharp {
          fixed(width: 12, height: 12) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <AlteredDiv {...props}>
      <Image
        alt="Arrow icon"
        title="Arrow icon"
        fixed={imageData.file.childImageSharp.fixed}
        variant={variant}
      />
    </AlteredDiv>
  );
};

export default ArrowIcon;
