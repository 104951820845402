import React, { useRef } from 'react';
import styled from 'styled-components';
import ImageWideBorder from './ImageWideBorder/ImageWideBorder';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import SbEditable from '~components/util/SbEditable';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';

import './OurWorkStrives.css';

import * as mixins from '~styles/mixins';
import AnchorButton from '~components/Button/AnchorButton';

const Container = styled.div`
  overflow: visible;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    ${mixins.makeContainer}
    ${mixins.makeContainerMaxWidths}
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    overflow: hidden;
  }
`;

const SubTitle = styled.div`
  h3 {
    line-height: 20px !important;
  }
`;
const AlteredListSliderTitle = styled(ListSliderTitle)`
  h2 {
    line-height: 55px !important;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    h2 {
      line-height: 37px !important;
      font-size: 32px !important;
      letter-spacing: 0.64px !important;
      margin-bottom: 0px;
    }
  }
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ theme, md, lg, xl }) => {
      return `
          @media ${theme.breakpoint.md} {
              ${mixins.makeCol(md ?? 12, 12)}
          }
          @media ${theme.breakpoint.lg} {
              ${mixins.makeCol(lg ?? 12, 12)}
          }
          @media ${theme.breakpoint.xl} {
              ${mixins.makeCol(xl ?? 12, 12)}
          }
      `;
    }}
    @media ${({ theme }) => theme.BreakpointDown.md} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
`;

const Row = styled.div`
  ${mixins.makeRow}
  overflow: visible;
`;

const OurWorkStrives = (props) => {
  const { blok } = props;

  const ref = useRef(null);

  return (
    <SbEditable content={blok}>
      <Container ref={ref}>
        <Row>
          <Col md={12} lg={6} xl={5}>
            <AlteredListSliderTitle>
              <SanitizeHTML
                html={
                  Api.richTextResolver.render(blok.heading) === ''
                    ? blok.heading
                    : Api.richTextResolver.render(blok.heading)
                }
              />
            </AlteredListSliderTitle>
            <SubTitle>
              <SanitizeHTML
                html={
                  Api.richTextResolver.render(blok.description) === ''
                    ? blok.description
                    : Api.richTextResolver.render(blok.description)
                }
              />
            </SubTitle>
            <AnchorButton parentRef={ref} />
          </Col>
          <Col md={12} lg={6} xl={7}>
            <ImageWideBorder blok={blok} />
          </Col>
        </Row>
      </Container>
    </SbEditable>
  );
};

export default OurWorkStrives;
