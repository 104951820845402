import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import TwoColumnListSlider from './TwoColumnListSlider';

const TwoColumnListSliderBlok = (props) => {
  const prepareTitle = () => {
    return <SanitizeHTML html={Api.richTextResolver.render(props.blok.title)} />;
  };

  return (
    <SbEditable content={props.blok}>
      <TwoColumnListSlider
        title={prepareTitle()}
        imageUrl={props.blok.img.filename}
        data={props.blok.data}
      >
        <SanitizeHTML html={Api.richTextResolver.render(props.blok.copy)} />
      </TwoColumnListSlider>
    </SbEditable>
  );
};

export default TwoColumnListSliderBlok;
