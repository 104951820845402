import React from 'react';
import SbEditable from '~components/util/SbEditable';

import BlokGrid from './BlogGrid';

const BlogGridBlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <BlokGrid />
    </SbEditable>
  );
};

export default BlogGridBlok;
