import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';
import * as mixins from '~styles/mixins';

import Button from '~components/Button/Button';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 40px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  
  &.featured-content {
    .post-excerpt {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      ${mixins.makeCol(7, 12)}

      .post-excerpt {
        padding-top: 10px;
        padding-bottom: 30px;
        width: ${() => `${(6 / 7) * 100}%`};
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.featured-image {
    @media ${({ theme }) => theme.breakpoint.md} {
      ${mixins.makeCol(5, 12)}
    }
    max-height: 472px;

    position: relative;

    .gatsby-image-wrapper {
      float: right;
      width: 600px;
    }
    img {
      transition: 2.5s ease-in-out transform !important;
      &:hover {
        overflow: hidden;
        transform: scale(1.02) translate(-0.07%, 0.05%);
      }
    }
  }
`;

const LatestPost = ({ post }) => {
  const imageRaw = post.content.featured_image?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 600,
    maxHeight: 472,
  });

  return (
    <Container>
      <Row>
        <Col className="featured-image">
          <Img
            alt={post.content.featured_image?.alt}
            title={post.content.featured_image?.title}
            fluid={image}
          />
        </Col>
        <Col className="featured-content">
          <ListSliderTitle>{post.content.title}</ListSliderTitle>
          <div className="post-excerpt">{post.content.excerpt}</div>
          <div>
            <Button variant="secondary" link={post.full_slug}>
              Learn more
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LatestPost;
