import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LatestPost from '../view/LatestPost';
import Post from '../view/Post';
import * as mixins from '~styles/mixins';
import Slick from './Slick';

import Arrow from '~components/util/arrow';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top: 5rem;
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const ButtonCol = styled.div`
  display: none;

  @media ${({ theme }) => theme.breakpoint.md} {
    ${mixins.makeColReady()}
    ${mixins.makeCol()}

    display: flex;
    justify-content: center;

    padding: 3rem 0;

    button {
      align-items: center;
      display: flex;
      border-radius: 999px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      line-height: 1;
      font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
      @media ${({ theme }) => theme.breakpoint.md} {
        line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.md};
        font-size: ${({ theme }) => theme.font.typography.copy.size.md};
      }

      cursor: pointer;

      padding: 3px 10px 3px 20px;

      background: rgba(255, 255, 255, 0);
      border: 1px solid #000;

      &:hover {
        background: ${({ theme }) => theme.colors.secondary};
        border: 1px solid ${({ theme }) => theme.colors.secondary};
        color: #fff;

        svg g {
          stroke: white;
        }
      }
    }
    ${({ hide }) => hide === true && `visibility:hidden;`}
  }
`;

const StyledArrow = styled.div`
  display: flex;
`;

const GridScreen = ({ posts }) => {
  const [page, setPage] = useState(1);
  const pageLimit = 2;
  const [isMobile, setMobile] = useState(false);

  const slickRef = useRef(null);

  const postList = Object.assign([], posts);
  const latestPost = postList.shift();

  const indLastPost = page * pageLimit;
  // const indFirstPost = indLastPost - pageLimit;
  const indFirstPost = 0;
  const currentPosts = postList.slice(indFirstPost, indLastPost);

  const getNextPage = () => {
    setPage(page + 1);
  };

  const slickSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    onEdge: (d) => {
      if (d === 'left') {
        getNextPage();
      }
    },
  };

  const updateIsMobileIfNeeded = (width) => {
    if (width > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        updateIsMobileIfNeeded(window.innerWidth);
      };

      updateIsMobileIfNeeded(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return null;
  }, []);

  const printSliderOrGrid = () => {
    const postsMap = currentPosts.map((it, i) => {
      return (
        <Post
          post={it}
          key={Math.random().toString(36).substr(2, 9)}
          last={currentPosts.length === i + 1}
        />
      );
    });

    if (isMobile) {
      return (
        <Slick ref={slickRef} {...slickSettings}>
          {postsMap}
        </Slick>
      );
    }
    return postsMap;
  };

  return (
    <>
      <LatestPost post={latestPost} />
      <Container>
        <Row>{printSliderOrGrid()}</Row>
        <Row>
          <ButtonCol hide={page >= Math.ceil(postList.length / pageLimit)}>
            <button type="button" onClick={getNextPage}>
              Load more
              <StyledArrow>
                <Arrow color="#000" />
              </StyledArrow>
            </button>
          </ButtonCol>
        </Row>
      </Container>
    </>
  );
};

export default GridScreen;
