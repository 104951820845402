import React from 'react';
import styled from 'styled-components';

import Arrow from '../../../util/arrow';

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  height: 34px;

  transform: scale(0);

  flex-shrink: 0;

  margin-right: 1rem;

  transition: 0.5s transform ease-in-out;

  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
`;

const LabelWrapper = styled.div`
  position: relative;
  left: calc(-34px - 1rem);

  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding-top: 11px;

  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 1.6875rem;
  font-size: 1.25rem;

  white-space: nowrap;

  transition: transform 0.4s linear;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 10px;

    font-size: 1.375rem;
    line-height: 1.875rem;
  }

  ${({ isActive, theme }) => {
    if (isActive) {
      return `
                font-weight: ${theme.font.weight.bold};

                ${ArrowWrapper} {
                    transform:scale(1);
                }

            `;
    }
    return null;
  }}

  &.active {
    transform: translateX(50px);
  }
`;

const ListSliderItem = ({ isActive, children, ...props }) => {
  return (
    <LabelWrapper {...props} className={isActive ? 'active' : ''} isActive={isActive}>
      <ArrowWrapper>
        <Arrow size={30} />
      </ArrowWrapper>
      {children}
    </LabelWrapper>
  );
};

export default ListSliderItem;
