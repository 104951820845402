/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Button from '~components/Button/Button';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(1, 2)}
  }

`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 0;
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 83.333333%;
    margin-bottom: 0;
  }
`;

const Heading = styled.h2`
  margin-top: 0;
  text-transform: uppercase;

  .thin {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`;

const ContentButton = styled(Button)`
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${({ mobile, theme }) => {
    if (mobile === true) {
      return `
      margin-bottom: 0;
        @media ${theme.breakpoint.lg} {
          display: none;
        }
      `;
    }
    return `
    display: none;
      @media ${theme.breakpoint.lg} {
        display: inline-flex;
      }
    `;
  }}
`;

const AlterListSliderTitle = styled(ListSliderTitle)`
  padding-bottom: 1rem;
`;

const StatsCol = styled(Col)`
  padding-top: 1rem;
`;

const data = {
  heading: `How we excelled <br /><span class="thin">For Docks Bruxsel</span>`,
  content: `By working closely with our clients, we can identify and highlight what’s important to you and what works well. Having a purpose or meaning behind each action or touchpoint delivers better end results for you, whether that’s more conversions on your website, higher click-through rate on Google ads, or more engagement across social.`,
};

const HowWeExcelled = ({ title, heading, content, children, button }) => {
  return (
    <Container>
      <Row>
        <Col>
          {typeof title !== 'undefined' && title !== '<p></p>' && (
            <AlterListSliderTitle>
              <SanitizeHTML html={title} />
            </AlterListSliderTitle>
          )}
          <Heading>
            <SanitizeHTML html={heading ?? data.heading} />
          </Heading>
          <Content>
            <SanitizeHTML html={content ?? data.content} />
            <ContentButton link={button?.url?.cached_url ?? '#'}>
              {button?.label ?? `I want some numbers like this`}
            </ContentButton>
          </Content>
        </Col>
        <StatsCol>
          {children}
          <ContentButton mobile link={button?.url?.cached_url ?? '#'}>
            {button?.label ?? `I want some numbers like this`}
          </ContentButton>
        </StatsCol>
      </Row>
    </Container>
  );
};

export default HowWeExcelled;
