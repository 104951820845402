import Page from './ContentTypes/Page';
import IndividualService from './ContentTypes/IndividualService';
import IndividualProject from './ContentTypes/IndividualProject';
import IndividualBlog from './ContentTypes/IndividualBlog';
import Global from './ContentTypes/Global';

import getComponent from '~components/util/getComponent';

const ContentTypeList = {
  page: Page,
  individual_service: IndividualService,
  individual_project: IndividualProject,
  blog: IndividualBlog,
  global: Global,
};

const getContentType = (type) => {
  return getComponent(ContentTypeList, type);
};

export default getContentType;
