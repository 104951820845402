import React, { useRef } from 'react';
import styled from 'styled-components';
import ListSliderTitle from '~components/Heading/Title';
import SanitizeHTML from '~components/util/SanitizeHTML';

import AnchorButton from '~components/Button/AnchorButton';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:80px;
  padding-bottom: 100px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 120px;
    padding-bottom: 60px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
   ${mixins.makeColReady()} 
   ${mixins.makeCol()}
   
   @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(1, 2)}
   }
`;

const Title = styled(ListSliderTitle)`
  & .greytext {
    color: #abaaae;
  }

  h2,
  h1 {
    margin-top: 0;
  }
`;

const Content = styled.span`
  overflow: hidden;
  h2 {
    text-transform: uppercase;
    &.tags {
      margin-left: -11px;
      margin-right: -11px;
    }
    margin-top: 0;

    &.tags span {
      padding-left: 11px;
      padding-right: 11px;
    }
    span {
      display: block;
      @media ${({ theme }) => theme.breakpoint.lg} {
        display: inline-block;
      }

      position: relative;
    }
    span + span:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 15px;

      background: #d8d8d8;
    }
  }
`;

const TitleTags = styled.div`
  overflow: hidden;
  width: 100%;

  padding-bottom: 1.5rem;
`;

export const testData = {
  title: `Docks Bruxsel <br />
  <span class="greytext">
    website <br />
    improvements
  </span>`,
  tags: `<h2>
  <span>Website Build</span>
  <span>Seo Reports</span>
  <span>Social Media Marketing</span>
</h2>`,
  content: `We deliver impactful web designs, digital strategies, engaging social content, effective
  seo and so much more for all your digital needs, so much more for all your digital.
  <br />
  We deliver impactful web designs, digital strategies, engaging social content, effective
  seo and so much more for all your digital needs, so much more for all your. <br />
  We deliver impactful web designs, digital strategies, engaging social content, effective
  seo and so much more for all your digital needs.`,
};

const StyledAnchorButton = styled(AnchorButton)`
  margin-top: 50px;
  margin-bottom: 60px;

  display: none;

  @media ${({ theme }) => theme.breakpoint.md} {
    display: flex;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;

const Hero = ({ title, tag, tags, content }) => {
  const ref = useRef(null);
  console.log(tag);

  let tagKey = 0;

  return (
    <Container ref={ref}>
      <Row>
        <Col>
          <Title tag={tag}>
            <SanitizeHTML html={title} />
          </Title>
          <StyledAnchorButton parentRef={ref} />
        </Col>
        <Col>
          <Content>
            <TitleTags>
              <h2 className="tags">
                {tags?.map((singleTag) => {
                  tagKey += 1;
                  return <span key={tagKey}>{singleTag}</span>;
                })}
              </h2>
            </TitleTags>
            <SanitizeHTML html={content} />
          </Content>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
