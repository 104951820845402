import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import LinkComponent from '~components/util/Link';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(12, 12)}
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top:20px;
    padding-bottom:20px;
    ${mixins.makeCol(6, 12)}
    &:nth-child(odd) {
        ${({ last }) => {
          if (last === true) {
            return mixins.makeCol();
          }
          return null;
        }}
    }
  
    }
  @media ${({ theme }) => theme.breakpoint.lg} {

      ${mixins.makeCol(7, 12)}
    
    &:nth-child(4n+1),
    &:nth-child(4n+4) {
        ${mixins.makeCol(5, 12)}
    }
    &:nth-child(odd) {
        ${({ last }) => {
          if (last === true) {
            return mixins.makeCol();
          }
          return null;
        }}
    }
}

`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }

  &:hover * {
    color: ${({ theme }) => theme.colors.secondary};
  }
  img {
    transition: 2.5s ease-in-out transform !important;
    &:hover {
      overflow: hidden;
      transform: scale(1.02) translate(-0.07%, 0.05%);
    }
  }
`;

const Image = styled(Img)`
  height: 317px;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.125rem;
  width: 90%;
  line-height: 0.9;
  padding-top: 22px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 35px;
    font-size: 1.375rem;
    line-height: 1.1;
  }

  transition: color 0.2s ease-in-out;
`;

const Excerpt = styled.div`
  width: 80%;
  padding-top: 22px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 22px;
  }
  transition: color 0.2s ease-in-out;
`;

const Link = styled(LinkComponent)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
`;

const Post = ({ post, last }) => {
  const imageRaw = post.content.featured_image?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 660,
  });
  return (
    <Container last={last}>
      <Link to={post.full_slug} title={post.content.title}>
        <Wrapper>
          <Image
            alt={post.content.featured_image?.alt}
            title={post.content.featured_image?.title}
            fluid={image}
            objectPosition="top center"
          />
          <Title>{post.content.title}</Title>
          <Excerpt>{post.content.excerpt}</Excerpt>
        </Wrapper>
      </Link>
    </Container>
  );
};

export default Post;
