import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import * as mixins from '../../../../styles/mixins';
import Title from '../WebDesign/components/Title';

const ContainerFluid = styled.div`
  ${mixins.makeContainer()}

  padding-top: 25px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 0;
  }

  padding-left: 0;
  padding-right: 0;

  overflow: hidden;
  ${({ greyBackground }) => greyBackground && `background: #fafafa;`}
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}

    ${({ theme, md, lg }) => {
      return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
            }
            @media ${theme.breakpoint.lg} {
                ${mixins.makeCol(lg ?? 12, 12)}
            }
        `;
    }}
`;

const LogoGridWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    transform: translateY(40px);
    width: 455px;
    height: 350px;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 650px;
    height: 500px;
  }

  @media ${({ theme }) => theme.breakpoint.xl} {
    transform: translateY(100px);
    width: 966px;
    height: 743px;
  }

  position: relative;
`;

const BackgroundWrapper = styled.div`
  width: 650px;
  height: 500px;

  @media ${({ theme }) => theme.breakpoint.md} {
    width: 100%;
    height: 100%;
  }
  position: absolute;
  z-index: -1;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;

    background: white;
    border-radius: 25px;
    @media ${({ theme }) => theme.breakpoint.lg} {
      border-radius: 50px;
    }
    z-index: -1;
  }
`;

const LogoGrid = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    width: calc(50vw + ${({ theme }) => parseInt(theme.containerSize.sm, 10) / 2}px);
    max-width: 650px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    width: calc(50vw);
    padding: 60px 30px;
    max-width: 455px;
    max-height: 350px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    width: calc(50vw + ${({ theme }) => parseInt(theme.containerSize.lg, 10) / 12}px);
    max-width: 650px;
    max-height: 500px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    max-width: 966px;
    max-height: 743px;
    padding: 100px 90px;
    width: calc(50vw + ${({ theme }) => parseInt(theme.containerSize.xl, 10) / 12}px);
  }

  display: flex;
  flex-wrap: wrap;
`;

const Image = styled(Img)`
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const Content = styled.div`
  line-height: 27px;

  padding-bottom: 15px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-bottom: 0;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    width: 80%;
  }

  .subtitle {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.light};
    letter-spacing: 1.1px;

    @media ${({ theme }) => theme.breakpoint.md} {
      font-size: 22px;
    }
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  p:last-child() {
    margin-bottom: 25px !important;
  }
  h2 {
    margin-bottom: 15px;
  }

  span {
    display: block;
    margin-bottom: 10px;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-bottom: 40px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    h2 {
      margin-bottom: 28px;
    }
    span {
      margin-bottom: 10px;
    }
    p:last-child() {
      margin-bottom: initial;
    }
    padding-bottom: 120px;
  }
`;

const TabletPortfolio = ({ greyBackground, title, content, children }) => {
  const imageData = useStaticQuery(graphql`
    query TabletQuery {
      file(relativePath: { eq: "tablet.png" }) {
        childImageSharp {
          fluid(maxWidth: 966, maxHeight: 743) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <ContainerFluid greyBackground={greyBackground}>
      <Container>
        <Row>
          <Col md={6} lg={5}>
            <TextWrapper>
              <Title>{title}</Title>
              <Content>{content}</Content>
            </TextWrapper>
          </Col>
          <Col md={6} lg={7}>
            <LogoGridWrapper>
              <BackgroundWrapper>
                <Image alt="" fluid={imageData.file.childImageSharp.fluid} />
              </BackgroundWrapper>
              <LogoGrid>{children}</LogoGrid>
            </LogoGridWrapper>
          </Col>
        </Row>
      </Container>
    </ContainerFluid>
  );
};

export default TabletPortfolio;
