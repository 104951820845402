/* eslint-disable camelcase */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import Masonry from 'react-masonry-css';

import * as mixins from '../../../../styles/mixins';
import Title from './components/Title';
import Button from '../../../Button/Button';

export const mockData = {
  img: {
    filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
  },
  copyCol1:
    'We supply a range of services, we can help your buld your brand fromthe ground up or just help you with that one area you are lacking to push your brand over the line',
  copyCol2:
    'We supply a range of services, we can help your buld your brand fromthe ground up or just help you with that one area you are lacking to push your brand over the line',
};

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top:3rem;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    display:flex;
    flex-direction: column;

    & > ${Row}:first-child {
      order: 2;
      margin-bottom: 5rem;
    }
    & > ${Row}:nth-child(2) {
      order: 1;
    }
    & > ${Row}:nth-child(3) {
      order: 3;
    }
  }
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ mobileOrder }) => mobileOrder && `order:${mobileOrder};`}
    ${({ theme, md, xl }) => {
      return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
                order: initial;
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
    }}
`;

const ImgCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

    padding-top:25px;
  padding-bottom: 25px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  padding-top: 25px;
  padding-bottom: 10px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  align-items: center;
  justify-content: flex-start;
`;

const MasonryGrid = styled(Masonry)`
  display: flex;
  width: auto;

  margin-left: -${({ theme }) => theme.gutterSize.md}px;

  .my-masonry-grid_column {
    padding-left: ${({ theme }) => theme.gutterSize.md}px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 5rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      margin-bottom: ${({ theme }) => theme.gutterSize.md}px;
    }
  }
`;

const ImgComponent = styled(Img)`
  &:hover img {
    transform: scale(1.02) translate(-0.07%, 0.05%);
  }
`;

const WebDesign = ({ heading, copyCol1, copyCol2, button, imgData, children }) => {
  const themeContext = useContext(ThemeContext);

  const mainImg = () => {
    if (typeof imgData === 'undefined') {
      return null;
    }
    const imgUrl = imgData?.filename?.replace(/(^\w+:|^)/, '');
    const img = getFluidGatsbyImage(imgUrl, {
      maxWidth: 1160,
      maxHeight: 485,
    });

    if (img === null) {
      return null;
    }

    return (
      <ImgComponent
        alt={imgData?.alt}
        title={imgData?.title}
        fluid={img}
        imgStyle={{ transition: 'transform 2.5s ease-in-out' }}
      />
    );
  };

  const returnBreakpoint = () => {
    return parseInt(themeContext.breakpointRaw.lg, 10) - 1;
  };

  const breakpointColumnsObj = {
    default: 2,
    [returnBreakpoint()]: 1,
  };

  return (
    <Container>
      <Row>
        <Col md={6} xl={5}>
          <Title>{heading}</Title>
        </Col>
        <Col md={6} xl={7} />
        <Col md={6} xl={5}>
          {copyCol1}
        </Col>
        <Col md={6} xl={5}>
          {copyCol2}
        </Col>
        <Col>
          <ButtonWrapper>
            <Button link={button?.url?.cached_url ?? '#'}>{button?.label ?? `Learn more`}</Button>
          </ButtonWrapper>
        </Col>
      </Row>
      <Row>
        <ImgCol>{mainImg()}</ImgCol>
      </Row>
      <Row>
        <Col>
          <MasonryGrid
            breakpointCols={breakpointColumnsObj}
            columnClassName="my-masonry-grid_column"
          >
            {children}
          </MasonryGrid>
        </Col>
      </Row>
    </Container>
  );
};

export default WebDesign;
