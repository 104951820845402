import React, { useRef } from 'react';
import styled from 'styled-components';
import AnchorButton from '~components/Button/AnchorButton';
import SanitizeHTML from '~components/util/SanitizeHTML';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Col = styled.div`
   ${mixins.makeColReady()} 
   ${mixins.makeCol()}
   
   @media ${({ theme }) => theme.breakpoint.md} {
       ${({ fw }) => (fw ? mixins.makeCol() : mixins.makeCol(1, 2))}
   }
`;

const ContentCol = styled(Col)`
  /* line-height: 1.375rem; */
  @media ${({ theme }) => theme.breakpoint.md} {
    letter-spacing: 0.8px;
  }

  & + & {
    padding-top: 2rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      padding-top: 0;
    }
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Title = styled.h2`
  text-transform: uppercase;

  .thin {
    display: block;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: initial;
    }
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`;

export const testData = {
  title: `How we made <span class='thin'>an impactful website</span>`,
  columnLeft: `We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.`,
  columnRight: `We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.`,
};

const TwoColumnsText = ({ title, columnLeft, columnRight, showCta }) => {
  const ref = useRef(null);
  return (
    <Container ref={ref}>
      <Row>
        <Col fw>
          <Title>
            <SanitizeHTML html={title} />
          </Title>
        </Col>
      </Row>
      <Row>
        <ContentCol>
          <SanitizeHTML html={columnLeft} />
        </ContentCol>
        <ContentCol>
          <SanitizeHTML html={columnRight} />
        </ContentCol>
      </Row>
      {showCta && showCta === true && (
        <Row>
          <Col fw>
            <AnchorButton parentRef={ref} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default TwoColumnsText;
