import React from 'react';

import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';

import Api from '~util/storyblok-api';
import SEO from '~components/util/seo';

import { getSafe } from '../../util/util';
import readTime from '~components/util/readTime';
import SanitizeHTML from '~components/util/SanitizeHTML';

import SimilarBlog from '~components/PageSpecific/Blog/SimilarBlog/SimilarBlog';
import Heading from '~components/PageSpecific/Blog/Heading/Heading';
import LetsMakeContact from '~components/Contact/LetsMakeContact';
import Banner from '~components/PageSpecific/Blog/Banner/Banner';
import Content from '~components/PageSpecific/Blog/Content/Content';

Api.setComponentResolver((component) => {
  switch(component) {
    case 'spacer':
      return `<div style="height: 2rem;"></div>`
      break;

  }
})


const IndividualBlog = (props) => {
  console.log(props.blok.body);
  const blogContent = Api.richTextResolver.render(props.blok.body);
  console.log("resolver" + blogContent);
  const readTimeRes = readTime(blogContent ?? '');

  const blogHeading = {
    title: Api.richTextResolver.render(props.blok.heading) ?? 'Enter title',
    author: props.blok.author ?? 'Select author',
    author_title: props.blok.author_title ?? 'Enter job title',
    published_at: props.published_at ?? 'Unknown date',
    read_time: readTimeRes.humanizedDuration,
  };

  const hasImage =
    typeof props.blok.featured_image?.filename !== 'undefined' &&
    props.blok.featured_image?.filename !== null &&
    props.blok.featured_image?.filename !== '';

  const canImageMeta = () => {
    if (
      hasImage === true &&
      (typeof props.blok.og_image === 'undefined' ||
        props.blok.og_image === null ||
        props.blok.og_image === '')
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ParallaxProvider>
        <SEO seo={props.blok.seo} defaultImage={props.globalLayout?.og_image_default?.filename} />
        <Helmet>
          <meta name="viewport" content="initial-scale=1, minimum-scale=1, maximum-scale=1" />

          {canImageMeta() === true && (
            <meta itemProp="image" content={props.blok.featured_image.filename} />
          )}
          {canImageMeta() === true && (
            <meta property="og:image" content={props.blok.featured_image.filename} />
          )}
          {canImageMeta() === true && (
            <meta name="twitter:image" content={props.blok.featured_image.filename} />
          )}
        </Helmet>
        <Heading data={blogHeading} url={getSafe(() => props.globalLayout.href, null)} />
        <Banner image={props.blok.banner} />
        <Content url={getSafe(() => props.globalLayout.href, null)}>
          <SanitizeHTML html={blogContent} />
        </Content>
        <SimilarBlog categories={props?.blok?.tags} />
        <LetsMakeContact
          title={false}
          greyBackground={false}
          background_color={props.blok.background_color ?? '#ffffff'}
        />
      </ParallaxProvider>
    </>
  );
};

export default IndividualBlog;
