import React, { useRef } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Api from '~components/../util/storyblok-api';
import SanitizeHTML from '~components/util/SanitizeHTML';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import Row from '~components/Layout/Row';
import Container from '~components/Layout/Container';

import * as mixins from '~styles/mixins';
import AnchorButton from '~components/Button/AnchorButton';

const AlteredListSliderTitle = styled(ListSliderTitle)`
  h2 {
    font-size: ${({ theme }) => theme.font.typography.hero.size.xs};
    line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.xs};

    @media ${({ theme }) => theme.breakpoint.md} {
      font-size: ${({ theme }) => theme.font.typography.hero.size.md};
      line-height: ${({ theme }) => theme.font.typography.hero.lineHeight.md};
    }
  }

  & .greytext {
    color: #abaaae;
  }

  & .pinktext {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Col = styled.div`
${mixins.makeColReady()}
${mixins.makeCol()}

@media ${({ theme }) => theme.breakpoint.lg} {
  
  ${mixins.makeCol(6, 12)}
}
`;

const OptionalTextWrapper = styled.div`
  ${mixins.makeCol()}
`;

const Wrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 0px;
    padding-bottom: 40px;

    border-bottom: 35px solid ${({ theme }) => theme.colors.grey300};
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 0px;
    padding-bottom: 80px;
  }
`;

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  padding-left:0;
  padding-right: 0;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 0;
    padding-right: 0;
  }

  position: relative;

  display: flex;
  flex-direction: column-reverse;
  @media ${({ theme }) => theme.breakpoint.lg} {
    flex-direction: column-reverse;
  }
`;

const AbsolutRow = styled.div`
${mixins.makeRow()}
  @media ${({ theme }) => theme.breakpoint.lg} {
    position: absolute;
    width: 100%;
    height: 100%;
  }

`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const VideoWrapper = styled.div`
  &:hover {
    transform: scale(1.1);
  }
  transition: all ease 0.5s;
  height: 100%;

  background: ${({ theme }) => theme.colors.grey300};

  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 600px;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    min-height: 500px;
  }
`;

const ColContentRight = styled(Col)`
  height: 100%;

  @media ${({ theme }) => theme.breakpoint.lg} {
    display: flex;
    justify-content: flex-end;

    padding-left: 0;
  }
`;

const ImageCol = styled(Col)`
  overflow: hidden;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    order: 2;
  }
`;
const ContentCol = styled(Col)`
  padding-top: 0px;
  padding-bottom: 50px;
`;

function isFileImage(file) {
  if (file.match(/.(jpg|jpeg|png|gif)$/i)) {
    return true;
  }
  return false;

  // return file && file.type.split('/')[0] === 'image';
}

const AlteredVideo = styled.video`
  object-fit: cover;
  object-position: center;
  position: absolute;
  /* height: 100%; */

  @media ${({ theme }) => theme.BreakpointDown.md} {
    width: 100% !important;
  }
`;
const Hero = (props) => {
  const { blok } = props;
  const ref = useRef(null);

  const imgUrl = blok.image.filename?.replace(/(^\w+:|^)/, '');
  const fluid = getFluidGatsbyImage(imgUrl, { maxWidth: 600 });
  const isImage = isFileImage(imgUrl);
  return (
    <Wrapper ref={ref}>
      <FluidContainer>
        {/* <AbsolutRow>
          <ColContentRight>
            
          </ColContentRight>
        </AbsolutRow> */}
        <Container>
          <Row>
            <ImageCol>
              <VideoWrapper>
                {isImage && <Image alt={blok.image?.alt} title={blok.image?.title} fluid={fluid} />}
                {!isImage && (
                  // <iframe
                  //   src={imgUrl}
                  //   title={blok.image?.title}
                  //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  //   frameBorder="0"
                  //   webkitallowfullscreen="true"
                  //   mozallowfullscreen="true"
                  //   allowFullScreen
                  // />
                  <AlteredVideo playsInline autoPlay muted loop>
                    <source src={imgUrl} type="video/mp4" />
                  </AlteredVideo>
                )}
              </VideoWrapper>
            </ImageCol>
            <ContentCol>
              <AlteredListSliderTitle>
                <SanitizeHTML
                  html={
                    Api.richTextResolver.render(blok.main_text) === ''
                      ? blok.main_text
                      : Api.richTextResolver.render(blok.main_text)
                  }
                />
              </AlteredListSliderTitle>
              {typeof blok.optional_text !== 'undefined' && blok.optional_text !== '' && (
                <OptionalTextWrapper>
                  <SanitizeHTML
                    html={
                      Api.richTextResolver.render(blok.optional_text) === ''
                        ? blok.optional_text
                        : Api.richTextResolver.render(blok.optional_text)
                    }
                  />
                </OptionalTextWrapper>
              )}
              <AnchorButton parentRef={ref} />
            </ContentCol>
          </Row>
        </Container>
      </FluidContainer>
    </Wrapper>
  );
};

export default Hero;
