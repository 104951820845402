import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Hero from './Hero';

export const mockData = {};

const HeroBlok = (props) => {
  let { blok } = props;
  if (typeof blok === 'undefined') {
    blok = mockData;
  }
  return (
    <SbEditable content={blok}>
      <Hero blok={blok} />
    </SbEditable>
  );
};

export default HeroBlok;
