import HeroFrontBlok from './PageSpecific/FrontPage/HeroFront/HeroFrontBlok';
import VideoPhonesBlok from './PageSpecific/FrontPage/VideoPhones/VideoPhonesBlok';

import ComponentNotFound from './util/ComponentNotFound';
import ViewOurWorkBlok from './PageSpecific/FrontPage/ViewOurWork/ViewOurWorkBlok';
import PartnersSliderBlok from './PageSpecific/FrontPage/PartnersSlider/PartnersSliderBlok';
import ListSliderBlok from './PageSpecific/FrontPage/ListSlider/ListSliderBlok';

import LetsMakeContactBlok from './Contact/LetsMakeContactBlok';
import StartingProjectBlok from './Contact/StartingProjectBlok';

import WebDesignBlok from './PageSpecific/ServicesPage/WebDesign/WebDesignBlok';
import TwoColumnListSliderBlok from './PageSpecific/ServicesPage/TwoColumnListSlider/TwoColumnListSliderBlok';
import TabletPortfolioBlok from './PageSpecific/ServicesPage/TabletPortfolio/TabletPortfolioBlok';

import OurWorkStrivesBlok from './PageSpecific/AboutUs/OurWorkStrives/OurWorkStrivesBlok';
import SidedTextBlockBlok from './PageSpecific/AboutUs/SidedTextBlock/SidedTextBlockBlok';
import TwoImagesBlockBlok from './PageSpecific/AboutUs/TwoImagesBlock/TwoImagesBlockBlok';
import ParallaxImageBlok from './PageSpecific/AboutUs/ParallaxImage/ParallaxImageBlok';
import MapBlok from './PageSpecific/ContactUs/Map/MapBlok';
import ContactFormBlok from './PageSpecific/ContactUs/ContactForm/ContactFormBlok';

import IndServiceHeroBlok from '~components/PageSpecific/IndividualServices/Hero/HeroBlok';

import IndProjectHeroBlok from './PageSpecific/IndividualProjects/Hero/HeroBlok';
import IndProjectImagesBlok from './PageSpecific/IndividualProjects/ImageModule/ImageModuleBlok';
import IndProjectTwoColumnsBlok from './PageSpecific/IndividualProjects/TwoColumnsText/TwoColumnsTextBlok';
import IndProjectTwoImagesBlok from './PageSpecific/IndividualProjects/TwoImages/TwoImagesBlok';
import IndProjectCTABlok from './PageSpecific/IndividualProjects/CTA/CTABlok';
import IndProjectBannerBlok from './PageSpecific/IndividualProjects/Banner/BannerBlok';
import HowWeExcelledBlok from './PageSpecific/IndividualProjects/HowWeExcelled/HowWeExcelledBlok';
import TeamGridBlok from './PageSpecific/Team/Grid/GridBlok';
import InterestedInWorkingBlok from './PageSpecific/Team/InterestedInUsing/InterestedInUsingBlok';
import TeamTwoColumnsTextBlok from './PageSpecific/Team/TwoColumnsText/TwoColumnsTextBlok';

import ProjectGridBlok from './PageSpecific/Work/ProjectGrid/ProjectGridBlok';
import BlogGridBlok from './PageSpecific/Blog/BlogGrid/BlogGridBlok';
import AestheticsHeroBlok from './PageSpecific/Aesthetics/Hero/HeroBlok';
import MarlowHeroBlok from './PageSpecific/Marlow/Hero/HeroBlok';
import MarlowSliderBlok from './PageSpecific/Marlow/Slider/SliderBlok';
import LetsNumbersTalkBlok from './PageSpecific/Marlow/LetsNumbersTalk/LetsNumbersTalkBlok';
import OurRecentProjectsBlok from './PageSpecific/Marlow/OurRecentProjects/OurRecentProjectsBlok';

const ComponentList = {
  HeroBlok: IndServiceHeroBlok,
  ParallaxImageBlok,
  ProjectGrid: ProjectGridBlok,
  TwoImagesBlock: TwoImagesBlockBlok,

  aesthetics_hero: AestheticsHeroBlok,
  blog_grid: BlogGridBlok,

  contact_form_module: ContactFormBlok,
  contact_lets_make: LetsMakeContactBlok,
  contact_starting_project: StartingProjectBlok,
  hero_front: HeroFrontBlok,
  how_we_excelled: HowWeExcelledBlok,
  individual_project_cta: IndProjectCTABlok,
  individual_project_hero: IndProjectHeroBlok,
  individual_project_images: IndProjectImagesBlok,
  individual_project_two_columns: IndProjectTwoColumnsBlok,
  individual_project_two_images: IndProjectTwoImagesBlok,
  list_slider: ListSliderBlok,
  map_module: MapBlok,
  marlow_hero: MarlowHeroBlok,
  marlow_slider: MarlowSliderBlok,
  our_work_strives_module: OurWorkStrivesBlok,
  parallax_banner: IndProjectBannerBlok,
  partners_slider: PartnersSliderBlok,
  sided_text_block: SidedTextBlockBlok,
  tablet_portfolio: TabletPortfolioBlok,
  team_grid: TeamGridBlok,
  team_interested_in: InterestedInWorkingBlok,
  team_two_columns_text: TeamTwoColumnsTextBlok,
  video_phones: VideoPhonesBlok,
  view_our_work: ViewOurWorkBlok,
  we_are_360: TwoColumnListSliderBlok,
  web_design_module: WebDesignBlok,
  marlow_lets_numbers_talk: LetsNumbersTalkBlok,
  OurRecentProjectsBlok,
};

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
