import React from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image/withIEPolyfill';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

// import SanitizeHTML from '~components/util/SanitizeHTML';
// import Api from '~components/../util/storyblok-api';

// import * as mixins from '~styles/mixins';

const ParallaxContainer = styled.div`
  width: 60vw;
  padding: 100px;
  background-color: #f1f1f1;
  margin-bottom: 120px;

  @media ${({ theme }) => theme.BreakpointDown.md} {
    width: auto;
    padding: initial;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  @media ${({ theme }) => theme.BreakpointDown.md} {
    & + .SidedBlock {
      .imageSide {
        order: 2;
        margin-top: 30px;
      }
    }
  }
`;

const ParallaxImage = (props) => {
  const { blok } = props;

  const imgURL = blok.image?.filename?.replace(/(^\w+:|^)/, '');
  const img = getFluidGatsbyImage(imgURL, {
    maxWidth: 661,
    maxHeight: 451,
  });

  return (
    <ParallaxContainer style={{ overflow: 'hidden' }}>
      <ParallaxProvider>
        <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
          <Img alt={blok.image?.alt} title={blok.image?.title} fluid={img} />
        </Parallax>
      </ParallaxProvider>
    </ParallaxContainer>
  );
};

export default ParallaxImage;
