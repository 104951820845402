import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { useMediaQuery } from 'react-responsive';

// import PhoneComponent from './Phone';
import PhoneComponent from './CSSPhone';

import * as mixins from '../../../../styles/mixins';

const ContainerFluid = styled.div`
  ${mixins.makeContainer()}

  background: ${({ theme }) => theme.colors.grey200};

  max-height:466px;

  height: calc(390px + 5.125rem);

  padding-top:2.625rem;
  padding-bottom:2.625rem;

  margin-top:2rem;
  margin-bottom:2rem;

  overflow:hidden;
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  transform: translateX(-50%);

  @media ${({ theme }) => theme.breakpoint.md} {
    transform: translateX(0);
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}

  flex-wrap: nowrap;
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  width:auto;
`;

const PhoneWrapper = styled(motion.div)`
  display: flex;

  position: relative;

  justify-content: center;
  align-items: center;
`;

const VideoPhones = ({ images }) => {
  const getImageAndShift = () => {
    const image = images.shift();
    images.push(image);
    return image;
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <ContainerFluid>
      <Container>
        <Row>
          <Col>
            <PhoneWrapper
              animate={
                isTabletOrMobile
                  ? {}
                  : {
                      scale: [1.1, 1.4, 1.3, 1.2, 1.1, 1, 0.9, 0.8, 1.1],
                      // scale: [1, 1, 1, 1, 1, 1, 1],
                    }
              }
              transition={{
                ease: 'linear',
                duration: 10,
                loop: Infinity,
              }}
            >
              <PhoneComponent fixedDataCms={getImageAndShift()} />
            </PhoneWrapper>
          </Col>
          <Col>
            <PhoneWrapper
              animate={
                isTabletOrMobile
                  ? {}
                  : {
                      scale: [1, 0.8, 0.75, 0.7, 0.85, 1, 1.2, 1],
                    }
              }
              transition={{
                ease: 'linear',
                duration: 10,
                loop: Infinity,
              }}
            >
              <PhoneComponent fixedDataCms={getImageAndShift()} />
            </PhoneWrapper>
          </Col>
          <Col>
            <Row>
              <Col>
                <PhoneWrapper
                  animate={
                    isTabletOrMobile
                      ? {}
                      : {
                          scale: [1, 0.7, 0.65, 0.6, 0.8, 1, 0.75, 1],
                        }
                  }
                  transition={{
                    ease: 'linear',
                    duration: 8,
                    loop: Infinity,
                  }}
                >
                  <PhoneComponent type="horizontal" fixedDataCms={getImageAndShift()} />
                </PhoneWrapper>
              </Col>
              <Col>
                <PhoneWrapper
                  animate={
                    isTabletOrMobile
                      ? {}
                      : {
                          scale: [1, 1.1, 1.2, 1.1, 1, 0.9, 1.2, 1],
                        }
                  }
                  transition={{
                    ease: 'linear',
                    duration: 8,
                    loop: Infinity,
                  }}
                >
                  <PhoneComponent type="horizontal" fixedDataCms={getImageAndShift()} />
                </PhoneWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <PhoneWrapper
                  style={{ paddingLeft: '2rem', paddingRight: '1rem', paddingTop: '2rem' }}
                  animate={
                    isTabletOrMobile
                      ? {}
                      : {
                          y: [0, 30, 60, 90, 120, 130, 120, 92, 46, 0],
                          scale: [1, 1.15, 1.3, 1.2, 1.1, 1.05, 1.05, 1, 1, 1, 1],
                        }
                  }
                  transition={{
                    ease: 'linear',
                    duration: 10,
                    loop: Infinity,
                  }}
                >
                  <PhoneComponent fixedDataCms={getImageAndShift()} />
                </PhoneWrapper>
              </Col>
              <Col>
                <PhoneWrapper
                  style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '2rem' }}
                  animate={
                    isTabletOrMobile
                      ? {}
                      : {
                          y: [0, 60, 120, 90, 60, 30, 0, 0, 0, 0],
                          scale: [1, 1, 1, 0.9, 0.8, 1, 1, 1, 1, 1, 1],
                        }
                  }
                  transition={{
                    ease: 'linear',
                    duration: 10,
                    loop: Infinity,
                  }}
                >
                  <PhoneComponent fixedDataCms={getImageAndShift()} />
                </PhoneWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ContainerFluid>
  );
};

export default VideoPhones;
