import styled from 'styled-components';
import * as mixins from '~styles/mixins';

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

  ${({ theme, md, xl }) => {
    return `
            @media ${theme.breakpoint.md} {
                ${mixins.makeCol(md ?? 12, 12)}
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
  }}
`;

export default Col;
