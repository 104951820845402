import React, { useContext } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import ContactContext from '~util/context';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';
import ButtonComponent from '~components/Button/Button';
import * as mixins from '~styles/mixins';

const BgContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey100};
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Title = styled(ListSliderTitle)`
  .title-grey {
    display: block;
    @media ${({ theme }) => theme.breakpoint.lg} {
      display: initial;
    }
  }
  border-bottom: 1px solid #e6e2e2;
  margin-bottom: 7.5px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  &.cta-content {
    font-size: 1.5rem;
    letter-spacing: 0.6px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    @media ${({ theme }) => theme.breakpoint.lg} {
      font-size: 1.875rem;
    }
    line-height: 2.9rem;
  }

  & > a.underLineme {
    text-decoration: none;
    position: relative;

    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 1px;
      left: 0;

      background: #232325;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Button = styled(ButtonComponent)`
  color: ${({ theme }) => theme.colors.secondary};

  padding: 4px 16px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
    line-height: 1.5625rem;
  }

  margin-left: 10px;
  margin-right: 10px;
  transform: translateY(1px);
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;

  transform: translateY(-1px);
`;

const CTA = () => {
  const contactContext = useContext(ContactContext);
  return (
    <BgContainer>
      <Container>
        <Row>
          <Col>
            <Title>
              <span className="title-grey">Lets make </span>something together
              <span className="title-pink">.</span>
            </Title>
          </Col>
        </Row>
        <Row>
          <Col className="cta-content">
            You could start by sending us an{` `}
            {/* <Button link={contactContext?.whatsapp} arrow={false}>
              <Icon icon={faWhatsapp} /> WhatsApp us
            </Button>{' '}
            or by sending us an  */}
            email at{' '}
            <a className="underLineme" href={`mailto:${contactContext?.email}`}>
              {contactContext?.email ?? `hello@ccity.co.uk`}
            </a>
            , it’s your choice.
          </Col>
        </Row>
      </Container>
    </BgContainer>
  );
};

export default CTA;
