import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

import SbEditable from '../../../util/SbEditable';
import ImageModule from './ImageModule';

const SidedTextBlockBlok = ({ blok }) => {
  const imgLeftUrl = blok.image_left?.filename?.replace(/(^\w+:|^)/, '');
  const imgLeft = getFluidGatsbyImage(imgLeftUrl, {
    maxWidth: 500,
  });
  const imgRightUrl = blok.image_right?.filename?.replace(/(^\w+:|^)/, '');
  const imgRight = getFluidGatsbyImage(imgRightUrl, {
    maxWidth: 500,
  });

  return (
    <SbEditable content={blok}>
      <ImageModule
        imageLeft={imgLeft}
        colorLeft={blok.color_left?.color}
        imageRight={imgRight}
        colorRight={blok.color_right?.color}
      />
    </SbEditable>
  );
};

export default SidedTextBlockBlok;
