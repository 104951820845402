import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Slider from './Slider';

const SliderBlok = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <Slider mobileFix={blok.mobile_fix} slides={blok.slides} description={blok.description} />
    </SbEditable>
  );
};

export default SliderBlok;
