import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Api from '../../../../util/storyblok-api';

import SanitizeHTML from '../../../util/SanitizeHTML';
import PartnersSlider from './PartnersSlider';
import PartnersSliderItem from './SliderItem/PartnersSliderItem';

const PartnersSliderBlok = (props) => {
  // const { hidesection } = props.blok?.hidesection;
  // console.log(props);
  return (
    <SbEditable content={props.blok}>
      <PartnersSlider
        title={props.blok.title}
        slideAmount={props.blok?.slider_items?.length}
        hide={props.blok?.hide}
        hidesection={props.blok?.hidesection}
      >
        {props.blok.slider_items &&
          props.blok.slider_items.map((blok) => {
            const img = blok.image?.filename;
            return (
              <PartnersSliderItem
                key={blok._uid}
                fluidDataCms={img}
                title={blok.title}
                relatedProject={blok.related_project}
              >
                <SanitizeHTML html={Api.richTextResolver.render(blok.content)} />
              </PartnersSliderItem>
            );
          })}
      </PartnersSlider>
    </SbEditable>
  );
};

export default PartnersSliderBlok;
