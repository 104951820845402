import React from 'react';

import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';

import SEO from '~components/util/seo';

import HeroBlok from '../PageSpecific/IndividualProjects/Hero/HeroBlok';
import ImageModuleBlok from '../PageSpecific/IndividualProjects/ImageModule/ImageModuleBlok';
import TwoColumnsTextBlok from '../PageSpecific/IndividualProjects/TwoColumnsText/TwoColumnsTextBlok';
import CTABlok from '../PageSpecific/IndividualProjects/CTA/CTABlok';
import BannerBlok from '../PageSpecific/IndividualProjects/Banner/BannerBlok';
import HowWeExcelledBlok from '../PageSpecific/IndividualProjects/HowWeExcelled/HowWeExcelledBlok';
import TwoImagesBlok from '../PageSpecific/IndividualProjects/TwoImages/TwoImagesBlok';
import SimilarProjectsBlok from '../PageSpecific/IndividualProjects/SimilarProjects/SimilarProjectsBlok';

const IndividualProject = (props) => {
  const hasImage =
    typeof props.blok.meta_image?.filename !== 'undefined' &&
    props.blok.meta_image?.filename !== null &&
    props.blok.meta_image?.filename !== '';

  const canImageMeta = () => {
    if (
      hasImage === true &&
      (typeof props.blok.og_image === 'undefined' ||
        props.blok.og_image === null ||
        props.blok.og_image === '')
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      <ParallaxProvider>
        <SEO seo={props.blok.seo} defaultImage={props.globalLayout?.og_image_default?.filename} />
        <Helmet>
          <meta name="viewport" content="initial-scale=1, minimum-scale=1, maximum-scale=1" />
          {canImageMeta() === true && (
            <meta itemProp="image" content={props.blok.meta_image?.filename ?? null} />
          )}
          {canImageMeta() === true && (
            <meta property="og:image" content={props.blok.meta_image?.filename ?? null} />
          )}
          {canImageMeta() === true && (
            <meta name="twitter:image" content={props.blok.meta_image?.filename ?? null} />
          )}
        </Helmet>
        {props.blok.hero?.length > 0 && (
          <HeroBlok blok={props.blok.hero[0]} tags={props.blok.meta_category} />
        )}
        {props.blok.image_module?.length > 0 && (
          <ImageModuleBlok blok={props.blok.image_module[0]} />
        )}
        {props.blok.two_columns?.length > 0 && (
          <TwoColumnsTextBlok blok={props.blok.two_columns[0]} />
        )}
        {props.blok.cta?.length > 0 && <CTABlok blok={props.blok.cta[0]} />}
        {props.blok.banner?.length > 0 && <BannerBlok blok={props.blok.banner[0]} />}
        {props.blok.how_we_excelled?.length > 0 && (
          <HowWeExcelledBlok blok={props.blok.how_we_excelled[0]} />
        )}
        {props.blok.two_images?.length > 0 && <TwoImagesBlok blok={props.blok.two_images[0]} />}
        {props.blok.similar_projects?.length > 0 && (
          <SimilarProjectsBlok blok={props.blok.similar_projects} />
        )}
      </ParallaxProvider>
    </>
  );
};

export default IndividualProject;
