/* eslint-disable no-undef */
import React from 'react';
// @ts-nocheck
import { Helmet } from 'react-helmet';

import SEO from '~components/util/seo';

import Components from '../components';

const Page = (props) => {
  return (
    <>
      <SEO seo={props.blok.seo} defaultImage={props.globalLayout?.og_image_default?.filename} />
      <Helmet>
        <script src="//scripts.iconnode.com/63770.js" />
        <script src="/load.js" type="text/javascript" />
        <meta name="viewport" content="initial-scale=1, minimum-scale=1, maximum-scale=1" />
      </Helmet>
      {props.blok.body &&
        props.blok.body.map((blok) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok,
          }),
        )}
    </>
  );
};

export default Page;
