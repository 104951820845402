import React, { useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import Slider from 'react-slick';
import * as mixins from '../../../../styles/mixins';
import PartnersSliderNext from './PartnersSliderNext';

import 'slick-carousel/slick/slick.css';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
    ${mixins.makeContainer()}
    ${mixins.makeContainerMaxWidths()}

    position:relative;

    transform: translateX(-20px);
    overflow: visible;

    .slick-list {
        overflow: visible;
    }

    @media ${({ theme }) => {
      return `(max-width:${parseInt(theme.breakpointRaw.md, 10) - 0.1}px) {`;
    }}
        .slick-slide {
            transition: opacity 0.5s ease-in-out;
        }
        .slick-slide:not(.slick-active) {
            opacity:0.5;
        }

    }

    padding-top:2rem;
    padding-bottom:3rem;

    @media ${({ theme }) => theme.breakpoint.sm} {
        transform: translateX(0);
        overflow:hidden;

        .slick-list {
            overflow: hidden;
        }
    }

`;

const Col = styled.div`
  ${mixins.makeColReady}
  ${mixins.makeCol(1, 1)}

    display:float;
  justify-content: flex-end;

  position: static;
  @media ${({ theme }) => theme.breakpoint.md} {
    position: relative;
  }
`;

const ButtonPos = styled.div`
  z-index: 999;

  position: absolute;
  bottom: 0;

  right: 0;

  @media ${({ theme }) => theme.breakpoint.sm} {
    right: 12px;
    padding-top: 20px;
    position: static;
  }
`;

const Title = styled.div`
    ${mixins.makeCol(1, 1)}
    ${mixins.makeColReady}

    @media ${({ theme }) => {
      return `(max-width:${parseInt(theme.breakpointRaw.sm, 10) - 0.1}px) {`;
    }}
        transform: translateX(20px);
    }

    h2 {
        color: ${({ theme }) => theme.colors.primary};
        font-size: ${({ theme }) => theme.font.typography.h1.size.xs};
        line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.xs};
    }

    @media ${({ theme }) => theme.breakpoint.sm} {
        h2 {
            font-size: ${({ theme }) => theme.font.typography.h1.size.md};
            line-height: ${({ theme }) => theme.font.typography.h1.lineHeight.md};  
        }
    }
    h2:after {
      content: '.';
      color: ${({ theme }) => theme.colors.secondary};
    }
`;

const Row = styled.div`
  ${mixins.makeRow()}

  display:block;
`;

const PartnersSlider = ({ title, slideAmount, children, hide, hidesection }) => {
  const themeContext = useContext(ThemeContext);

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    arrows: false,
    swipe: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    easing: 'ease-in-out',
    responsive: [
      {
        breakpoint: parseInt(themeContext.breakpointRaw.lg, 10) - 0.1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: parseInt(themeContext.breakpointRaw.sm, 10) - 0.1,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <div>
      {!hidesection && (
        <Wrapper>
          <Container>
            <Row>
              <Title>
                <h2>{title || 'Featured partners'}</h2>
              </Title>
            </Row>
            <Row>
              <Slider ref={sliderRef} {...settings}>
                {children}
              </Slider>
            </Row>
            {slideAmount > 3 && (
              <Row>
                <Col>
                  <ButtonPos>
                    {!hide && (
                      <PartnersSliderNext
                        onClick={() => {
                          sliderRef.current.slickNext();
                        }}
                      />
                    )}
                  </ButtonPos>
                </Col>
              </Row>
            )}
          </Container>
        </Wrapper>
      )}
    </div>
  );
};

export default PartnersSlider;
