import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

import Button from '../../../../Button/Button';

const Overlay = styled.div`
  position: absolute;
  top: 100%;

  transition: 0.3s ease-in-out transform;

  background: ${({ theme }) => theme.colors.grey100rgba};

  font-size: 1.5rem;
  line-height: ${({ theme }) => theme.font.typography.h3.lineHeight.md};

  min-width: 400px;
  max-width: 90%;
  padding: 20px;

  p {
    margin-top: initial;
    margin-bottom: initial;

    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding: 60px;
    font-size: 2rem;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OverlayText = styled.div`
  padding-bottom: 23px;
`;

const ImageTileWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    &:hover ${Overlay} {
      transform: translateY(-100%);
    }
  }
`;

const ImageTile = ({ children, button, fluidDataCms, overlay }) => {
  const defaultImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "placeholder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  let fluidData = fluidDataCms;
  if (typeof fluidData === 'undefined' || fluidData == null) {
    fluidData = defaultImage.file.childImageSharp.fluid;
  }

  return (
    <ImageTileWrapper>
      <Img
        alt="We are 360, Image"
        title="Image of we are 360 module"
        fluid={fluidData}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      {overlay && (
        <Overlay>
          <OverlayText>{children}</OverlayText>
          {button.url.cached_url && (
            <Button variant="secondary" link={button.url.cached_url}>
              {button.label}
            </Button>
          )}
        </Overlay>
      )}
    </ImageTileWrapper>
  );
};

ImageTile.defaultProps = {
  button: {
    url: '#',
    label: 'View this case study',
  },
};

ImageTile.propTypes = {
  // button: PropTypes.shape({
  //   url: PropTypes.string,
  //   label: PropTypes.string,
  // }),
};

export default ImageTile;
