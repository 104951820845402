import React from 'react';
import styled from 'styled-components';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 80px;
  padding-bottom: 80px;

  font-size: 3rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.grey600};
`;

const EmptyScreen = () => {
  return <Container>Something went wrong. :/</Container>;
};

export default EmptyScreen;
