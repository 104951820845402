export const checkTransitionsSupport = () => {
  const b = document.body || document.documentElement;
  const s = b.style;
  let p = 'transition';

  if (typeof s[p] === 'string') {
    return true;
  }

  // Tests for vendor specific prop
  const v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
  p = p.charAt(0).toUpperCase() + p.substr(1);

  for (let i = 0; i < v.length; i += 1) {
    if (typeof s[v[i] + p] === 'string') {
      return true;
    }
  }

  return false;
};

export const randomID = () => {
  const id = Math.random().toString(36).substr(2, 9);
  if (typeof document !== 'undefined') {
    if (document.getElementById(id)) {
      return randomID();
    }
  }

  return id;
};
