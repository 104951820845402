import React from 'react';
// import SbEditable from '../../../util/SbEditable';
import ParallaxImage from './ParallaxImage';

export const mockData = {};

const SidedTextBlockBlok = (props) => {
  let { blok } = props;
  if (typeof blok === 'undefined') {
    blok = mockData;
  }
  return (
    <ParallaxImage blok={blok} />
    // <SbEditable content={blok}>
    // </SbEditable>
  );
};

export default SidedTextBlockBlok;
