import React, { useState } from 'react';

import CForm from './CompactForm';

export const fieldsMockData = {
  name: {
    key: 'name',
    label: 'First tell us about you',
    default: '',
    placeholder: 'Enter your name',
  },
  surname: {
    type: 'email',
    key: 'surname',
    label: 'Lorem ipsum dolor sit',
    default: '',
  },
  jobname: {
    key: 'jobname',
    label: 'dolore sint eum dolores esse, amet repellat?',
    default: '',
  },
  hobby: {
    key: 'hobby',
    label: 'Sapiente iure dolores aliquid',
    default: 'test',
  },
};

const CompactForm = ({ fieldsData, type, onSubmitHandler }) => {
  const [formState] = useState({
    current: -1,
    values: {},
    formSubmitted: false,
  });

  return (
    <div style={{ width: '100%' }}>
      {!formState.formSubmitted && (
        <CForm onSubmitHandler={onSubmitHandler} fields={fieldsData} type={type} />
      )}

      {formState.formSubmitted && (
        <div className="submit-message">Thank you for your response!</div>
      )}
    </div>
  );
};

export default CompactForm;
