import React from 'react';

import StartingProject from './StartingProject';
import SbEditable from '../util/SbEditable';

const StartingProjectBlok = (props) => {
  return (
    <SbEditable content={props.blok}>
      <StartingProject />
    </SbEditable>
  );
};

export default StartingProjectBlok;
