import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Parallax } from 'react-scroll-parallax';

const Wrapper = styled.div`
  width: 100%;
  height: 45vw;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 30vw;
  }

  overflow: hidden;
`;

const ParallaxStyled = styled(Parallax)`
  width: 100%;
`;

const Banner = ({ image }) => {
  return (
    <Wrapper>
      <ParallaxStyled y={[-40, 0]}>
        {image && (
          <Img alt="A banner of the project" title="A banner of the project" fluid={image} />
        )}
      </ParallaxStyled>
    </Wrapper>
  );
};

export default Banner;
