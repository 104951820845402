import React from 'react';
import styled from 'styled-components';
import SanitizeHTML from '~components/util/SanitizeHTML';
import ListSliderTitle from '~components/PageSpecific/FrontPage/ListSlider/ListSliderTitle';

import * as mixins from '~styles/mixins';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

padding-top:50px;
  padding-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Col = styled.div`
   ${mixins.makeColReady()} 
   ${mixins.makeCol()}
   
   @media ${({ theme }) => theme.breakpoint.lg} {
       ${({ fw }) => (fw ? mixins.makeCol() : mixins.makeCol(1, 2))}
   }
`;

const ContentCol = styled(Col)`
  /* line-height: 1.375rem; */
  @media ${({ theme }) => theme.breakpoint.md} {
    letter-spacing: 0.8px;
  }

  & + & {
    padding-top: 2rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      padding-top: 0;
    }
  }
  &.col-right {
    h2 {
      text-transform: uppercase;
    }
    a {
      text-decoration: none;
    }
    .pink {
      display: block;
      padding-top: 1rem;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
    @media ${({ theme }) => theme.breakpoint.lg} {
      ${mixins.makeCol(5, 12)}
      margin-left: 8.33333%;
    }
  }

  &.col-left {
    @media ${({ theme }) => theme.breakpoint.lg} {
      ${mixins.makeCol(6, 12)}
    }
  }
`;

const Title = styled(ListSliderTitle)`
  h2 {
    margin-top: 0;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

export const testData = {
  columnLeft: `We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.`,
  columnRight: `We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.
  We deliver impactful web designs, digital strategies, engaging social content, effective seo and so much more for all your digital needs.`,
};

const TwoColumnsText = ({ columnLeft, columnRight }) => {
  return (
    <Container>
      <Row>
        <ContentCol className="col-left">
          <Title>
            <SanitizeHTML html={columnLeft} />
          </Title>
        </ContentCol>
        <ContentCol className="col-right">
          <SanitizeHTML html={columnRight} />
        </ContentCol>
      </Row>
    </Container>
  );
};

export default TwoColumnsText;
