/* eslint-disable no-bitwise */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { LightenDarkenColor } from '../../../../util/util';

// const deviceSilver = "#e2e3e4";
// const deviceSilverDark = "#caccce";
// const deviceSilverPanel = "#222";
const deviceSilver = '#535150';
// const deviceSilver = '#559955';
const deviceSilverDark = LightenDarkenColor(deviceSilver, -10);
const deviceSilverPanel = '#222';

const Device = styled.div`
  height: 868px;
  width: 428px;
  transition: 0.5s ease-in-out transform;
  position: relative;
  transform-origin: top right;

  .device-frame {
    background: ${deviceSilverPanel};
    border-radius: 68px;
    box-shadow: inset 0 0 2px 2px ${deviceSilverDark}, inset 0 0 0 7px ${deviceSilver};
    ${({ deviceColor, deviceDarkColor }) => {
      return `
        box-shadow: inset 0 0 2px 2px ${deviceDarkColor}, inset 0 0 0 7px ${deviceColor};
      `;
    }}
    height: 868px;
    padding: 28px;
    width: 428px;
  }

  // 2436-by-1125-pixel resolution
  .device-content {
    border-radius: 40px;
    height: 812px;
    width: 375px;
    overflow: hidden;

    box-shadow: 0 0px 2.8px -17px rgba(0, 0, 0, 0.171), 0 0px 6.8px -17px rgba(0, 0, 0, 0.246),
      0 0px 12.8px -17px rgba(0, 0, 0, 0.305), 0 0px 22.8px -17px rgba(0, 0, 0, 0.364),
      0 0px 42.6px -17px rgba(0, 0, 0, 0.439), 0 0px 102px -17px rgba(0, 0, 0, 0.61);

    .device-content--image {
      width: 100%;
      height: 100%;
    }
  }

  .device-stripe {
    &::after,
    &::before {
      border: solid rgba(#333, 0.25);
      border-width: 0 7px;
      content: '';
      height: 7px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
    }
    &::after {
      top: 85px;
    }
    &::before {
      bottom: 85px;
    }
  }

  .device-header {
    background: ${deviceSilverPanel};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 30px;
    left: 50%;
    margin-left: -102px;
    position: absolute;
    top: 28px;
    width: 204px;

    &::after,
    &::before {
      content: '';
      height: 10px;
      position: absolute;
      top: 0;
      width: 10px;
    }
    &::after {
      background: radial-gradient(
        circle at bottom left,
        transparent 0,
        transparent 75%,
        ${deviceSilverPanel} 75%,
        ${deviceSilverPanel} 100%
      );
      left: -10px;
    }
    &::before {
      background: radial-gradient(
        circle at bottom right,
        transparent 0,
        transparent 75%,
        ${deviceSilverPanel} 75%,
        ${deviceSilverPanel} 100%
      );
      right: -10px;
    }
  }

  .device-sensors {
    &::after,
    &::before {
      content: '';
      position: absolute;
    }
    &::after {
      background: #444;
      border-radius: 3px;
      height: 6px;
      left: 50%;
      margin-left: -25px;
      top: 32px;
      width: 50px;
    }
    &::before {
      background: #444;
      border-radius: 50%;
      height: 14px;
      left: 50%;
      margin-left: 40px;
      top: 28px;
      width: 14px;
    }
  }

  .device-btns {
    background: ${deviceSilverDark};
    ${({ deviceDarkColor }) => `background: ${deviceDarkColor};`}
    height: 32px;
    left: -3px;
    position: absolute;
    top: 115px;
    width: 3px;

    &::after,
    &::before {
      background: ${deviceSilverDark};
      ${({ deviceDarkColor }) => `background: ${deviceDarkColor};`}
      content: '';
      height: 62px;
      left: 0;
      position: absolute;
      width: 3px;
    }

    &::after {
      top: 60px;
    }
    &::before {
      top: 140px;
    }
  }

  .device-power {
    background: ${deviceSilverDark};
    ${({ deviceDarkColor }) => `background: ${deviceDarkColor};`}
    height: 100px;
    right: -3px;
    position: absolute;
    top: 200px;
    width: 3px;
  }
`;

const DeviceWrapper = styled.div`
  width: 192.6px;
  height: 390px;
  ${({ type }) => {
    if (type === 'horizontal') {
      return `
                width:390px;
                height:192.6px;

                ${Device} {
                    transform: rotate(-90deg) translateY(-50%);
                }
            `;
    }
    return null;
  }}
`;

const Container = styled.div`
  transform: scale(0.45);
  transform-origin: top left;
`;

const Phone = ({ color, type, fixedDataCms }) => {
  const rgbaToHex = (rgba) => {
    const regEx = /^#(?:[0-9a-f]{3}){1,2}$/i;
    if (regEx.test(rgba)) {
      return rgba;
    }
    const rgb = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);

    const hex = rgb
      ? `#${(rgb[1] | (1 << 8)).toString(16).slice(1)}${(rgb[2] | (1 << 8))
          .toString(16)
          .slice(1)}${(rgb[3] | (1 << 8)).toString(16).slice(1)}`
      : rgba;
    return hex;
  };

  const defaultImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "iphoness.png" }) {
        childImageSharp {
          fixed(width: 230, height: 500) {
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `);

  const deviceColor = rgbaToHex(color);
  const deviceDarkColor = LightenDarkenColor(deviceColor, -10);

  let fixedData = fixedDataCms;
  if (typeof fixedData === 'undefined' || fixedData == null) {
    fixedData = defaultImage.file.childImageSharp.fixed;
  }
  return (
    <DeviceWrapper type={type}>
      <Container>
        <Device deviceColor={deviceColor} deviceDarkColor={deviceDarkColor}>
          <div className="device-frame">
            <div className="device-content">
              <Img
                alt="Phones animation, One of 6 phones"
                title="Phones animation, one of 6 phones"
                className="device-content--image"
                fixed={fixedData}
                style={{ width: '100%', height: '100%' }}
                objectFit="cover"
              />
            </div>
          </div>
          <div className="device-stripe" />
          <div className="device-header" />
          <div className="device-sensors" />
          <div className="device-btns" />
          <div className="device-power" />
          <div className="device-home" />
        </Device>
      </Container>
    </DeviceWrapper>
  );
};

Phone.defaultProps = {
  color: '#535150',
  type: 'vertical',
};

Phone.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['vertical', 'horizontal']),
};

export default Phone;
