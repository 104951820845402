import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Slider from 'react-slick';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import Img from 'gatsby-image';

import { randomID } from '~components/MinimalForm/util';

import * as mixins from '~styles/mixins';

import Button from '~components/Button/Button';
import NumberComponent from './Number';
import Arrow from '~components/util/arrow';

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  
  padding-top: 3rem;
  padding-bottom: 2rem;
  @media ${({ theme }) => theme.breakpoint.sm} {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const ContentCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(12, 12)}
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(5, 12)}
  }
`;

const DesktopSpaceCol = styled.div`
  ${mixins.makeColReady()}
  display: none;
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(1, 12)}
    display: block;
  }
`;
const NumbersCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(12, 12)}
  padding-top: 10px;
  padding-bottom: 30px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    ${mixins.makeCol(6, 12)}
    padding-top: 0px;
    padding-bottom: 0px;
  }

  position: relative;
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 37px;
  padding-bottom: 20px;
  letter-spacing: 0.64;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 50px;
    line-height: 60px;
    width: 80%;
    letter-spacing: 1;
  }

  .title-pink {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledSlider = styled(Slider)`
  .slick-track {
  }
`;

const RightButton = styled.div`
  width: 52px;
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  position: absolute;
  right: 1%;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);

  background: ${({ theme }) => theme.colors.secondary};
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: none;
  }
`;

const Image = styled(Img)``;

const Copy = styled.div`
  font-size: 16px;
  line-height: 27px;
  padding-bottom: 20px;
  letter-spacing: 0.8;
`;
const ButtonWrapper = styled.div`
  padding-bottom: 30px;
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
`;

const LetsNumbersTalk = ({ title, copy, button, image, numbers }) => {
  const sliderRef = useRef(null);
  const themeContext = useContext(ThemeContext);
  const imageRaw = image?.filename?.replace(/(^\w+:|^)/, '');
  const imageFluid = getFluidGatsbyImage(imageRaw, {
    maxWidth: 600,
  });
  const [isMobile, setMobile] = useState(true);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    arrows: false,
    swipe: true,
    slidesToShow: 1,
    centerMode: 1,
    centerPadding: 10,
    slidesToScroll: 1,
    easing: 'ease-in-out',
  };

  const AddSliderForMobile = ({ children }) => {
    if (isMobile) {
      return (
        <StyledSlider ref={sliderRef} style={{ width: '100%' }} {...settings}>
          {children}
        </StyledSlider>
      );
    }
    return children;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const setMobileIfNeeded = () => {
        if (window.innerWidth < parseInt(themeContext.breakpointRaw.lg, 10) - 0.1) {
          setMobile(true);
        } else {
          setMobile(false);
        }
        return null;
      };
      setMobileIfNeeded();
      window.addEventListener('resize', setMobileIfNeeded);
      return () => {
        window.removeEventListener('resize', setMobileIfNeeded);
      };
    }
    return null;
  }, []);

  const NextSlide = () => {
    if (!isMobile) {
      return null;
    }

    return sliderRef?.current?.slickNext();
  };

  return (
    <Container>
      <Row>
        <ContentCol>
          <Title>
            {title}
            <span className="title-pink">.</span>
          </Title>
          <Copy>{copy}</Copy>
          <ButtonWrapper>
            <Button variant="secondary" link={button[0].url?.cached_url}>
              {button[0].label}
            </Button>
          </ButtonWrapper>
          {imageFluid && !isMobile && (
            <Image alt={image?.alt} title={image?.title} fluid={imageFluid} />
          )}
        </ContentCol>
        <DesktopSpaceCol />
        <NumbersCol>
          <AddSliderForMobile>
            {numbers.map((it) => {
              return <NumberComponent key={randomID()} blok={it} />;
            })}
          </AddSliderForMobile>
          <RightButton onClick={NextSlide}>
            <Arrow direction="right" size={42} />
          </RightButton>
        </NumbersCol>
        {imageFluid && isMobile && (
          <Col>
            <Image alt={image?.alt} title={image?.title} fluid={imageFluid} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

LetsNumbersTalk.defaultProps = {
  title: `Lets talk numbers.`,
  copy: `No not your mobile number, your stats.  Do you want your numbers to look as good as these? Then lets start your digital plan.`,
  button: [
    {
      label: `Let's chat`,
      url: {
        cached_url: '/',
      },
    },
  ],
  numbers: [
    {
      number: '88',
      unit: 'Unit',
      copy: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Shoppers',
                type: 'text',
              },
            ],
          },
        ],
      },
    },
  ],
  image: {
    filename: '',
    alt: '',
    title: '',
  },
};

LetsNumbersTalk.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  button: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.oneOfType([
        PropTypes.shape({
          cached_url: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    }),
  ),
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            content: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string,
                type: PropTypes.string,
              }),
            ),
          }),
        ),
        type: PropTypes.string,
      }),
      number: PropTypes.array,
      unit: PropTypes.object,
    }),
  ),
  image: PropTypes.shape({
    filename: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default LetsNumbersTalk;
