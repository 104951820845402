import React, { useRef } from 'react';
import styled from 'styled-components';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';

import Slick from './Slick';
import Slide from './Slide';
import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';

import * as mixins from '~styles/mixins';
import { randomID } from '~components/MinimalForm/util';
import AnchorButton from '~components/Button/AnchorButton';

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const FluidContainer = styled.div`
  ${mixins.makeContainer()}

  padding-left: 0;
  padding-right: 0;

  position: relative;
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const AbsolutRow = styled(Row)`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
`;

const BgCol = styled.div`
  ${mixins.makeColReady()}
  width: 50%;
  height: 100%;

  @media ${({ theme }) => theme.breakpoint.md} {
    background: ${({ theme }) => theme.colors.grey300};
  }
`;

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 1)}

  &.left {
    padding-left: 0;
    padding-right: 0;
    @media ${({ theme }) => theme.breakpoint.sm} {
      padding-left: ${({ theme }) => theme.gutterSize.xs / 2}px;
      padding-right: ${({ theme }) => theme.gutterSize.xs / 2}px;
    }
  }

  &.content {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    &.left {
      padding-left: ${({ theme }) => theme.gutterSize.md / 2}px;
      padding-right: ${({ theme }) => theme.gutterSize.md / 2}px;
      ${mixins.makeCol(2, 3)};
    }
    ${mixins.makeCol(1, 3)}
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey300};
`;

const DotsWrapper = styled.ul`
  display: flex;
  list-style: none;

  position: absolute;
  bottom: 0;

  padding-left: 20px;
  height: 35px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-left: 0px;
    height: 55px;
  }
  margin-top: 0;
  margin-bottom: 0;

  li {
    display: flex;
    align-items: center;
  }

  li > button {
    border: 0;
    text-indent: -99999px;
    background: #b9b0cf;
    width: 25px;
    height: 4px;
  }
  li + li {
    margin-left: 7px;
  }
  li.slick-active > button {
    background: #140439;
  }
`;

const Spacing = styled.div`
  height: 70px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: none;
  }
`;
const Slider = ({ slides, description, mobileFix }) => {
  const ref = useRef(null);
  const sliderConfig = {
    dots: true,
    arrows: false,
    infinite: false,
    appendDots: (dots) => <DotsWrapper>{dots}</DotsWrapper>,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const printSlides = () => {
    return slides.map((slide) => {
      const imgURL = slide.image?.filename?.replace(/(^\w+:|^)/, '');
      const img = getFluidGatsbyImage(imgURL, {
        maxWidth: 760,
        maxHeight: 578,
      });

      return (
        <Slide
          mobileFix={mobileFix}
          key={randomID()}
          fluid={img}
          heading={slide.heading}
          description={slide.description}
        />
      );
    });
  };

  printSlides();

  return (
    <Wrapper ref={ref}>
      <FluidContainer>
        <AbsolutRow>
          <BgCol />
        </AbsolutRow>
        <Container>
          <Row>
            <Col className="left">
              <SliderWrapper>
                <Slick {...sliderConfig}>{printSlides()}</Slick>
              </SliderWrapper>
            </Col>
            <Col className="content">
              {mobileFix && <Spacing />}
              <SanitizeHTML html={Api.richTextResolver.render(description)} />
              <AnchorButton parentRef={ref} />
            </Col>
          </Row>
        </Container>
      </FluidContainer>
    </Wrapper>
  );
};

export default Slider;
