/* eslint-disable camelcase */
import React from 'react';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import styled from 'styled-components';
import Img from 'gatsby-image';

import * as mixins from '~styles/mixins';

const Wrapper = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

  display:flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
  @media ${({ theme }) => theme.breakpoint.sm} {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.17);
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
  }
`;

const InfoWrapper = styled.div``;

const Heading = styled.h4`
  color: ${({ theme }) => theme.colors.primary};

  b {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  span {
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  margin-bottom: 0;
`;

const Categories = styled.div`
  color: #000;
  font-size: 0.9375rem;
  opacity: 0.64;
`;

const Project = ({ project }) => {
  const imageRaw = project?.content?.meta_image?.filename?.replace(/(^\w+:|^)/, '');
  const image = getFluidGatsbyImage(imageRaw, {
    maxWidth: 500,
    maxHeight: 312,
  });
  return (
    <Wrapper>
      <Container>
        <ImageWrapper>
          {image && (
            <Img
              alt={project?.content?.meta_image?.alt}
              title={project?.content?.meta_image?.title}
              fluid={image}
            />
          )}
        </ImageWrapper>
        <InfoWrapper>
          <Heading>
            <b>Project</b>&nbsp;
            <span>{project?.content?.meta_title}</span>
          </Heading>
          <Categories>{project?.content?.meta_category?.join(' / ')}</Categories>
        </InfoWrapper>
      </Container>
    </Wrapper>
  );
};

export default Project;
