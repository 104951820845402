import React from 'react';
import styled from 'styled-components';
import * as mixins from '~styles/mixins';
import SanitizeHTML from '~components/util/SanitizeHTML';

import AnimatedNumber from './AnimatedNumber';

const Wrapper = styled.div`
  ${mixins.makeRow()}

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey100};
  }
`;
const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 2)}

  display:flex;
  align-items: center;
  justify-content: flex-left;
`;

const ContentCol = styled(Col)`
  p {
    @media ${({ theme }) => theme.breakpoint.xl} {
      font-size: 1.25rem;
    }
  }
`;

const NumberRow = ({ numberType, numberRight, numberContent, numberChar, children }) => {
  return (
    <Wrapper>
      <Col>
        <AnimatedNumber
          type={parseInt(numberType, 10) ?? 0}
          number={numberContent ?? 20}
          char={numberChar ?? '%'}
          moveBgRight={numberRight ?? false}
        />
      </Col>
      <ContentCol>
        <SanitizeHTML html={children ?? `More <br />website users`} />
      </ContentCol>
    </Wrapper>
  );
};

export default NumberRow;
