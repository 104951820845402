import React from 'react';
import SbEditable from '../../../util/SbEditable';
import Hero from './Hero';
import Api from '../../../../util/storyblok-api';

const HeroBlok = ({ blok, tags }) => {
  return (
    <SbEditable content={blok}>
      <Hero
        title={Api.richTextResolver.render(blok.title)}
        tag={blok.tag ?? 'h1'}
        tags={tags}
        content={Api.richTextResolver.render(blok.content)}
      />
    </SbEditable>
  );
};

export default HeroBlok;
