import React from 'react';
import styled from 'styled-components';
import * as mixins from '~styles/mixins';
import SanitizeHTML from '~components/util/SanitizeHTML';

import AnimatedNumber from './AnimatedNumber';

const Wrapper = styled.div`
  ${mixins.makeRow()}

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey100};
  }
`;
const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 2)}

  display:flex;
  align-items: center;
  justify-content: flex-left;
`;

const NumberRow = ({ numberContent }) => {
  return (
    <Wrapper>
      <Col>
        <AnimatedNumber type={2} number={numberContent ?? 20} char="" moveBgRight />
      </Col>
    </Wrapper>
  );
};

export default NumberRow;
