import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

const AlteredDiv = styled.div`
  display: flex;
  position: relative;
  margin-left: 28px;
  vertical-align: middle;
  align-items: center;
`;

const Image = styled(Img)`
  width: 18px;
  height: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  transform: translateY(0px);

  img {
    vertical-align: middle;
  }
`;
const FilterIcon = () => {
  const imageData = useStaticQuery(graphql`
    query icon {
      file(relativePath: { eq: "filter.png" }) {
        childImageSharp {
          fixed(width: 18, height: 15) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <AlteredDiv>
      <Image alt="Filter Icon" title="Filter icon" fixed={imageData.file.childImageSharp.fixed} />
    </AlteredDiv>
  );
};

export default FilterIcon;
