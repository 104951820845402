import React from 'react';
import styled from 'styled-components';

import * as mixins from '~styles/mixins';

const AddressWrapper = styled.div``;

const AddressRow = styled.div`
  ${mixins.makeRow()}
`;

const AddressCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(1, 2)}

  padding-bottom:25px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-bottom: 0;
  }
`;

const Title = styled.h4`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  margin-top: 10px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  font-size: 16px;
  line-height: 22px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
    line-height: 25px;
  }
`;

const Address = ({ addressHtml, contactHtml }) => {
  return (
    <AddressWrapper>
      <AddressRow>
        <AddressCol>
          <Title>Address</Title>
          <ContentWrapper>{addressHtml}</ContentWrapper>
        </AddressCol>
        <AddressCol>
          <Title>Contact</Title>
          <ContentWrapper>{contactHtml}</ContentWrapper>
        </AddressCol>
      </AddressRow>
    </AddressWrapper>
  );
};

export default Address;
