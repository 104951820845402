import React from 'react';
import SbEditable from '../../../util/SbEditable';
import OurWorkStrives from './OurWorkStrives';

export const mockData = {
  heading: 'Our work strives to bring purpose',
  description: `<h2 class="subheadingDesc">WORK THAT HAS MEANING, ADDS VALUE AND PROMPTS ACTION.</h2>
    Whether you’re looking for more website conversions, higher click-through rates on Google ads, or an increase in brand awareness across your social media platforms; it’s our mission to maximise your efforts, make your budgets work harder and constantly strive to deliver maximum ROI.`,
  _uid: '1',
  label: 'Website Design',
  image: {
    filename: '//a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg',
  },
};
const OurWorkStrivesBlok = (props) => {
  let { blok } = props;
  if (typeof blok === 'undefined') {
    blok = mockData;
  }
  return (
    <SbEditable content={blok}>
      <OurWorkStrives blok={blok} />
    </SbEditable>
  );
};

export default OurWorkStrivesBlok;
