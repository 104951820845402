/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import ProjectGrid from '~components/PageSpecific/Work/ProjectGrid/ProjectGrid';
import ProjectItem from '~components/PageSpecific/Work/ProjectGrid/ProjectItem';
import FilterIcon from '~components/PageSpecific/Work/ProjectGrid/filterIcon';
import ArrowIcon from '~components/PageSpecific/Work/ProjectGrid/arrowIcon';
import NextArrow from '~components/PageSpecific/Work/ProjectGrid/nextArrow';
import * as mixins from '~styles/mixins';
import Button from '~components/Button/Button';
import 'slick-carousel/slick/slick.css';
import { randomID } from '~components/MinimalForm/util';

import DrawerContext from '~contexts/DrawerContext';

const ArrowedIcon = styled(ArrowIcon)``;

const FilterInstance = styled.li`
  position: relative;
`;
const FilterBn = styled.button`
  position: relative;
  display: flex;
  align-self: flex-end;
  height: 40px;
  background: ${({ theme }) => theme.colors.secondary};
  color: white;
  border: 0;
  border-radius: 25px;
  font-size: 16px;
  padding: 10px 25px;
  font-weight: bold;
  /* transition: border-radius 0.1s; */
  display: flex;
  align-items: center;
  min-width: 200px;
  span {
    flex: 1;
    text-align: left;
  }
  transition: border-radius 0.2s;
  transition-delay: 0.1s;
  &:hover {
    cursor: pointer;

    transition: border-radius 0.2s;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  ul li:last-child {
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  ${ArrowedIcon}:not(.alwayson) {
    display: none !important;
  }
  ul ${FilterInstance}:hover {
    font-weight: bold;

    ${ArrowedIcon} {
      display: inline-block !important;
    }
  }
  ul li {
    font-family: 'Avenir Next';
    font-weight: 500;
    text-align: left;
    padding-left: 25px;
    background-color: ${({ theme }) => theme.colors.secondary};
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ul {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 0px;
    /* display: block; */
    width: 100%;
    list-style-type: none;
    padding: 0;
    vertical-align: top;
    /* background-color: ${({ theme }) => theme.colors.secondary}; */
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.1s ease;
  }

  &:hover ul {
    transform: scaleY(1);
    transition: transform 0.3s ease;
    transition-delay: 0.1s;
  }
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    width: 95%;
    margin: 0 auto;
    margin-top: 40px;
    z-index: 999;
  }
`;

const HeroBanner = styled.h1`
  position: relative;
  z-index: 9999;
  font-weight: bold;
  color: #232324;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 1px;
  font-weight: bold;
  /* margin-top: 0px; */
  margin-top: 0;

  .subHeroGrey {
    color: #abaaad;
  }
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    width: 100%;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
  }
`;

const Bar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media ${({ theme }) => theme.BreakpointDown.xl} {
    flex-direction: column;
  }
`;

const SubHeadingWrapper = styled.div`
  flex: 1;
  p {
    font-size: 16px;
    font-weight: 500;
    color: #232324;
    line-height: 27px;
    letter-spacing: 0.8px;
    width: 60%;
    margin-bottom: 0;
    @media ${({ theme }) => theme.BreakpointDown.xl} {
      width: 95%;
    }
  }
`;
const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpoint.md} {
    ${mixins.makeRow}
    overflow: visible;
  }
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow: hidden;
  min-width: 100%;
  width: 0;

  .slick-slide {
    height: auto !important;
  }
`;
// const person = { firstName: 'John', lastName: 'Doe', age: 50, eyeColor: 'blue' };
const InsideWrapper = styled.div`
  display: block;
  /* flex-direction: column; */
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
`;

const ItemWrapper = styled.div`
  display: none;
  width: 100%;

  ${InsideWrapper} {
    background: #f5f5f5;
    padding: 80px 36px;
  }

  &.add {
    @media ${({ theme }) => theme.BreakpointDown.xl} {
      display: block;
    }
    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 38px;
    }
  }
  margin-top: 30px;
`;
const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${mixins.makeRow}
    @media ${({ theme }) => theme.BreakpointDown.xl} {
  background: #f1f1f1;
    }
`;
const ProjectGridBlok = (props) => {
  const { blok } = props;
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    arrows: false,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'ease-in-out',
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const { drawerDispatch } = useContext(DrawerContext);

  const [projectData, setProjectData] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [allCategories, setAllCategories] = useState([]);

  const DefaultProjectData = useStaticQuery(graphql`
    query Project {
      allStoryblokEntry(filter: { field_component: { eq: "individual_project" } }) {
        edges {
          node {
            uuid
            name
            content
            full_slug
          }
        }
      }
    }
  `);

  const setProjectsToMarlow = () => {
    // console.log('setProjectsToMarlow');
    // setProjectData(
    //   projectData?.filter((project) => {
    //     const content = JSON.parse(project?.node?.content);
    //     return content?.meta_category?.includes(['Website Design']);
    //   }),
    // );
    // console.log(`projectdata${projectData}`);
    // setProjectData(
    //   DefaultProjectData?.allStoryblokEntry?.edges.filter((project) => {
    //    return project?.node?.name?.includes("BIG Shopping");
    //   });
    // );

    setProjectData(
      DefaultProjectData?.allStoryblokEntry?.edges?.filter((project) => {
        // console.log(project.node.name);
        // const content = JSON.parse(project?.node?.content);
        const arr = [blok?.project_name_1, blok?.project_name_2, blok?.project_name_3];
        const name = project?.node?.name;
        if (arr.includes(name)) {
          return name;
        }
        // return project?.node?.name.some();
      }),
    );

    // console.log(DefaultProjectData?.allStoryblokEntry?.edges);
  };

  const resetProjects = () => {
    setProjectData(DefaultProjectData?.allStoryblokEntry?.edges);
    setCurrentCategory(null);
  };

  const getCategories = (data) => {
    const allCat = [];
    data?.map((item) => {
      const content = JSON.parse(item.node.content);
      content.meta_category?.map((category) => {
        allCat.push(category);
        return () => {};
      });
      return () => {};
    });
    const uCategories = [...new Set(allCat)];
    return uCategories;
  };

  useEffect(() => {
    setProjectData(DefaultProjectData?.allStoryblokEntry?.edges);
    setAllCategories(getCategories(DefaultProjectData?.allStoryblokEntry?.edges));
    if (blok.marlow) setProjectsToMarlow();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setAllCategories(getCategories(projectData));
  // }, [projectData]);

  const FilerFn = (value) => {
    if (!currentCategory) {
      setProjectData(
        projectData?.filter((project) => {
          const content = JSON.parse(project?.node?.content);
          return content?.meta_category?.includes(value);
        }),
      );
      // setAllCategories([['All'], [value]]);
      setCurrentCategory([value]);
    } else {
      resetProjects();
    }
  };

  const getDropdownCategories = () => {
    if (!currentCategory) {
      return allCategories?.map((item) => {
        return (
          <FilterInstance
            key={randomID()}
            onClick={(e) => {
              FilerFn(item, e);
            }}
          >
            {item} <ArrowedIcon />
          </FilterInstance>
        );
      });
    }
    return (
      <>
        <FilterInstance
          key={randomID()}
          onClick={() => {
            resetProjects();
          }}
        >
          All Projects
        </FilterInstance>
        <FilterInstance
          key={randomID()}
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            resetProjects();
          }}
        >
          {currentCategory} <ArrowedIcon className="alwayson" variant="down" />
        </FilterInstance>
      </>
    );
  };

  return (
    <>
      <ProjectGrid>
        {!blok.marlow && (
          <HeroBanner>
            Brands we’ve
            <div className="subHeroGrey">collaborated with.</div>
            <Bar>
              <SubHeadingWrapper>
                <p>
                  Our work is driven by digital transformation and working collaboratively with our
                  clients, to make something special.
                </p>
              </SubHeadingWrapper>
              <FilterBn>
                <span>Filter by</span>
                <FilterIcon />
                <ul>{getDropdownCategories()}</ul>
              </FilterBn>
            </Bar>
          </HeroBanner>
        )}
        <SliderWrapper>
          <StyledSlider ref={sliderRef} {...settings}>
            {projectData?.map((item, i) => {
              const z = i + 1;
              return (
                <>
                  {!blok.marlow && (
                    <ProjectItem
                      key={item.node.uuid}
                      full_slug={item.node.full_slug}
                      contentJSON={item.node.content}
                      compSize={
                        !currentCategory &&
                        {
                          // 1: 'fullwidth',
                          4: 'quater',
                          5: 'informal',
                          6: 'reversed-informal',
                          7: 'quater',
                          8: 'normal',
                          // 9: 'quater',
                          // 2: 'fullwidth',
                        }[z]

                        // i === 0 ? 'wide' : 'normal'
                      }
                    />
                  )}
                  {blok.marlow && (
                    <ProjectItem
                      key={item.node.uuid}
                      full_slug={item.node.full_slug}
                      contentJSON={item.node.content}
                      compSize={
                        !currentCategory &&
                        {
                          1: 'fullwidth',
                          2: 'quater',
                          3: 'informal',
                        }[z]
                      }
                    />
                  )}
                </>
              );
            })}
          </StyledSlider>
          <NextArrow
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          />
        </SliderWrapper>

        <ItemWrapper className="add" compSize="add">
          <InsideWrapper>
            <h2>Let&apos;s make something together</h2>
            <Button nolink onClick={() => drawerDispatch({ type: 'modalOpen' })}>
              Let&apos;s chat
            </Button>
          </InsideWrapper>
        </ItemWrapper>
      </ProjectGrid>
    </>
  );
};

export default ProjectGridBlok;
